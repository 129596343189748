import React from "react";
import Input from "@mui/material/Input";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  "@global": {},
  root: {
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    "& $inputInput": {
      transition: theme.transitions.create("width"),
      width: "50%",
    },
  },
  search: {
    width: theme.spacing(9),
    height: "60%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "80%",
    fontSize: "2em",
    lineHeight: "150%",
    borderBottom: "solid 1px black",
  },
  inputInput: {
    padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
      1
    )} ${theme.spacing(9)}`,
  },
  errorText: {
    margin: theme.spacing(1),
  },
}));

const AppSearch = ({ currentRefinement, isSearchStalled, refine, setOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchIcon />
      </div>
      <Input
        disableUnderline
        placeholder="Søg..."
        id="docsearch-input"
        onChange={(event) => refine(event.currentTarget.value)}
        value={currentRefinement}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
      />
      <Button onClick={() => setOpen(false)} endIcon={<CloseIcon />}>
        Luk
      </Button>

      <Typography variant="body1" color="error" className={classes.errorText}>
        {isSearchStalled ? "Søgning fejlet, ryd søgefeltet og prøv igen" : ""}
      </Typography>
    </div>
  );
};

export default AppSearch;
