import React from "react";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import NewIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import EventsIcon from "../../components/Icons/EventsIcon";

const PREFIX = "Header";

type HeaderProps = {
  selection: Record<string, unknown>[];
  handleCreateEvent: () => void;
  handleDeleteEvents: () => void;
  semester: string;
  setSemester: (semester: string) => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  handleCreateEvent,
  handleDeleteEvents,
  semester,
  setSemester,
}) => {
  return (
    <Container>
      <Stack
        spacing={{ sm: 1, md: 2, lg: 6 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <EventsIcon />

        <ButtonGroup size="medium" color="primary" orientation="vertical">
          <Button
            // className={classes.button}
            variant="contained"
            disableElevation
            endIcon={<NewIcon />}
            onClick={() => handleCreateEvent()}
          >
            Ny infoaften
          </Button>
          <Button
            // className={classes.button}
            endIcon={<DeleteIcon />}
            onClick={() => handleDeleteEvents()}
            disabled={selection.length < 1}
            color="secondary"
          >
            Slet
          </Button>
        </ButtonGroup>

        <FormControl style={{ width: "200px" }}>
          <InputLabel id="simple-select-label">Vælg årgang</InputLabel>
          <Select
            labelId="simple-select-label"
            label="Vælg årgang"
            value={semester}
            onChange={(e) => setSemester(e.target.value as string)}
          >
            <MenuItem value="2018">2018-2019</MenuItem>
            <MenuItem value="2019">2019-2020</MenuItem>
            <MenuItem value="2020">2020-2021</MenuItem>
            <MenuItem value="2021">2021-2022</MenuItem>
            <MenuItem value="2022">2022-2023</MenuItem>
            <MenuItem value="2023">2023-2024</MenuItem>
            <MenuItem value="2024">2024-2025</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Container>
  );
};

export default Header;
