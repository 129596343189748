import React from "react";
import { useFirestore } from "react-redux-firebase";
import { Field, Formik } from "formik";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "../../components";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  eventID: string;
};

const validationSchema = Yup.object().shape({
  rendesvouz: Yup.string().required(),
});

const SendFinalReminderDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  eventID,
}) => {
  const firestore = useFirestore();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{ rendesvouz: "" }}
          validationSchema={validationSchema}
          onSubmit={async ({ rendesvouz }, actions) => {
            try {
              actions.setSubmitting(true);
              const eventRef = firestore.collection("info_events").doc(eventID);
              await eventRef.update({
                rendesvouz,
                sendFinalConfirmation: true,
              });

              handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                Send bekræftelsesmails
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="rendesvouz"
                      label={"Mødested"}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Annuller
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Send emails
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default SendFinalReminderDialog;
