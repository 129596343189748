import React from "react";
import Grid from "@mui/material/Grid";
import useSubject from "../hooks/useSubject";
import Typography from "@mui/material/Typography";
import { parseExamType, parseLine } from "../helpers/parser";

type Props = {
  subjectID: string;
};
const AssignExamResultsComponent: React.FC<Props> = ({ subjectID }) => {
  const subject = useSubject({ subjectID });
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">
          {subject?.name} - {parseExamType(subject?.exam.type)}
        </Typography>
        <Typography variant="body2">
          {subject?.lines.map(parseLine).join(", ")} - {subject?.semester}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AssignExamResultsComponent;
