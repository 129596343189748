const sport = {
  hex: "#fc4f4f",
};
const outdoor = {
  hex: "#7cd4b7",
};

const xOutdoor = {
  hex: "#5ec0ef",
};

const defaultColor = {
  hex: "#444040",
};
const colors: { [key: string]: any } = {
  sport,
  outdoor,
  xOutdoor,
  ['x-outdoor']: xOutdoor,
  defaultColor,
};

export default colors;
