import React from "react";

import { BarChart, mangoFusionPalette } from "@mui/x-charts";

type ChartProps = {
  data: any[];
};

const EventStatsLineChart: React.FC<ChartProps> = ({ data }) => {
  return (
    <BarChart
      dataset={data}
      colors={mangoFusionPalette}
      series={[
        { dataKey: "attendance", label: "Mødte op", stack: "Deltagelse" },
        { dataKey: "attending", label: "Deltager", stack: "Deltagelse" },
        {
          dataKey: "not_attending",
          label: "Deltager ikke",
          stack: "Deltagelse",
        },
        { dataKey: "unconfirmed", label: "Ubesvaret", stack: "Deltagelse" },
        { dataKey: "interviews", label: "Samtaler Aftalt", stack: "Samtaler" },
        {
          dataKey: "interviews_sport",
          label: "Samtaler Sport",
          stack: "Samtaler",
        },
        {
          dataKey: "interviews_outdoor",
          label: "Samtaler Friluft",
          stack: "Samtaler",
        },
        {
          dataKey: "interviews_xoutdoor",
          label: "Samtaler X-Outdoor",
          stack: "Samtaler",
        },
        {
          dataKey: "facebook_interested",
          label: "Facebook Interesserede",
          stack: "Sociale Medier",
        },
        {
          dataKey: "facebook_participants",
          label: "Facebook Deltagere",
          stack: "Sociale Medier",
        },
      ]}
      xAxis={[{ data: data.map((val) => val.name), scaleType: "band" }]}
    />
  );
};

export default EventStatsLineChart;
