import React, { useEffect, useState } from "react";

import { LineChart } from "@mui/x-charts";
import { functions } from "../../helpers/firebaseHelper";

type Data = {
  name: string;
  sport: number;
  outdoor: number;
  xOutdoor: number;
  noLine: number;
  total: number;
};

type ChartProps = {
  semester?: string;
};

export const ApplicantsChart: React.FC<ChartProps> = ({ semester }) => {
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    async function loadContracts() {
      if (!semester) return [];
      try {
        const applicantsFunction = functions.httpsCallable(
          "statistics-applicants"
        );
        const startYear = parseInt(semester.split("-")[0]);

        const result = await applicantsFunction({ startYear });
        setData(result.data);
      } catch (error: any) {
        console.log(error.message);
      }
    }
    loadContracts();
  }, [semester]);
  return (
    <LineChart
      dataset={data}
      series={[
        { dataKey: "total", label: "Total" },
        { dataKey: "sport", label: "Original Sport" },
        { dataKey: "outdoor", label: "Friluft" },
        { dataKey: "xOutdoor", label: "X-Outdoor" },
      ]}
      xAxis={[{ dataKey: "name", scaleType: "point" }]}
    />
    /* <ResponsiveContainer width="100%" height="95%">
      <LineChart
        width={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name">
          <Label value="Uge nr." offset={0} position="insideBottomLeft" />
        </XAxis>

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend margin={{ bottom: 16 }} height={16} />
        <Line
          name="Antal ansøgere totalt"
          type="monotone"
          dataKey="total"
          stroke="#4caf50"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer> */
  );
};
