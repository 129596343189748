import React, { Component, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { Field, FieldProps, Formik, FormikBag } from "formik";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import { internalContactGroups } from "../../helpers/contactGroups";
import { subjects } from "../../helpers/subjects";
import { keyOptions } from "../../helpers/keyOptions";
import { ListItemButton, Typography } from "@mui/material";
import { DropzoneFileList } from "../../containers";
import MUITextField from "@mui/material/TextField";
import * as Yup from "yup";
import Contact from "../../Classes/Contact";
import CheckboxField from "../FormFields/CheckboxField";
import TextField from "../FormFields/TextField";
import SelectField from "../FormFields/SelectField";
const PREFIX = "ContactsForm";

const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  textArea: `${PREFIX}-textArea`,
  radio: `${PREFIX}-radio`,
  button: `${PREFIX}-button`,
  list: `${PREFIX}-list`,
  listSection: `${PREFIX}-listSection`,
  ul: `${PREFIX}-ul`,
  select: `${PREFIX}-select`,
  selectBox: `${PREFIX}-selectBox`,
  dropzone: `${PREFIX}-dropzone`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.textArea}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.radio}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.list}`]: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: listHeight,
  },

  [`& .${classes.listSection}`]: {
    backgroundColor: "inherit",
  },

  [`& .${classes.ul}`]: {
    backgroundColor: "inherit",
    padding: 0,
  },

  [`& .${classes.select}`]: {
    margin: theme.spacing(1),
    minWidth: 150,
  },

  [`& .${classes.selectBox}`]: {
    marginTop: topMargin,
    padding: 8,
  },

  [`& .${classes.dropzone}`]: {
    height: 100,
  },
}));

const listHeight = 300;
const topMargin = 16;

const validationSchema = Yup.object().shape({
  email: Yup.string().email(),
});

interface RenderFieldProps {
  field: React.ComponentType;
  form: any;
}
const renderTextField: React.FC<RenderFieldProps> = ({
  field,
  form,
  ...props
}) => {
  const isError =
    form.touched[field.name] && form.errors[field.name] ? true : false;
  return (
    <MUITextField
      helperText={form.touched[field.name] && form.errors[field.name]}
      InputLabelProps={{
        error: isError,
      }}
      {...field}
      {...props}
    />
  );
};

const Equipment = () => {
  return (
    <Grid container style={{ marginTop: 8 }}>
      <Grid item xs={4}>
        <Field
          name="equipment.computer"
          component={CheckboxField}
          type="checkbox"
          Label={{ label: "Computer" }}
        />
        <Field
          name="equipment.computerLoanAgreementSigned"
          component={CheckboxField}
          type="checkbox"
          style={{ paddingLeft: 8 }}
          Label={{ label: "Låneaftale underskrevet" }}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.computerDescription"
          className={classes.textField}
          fullWidth
          component={renderTextField}
          placeholder={"eks. MacBook Pro 2018"}
          type="text"
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          name="equipment.phone"
          component={CheckboxField}
          type="checkbox"
          Label={{ label: "Telefon" }}
        />
        <Field
          name="equipment.phoneLoanAgreementSigned"
          component={CheckboxField}
          type="checkbox"
          style={{ paddingLeft: 8 }}
          Label={{ label: "Låneaftale underskrevet" }}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.phoneDescription"
          className={classes.textField}
          fullWidth
          component={renderTextField}
          placeholder={"eks. iPhone 7"}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="employeeHandbookSigned"
          component={CheckboxField}
          type="checkbox"
          Label={{ label: "Medarbejderhåndbog underskrevet" }}
        />
        <Field
          name="teachersHandbookSigned"
          component={CheckboxField}
          type="checkbox"
          Label={{ label: "underviserhåndbog underskrevet" }}
        />
      </Grid>
    </Grid>
  );
};

const ExternalGroupSelect = () => {
  return (
    <FormControl>
      <InputLabel htmlFor="group">Vælg gruppe</InputLabel>
      <Field
        name="group"
        className={classes.select}
        component={SelectField}
        placeholder="Vælg gruppe"
      >
        <MenuItem value={"denmark"}>Danmark</MenuItem>
        <MenuItem value={"international"}>Udlandet</MenuItem>

        <MenuItem value={"boardingschool"}>Højskolebesøg</MenuItem>
      </Field>
    </FormControl>
  );
};

const BelongsToLine = () => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ marginLeft: 8, marginRight: 8 }}
      >
        Tilknyttet
      </Typography>
      <Field
        name="associatedLines.sports"
        component={CheckboxField}
        type="checkbox"
        Label={{ label: "Sport" }}
      />
      <Field
        name="associatedLines.outdoor"
        component={CheckboxField}
        type="checkbox"
        Label={{ label: "Friluft" }}
      />
    </Grid>
  );
};

const KeyOptions = () => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Nøgler</ListSubheader>
            {Object.keys(keyOptions).map((key) => (
              <ListItemButton key={key} dense>
                <Field
                  name={`keys.${key}`}
                  component={CheckboxField}
                  type="checkbox"
                />
                <ListItemText primary={keyOptions[key]} />
              </ListItemButton>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

const ContactGroups = () => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Medlem af</ListSubheader>
            {Object.keys(internalContactGroups).map((key) => (
              <ListItemButton key={key} dense>
                <Field
                  name={`memberOf.${key}`}
                  component={CheckboxField}
                  type="checkbox"
                />
                <ListItemText
                  primary={
                    (internalContactGroups as { [key: string]: string })[key]
                  }
                />
              </ListItemButton>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

const TeachingIn = () => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Tilkyttet fag</ListSubheader>
            {Object.keys(subjects).map((key) => (
              <ListItemButton key={key} dense>
                <Field
                  name={`subjects.${key}`}
                  component={CheckboxField}
                  type="checkbox"
                />
                <ListItemText
                  primary={(subjects as { [key: string]: string })[key]}
                />
              </ListItemButton>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

interface ContactsFormProps {
  savePrivateContact: (contact: Contact) => void;
  initialValues: Contact;
}

const ContactsForm: React.FC<ContactsFormProps> = ({
  savePrivateContact,
  initialValues,
}) => {
  const [checked, setChecked] = useState<string[]>([""]);

  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await savePrivateContact(values);
        setSubmitting(false);
      }}
    >
      {({ values: { isExternal }, resetForm, handleSubmit, isSubmitting }) => (
        <Root onSubmit={handleSubmit}>
          <Grid container sx={{ padding: 4 }} rowSpacing={2}>
            <Grid item xs={12}>
              <Field
                name="isExternal"
                component={CheckboxField}
                type="checkbox"
                Label={{ label: "Ekstern kontakt" }}
              />
              <Field
                name="isArchived"
                type="checkbox"
                component={CheckboxField}
                Label={{ label: "Arkiveret" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name={"title"}
                className={classes.textField}
                component={renderTextField}
                placeholder={"F.eks. 'Underviser'"}
                label={"Titel"}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={isExternal ? "name" : "firstName"}
                className={classes.textField}
                component={renderTextField}
                placeholder={isExternal ? "Firma Navn" : "Fornavn"}
                label={isExternal ? "Firma Navn" : "Fornavn"}
                type="text"
              />
              <Field
                name={isExternal ? "att" : "lastName"}
                className={classes.textField}
                component={renderTextField}
                placeholder={isExternal ? "Kontakt navn." : "Efternavn"}
                label={isExternal ? "Kontakt navn." : "Efternavn"}
                type="text"
              />
              <Field
                name="email"
                className={classes.textField}
                component={renderTextField}
                placeholder={"Email"}
                label="Email"
                type="text"
              />
              <Field
                name="phone"
                className={classes.textField}
                component={renderTextField}
                placeholder={"Telefon"}
                label="Telefon"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="address"
                className={classes.textField}
                component={renderTextField}
                placeholder={"Adresse"}
                label="Adresse"
                type="text"
              />
              <Field
                name="postcode"
                className={classes.textField}
                component={renderTextField}
                placeholder={"Postnummer"}
                label="Postnummer"
                type="text"
              />
              <Field
                name="city"
                className={classes.textField}
                component={renderTextField}
                placeholder={"By"}
                label="By"
                type="text"
              />
              {isExternal ? <ExternalGroupSelect /> : <BelongsToLine />}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {!isExternal && <Equipment />}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: topMargin }}>
              <Divider />
            </Grid>
            {!isExternal && (
              <React.Fragment>
                <ContactGroups />
                <TeachingIn />
                <KeyOptions />
                <Grid item xs={6} lg={4} className={classes.selectBox}>
                  <DropzoneFileList
                    collection="contacts"
                    id={initialValues.id}
                    multiple={false}
                    heading="Filer"
                  />
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={6} className={classes.selectBox}>
              <Field
                name="note"
                component={TextField}
                placeholder={"Kommentar"}
                type="text"
                multiline
                fullWidth
                rows={6}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                Opdater
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                disabled={isSubmitting}
                onClick={() => resetForm()}
              >
                Fortryd
              </Button>
            </Grid>
          </Grid>
        </Root>
      )}
    </Formik>
  );
};

export default ContactsForm;
