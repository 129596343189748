import React from "react";
import { Formik, Field, FieldArray, FormikValues } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFirestore } from "react-redux-firebase";
import SubjectForm from "./Components/SubjectForm";

type DialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  semesters: Semester[];
};

const NewSubjectDialog: React.FC<DialogProps> = ({
  open,
  setOpen,
  semesters,
}) => {
  const firestore = useFirestore();
  const doc = firestore.collection("subjects").doc();

  const onSubmit = (values: FormikValues): Promise<void> => {
    return doc.set({ ...values });
  };

  const onComplete = () => {
    // Close the modal if the operation was successful
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Nyt fag</DialogTitle>
      <DialogContent>
        <DialogContentText>Opret nyt fag</DialogContentText>
        <SubjectForm
          initialValues={{
            id: doc.id,
            name: "",
            semester: "",
            lines: [],
            exam: { type: "graded" },
            students: [],
            examiners: [],
            examResults: [],
          }}
          onSubmit={onSubmit}
          semesters={semesters}
          onComplete={onComplete}
          isCreate
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewSubjectDialog;
