import React from 'react';
import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

const PREFIX = 'UploadersComponent';

const classes = {
  root: `${PREFIX}-root`,
  menuItem: `${PREFIX}-menuItem`,
  primary: `${PREFIX}-primary`,
  icon: `${PREFIX}-icon`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  [`& .${classes.menuItem}`]: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white
      }
    }
  },

  [`& .${classes.primary}`]: {},
  [`& .${classes.icon}`]: {}
}));

const UploadersComponent = ({
  uploads,
  classes,
  userDidSelect,
  selectedStudent,
  isFetching
}) => {
  return (
    <StyledPaper>
      <MenuList>
        {isFetching && (
          <MenuItem key="loading" className={classes.menuItem}>
            <CircularProgress />
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary="Henter..."
            />
          </MenuItem>
        )}
        {Object.keys(uploads).map(key => {
          const uploader = uploads[key];
          return (
            <MenuItem
              key={key}
              className={classes.menuItem}
              onClick={() => userDidSelect(key)}>
              <Avatar alt={uploader.displayName} src={uploader.photoURL} />
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary={uploader.displayName}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </StyledPaper>
  );
};

UploadersComponent.propTypes = {
  uploads: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDidSelect: PropTypes.func.isRequired
};

export default (UploadersComponent);
