import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudent,
  fetchClasses,
  saveStudent,
  saveSafetyInformation,
  createAccountForStudent,
  moveStudentToApplicants,
} from "../../actions/students";
import { setRecipients } from "../../actions/messages";
import { fetchSemesters } from "../../actions/semesters";
import {
  uploadFile,
  unsubscribeFilesListener,
} from "../../actions/fileUploads";
import { StudentComponent } from "../../components";
import { StudentFilesContainer } from "..";
import { useHistory, useParams } from "react-router";
import { AppState } from "../../reducers";
import Student from "../../Classes/Student";

interface Props {
  isFetching: boolean;
  progress: number;
  isUploading: boolean;
  uploadSucceeded: boolean;
  isSaving: boolean;
  student: Student;
  semesters: string[];
  classNames: string[];
  creatingAccount: boolean;
  createAccountSuccess: boolean;
  createAccountError: any;
  success: boolean;
  pagination: any;
  isFetchingPagination: boolean;
  safetyInformation: any;
}

const StudentContainer: React.FC<Props> = () => {
  const history = useHistory();
  const { id, semester }: { id: string; semester: string } = useParams();
  const {
    students: {
      isFetching,
      // error,
      student,
      isSaving,
      classNames,
      creatingAccount,
      // createAccountSuccess,
      // createAccountError,
      // grades,
      // isFetchingGrades,
      // success,
      pagination,
      isFetchingPagination,
      safetyInformation,
    },
    semesters: { semesters },
    exams: { isUpdating, updateSuccess, saveGradeSuccess, examSubjects: exams },
    fileUploads: { isUploading, progress, uploadSucceeded },
  } = useSelector((state: AppState) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStudent(id));
    dispatch(fetchClasses(semester));
    dispatch(fetchSemesters());

    return () => {
      unsubscribeFilesListener();
    };
  }, []);

  const handleFileUpload = (path: string, file: File) => {
    if (!file) {
      return;
    }

    if (!student.id) {
      return;
    }
    dispatch(uploadFile(path, file));
  };

  const saveSafetyInfo = (si: any) => {
    dispatch(saveSafetyInformation(student.id, si));
  };

  const removeSemester = () => {
    if (window) {
      if (
        window.confirm(
          "Er du sikker på du vil fjerne årgangen fra denne studerende? Dette vil flytte den studerende tilbage under Ansøgere"
        )
      ) {
        dispatch(moveStudentToApplicants(student));
      }
    }
  };

  const handleSendMessage = () => {
    const recipient = [
      {
        name: `${student.firstName} ${student.lastName}`,
        email: student.email,
        phone: student.phone,
        collection: "students",
        docId: student.studentId || student.id,
        status: "not_submitted",
      },
    ];
    dispatch(setRecipients(recipient));
  };

  const handleViewMessages = () => {
    if (!student) return;
    history.push(`/messages/students/${student.id}`);
  };

  return (
    <StudentComponent
      saveStudent={(s: Student) => dispatch(saveStudent(s))}
      student={student}
      safetyInformation={safetyInformation}
      semesters={semesters}
      pagination={pagination}
      isFetchingPagination={isFetchingPagination}
      classNames={classNames}
      saveSafetyInfo={(si: any) => saveSafetyInfo(si)}
      handleFileChange={(path: string, file: File) =>
        handleFileUpload(path, file)
      }
      progress={progress}
      isSaving={isSaving}
      isUploading={isUploading}
      isFetching={isFetching}
      creatingAccount={creatingAccount}
      handleSendMessage={handleSendMessage}
      handleViewMessages={handleViewMessages}
      removeSemester={removeSemester}
      handleCreateAccount={() => dispatch(createAccountForStudent(student))}
    >
      <StudentFilesContainer studentId={student.id} />
    </StudentComponent>
  );
};

export default StudentContainer;
