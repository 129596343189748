import React from "react";
import { styled } from "@mui/material/styles";
import { useFirestore } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FormikValues, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import useSemesters from "../hooks/useSemesters";
import SubjectForm from "./Components/SubjectForm";
import { openSnackbar } from "../actions/snackbar";
import useSubject from "../hooks/useSubject";

const PREFIX = "SubjectComponent";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(2),
  },
}));

const SubjectComponent: React.FC = () => {
  const { id: subjectID }: { id: string } = useParams();
  const semesters = useSemesters();

  const firestore = useFirestore();
  const dispatch = useDispatch();

  const subject = useSubject({ subjectID });

  const onSubmit = (values: FormikValues): Promise<void> =>
    firestore
      .collection("subjects")
      .doc(subjectID)
      .update({ ...values });

  const assignStudents = async (ids: string[]) => {
    // Add a student to the 'students' prop of the subject. Then add the subject to the students exam subjects as well
    const subjectRef = firestore.collection("subjects").doc(subjectID);
    const studentsCollection = firestore.collection("students");

    const batch = firestore.batch();
    try {
      batch.update(subjectRef, {
        students: firestore.FieldValue.arrayUnion(...ids),
      });
      for (const id of ids) {
        batch.update(studentsCollection.doc(id), {
          examSubjects: firestore.FieldValue.arrayUnion(subjectID),
        });
      }
      await batch.commit();
      dispatch(
        openSnackbar(
          `✅ Tildelte ${ids.length} studerende eksamensfaget ${subject?.name}`
        )
      );
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 ${error.message}`));
      console.error(error);
    }
  };

  const unassignStudents = async (ids: string[]) => {
    // Add a student to the 'students' prop of the subject. Then add the subject to the students exam subjects as well
    const subjectRef = firestore.collection("subjects").doc(subjectID);
    const studentsCollection = firestore.collection("students");

    const batch = firestore.batch();
    try {
      batch.update(subjectRef, {
        students: firestore.FieldValue.arrayRemove(...ids),
      });
      for (const id of ids) {
        batch.update(studentsCollection.doc(id), {
          examSubjects: firestore.FieldValue.arrayRemove(subjectID),
        });
      }
      await batch.commit();
      dispatch(
        openSnackbar(
          `✅ Fjernede eksamensfaget ${subject?.name} fra ${ids.length} studerende`
        )
      );
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 ${error.message}`));
      console.error(error);
    }
  };

  return (
    <StyledGrid container direction="row" justifyContent="center">
      <Grid item xs={12} md={10}>
        <Paper className={classes.root} elevation={4}>
          {subject && (
            <SubjectForm
              initialValues={subject}
              onSubmit={onSubmit}
              semesters={semesters}
              isCreate={false}
              assignStudents={assignStudents}
              unassignStudents={unassignStudents}
            />
          )}
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default SubjectComponent;
