import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PREFIX = 'ExamSemesterSelect';

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  select: `${PREFIX}-select`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120
  },

  [`& .${classes.select}`]: {
    width: 200
  }
}));

const ExamSemesterSelect = ({
  classes,
  selectedSemester,
  handleSemesterChange,
  semesters,
  isFetching
}) => (
  <Root className={classes.root} autoComplete="off">
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="examSemesterSelect">Semester</InputLabel>
      <Select
        className={classes.select}
        value={selectedSemester}
        disabled={isFetching}
        onChange={handleSemesterChange}
        inputProps={{
          name: 'examSemester',
          id: 'examSemesterSelect'
        }}>
        {semesters.map(semester => {
          return (
            <MenuItem key={semester} value={semester}>
              {semester}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </Root>
);

ExamSemesterSelect.propTypes = {
  selectedSemester: PropTypes.string.isRequired,
  handleSemesterChange: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired
};

export default (ExamSemesterSelect);
