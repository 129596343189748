import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { PersonsTable, ContactsActionList } from "../../components";
import { useDispatch, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { fetchContacts, deleteContacts } from "../../actions/contacts";
import { createContactsSheet } from "../../actions/sheets";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppState } from "../../reducers";
import { useHistory } from "react-router";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Contact from "../../Classes/Contact";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";

const PREFIX = "ContactsContainer";

const classes = {
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: { margin: 16 },
  [`& .${classes.appBar}`]: { marginBotton: 24 },
}));

type Group = "INTERNAL" | "EXTERNAL" | "ARCHIVED";
const groupings: Group[] = ["INTERNAL", "EXTERNAL", "ARCHIVED"];
type GroupTranslations = Record<Group, string>;
const groupTranslations: GroupTranslations = {
  INTERNAL: "Interne",
  EXTERNAL: "Eksterne",
  ARCHIVED: "Arkiverede",
};

const ContactsContainer = () => {
  const [groupValue, setGroupValue] = useState<Group>("INTERNAL");
  const [filter, setFilter] = useState("all");
  const [query, setQuery] = useState<{
    isExternal?: boolean;
    isArchived?: boolean;
  }>({ isExternal: false, isArchived: false });
  const dispatch = useDispatch();
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "contacts",
      where: [["isExternal", "==", query.isExternal || false]],
    },
  ]);

  const contacts = useSelector(
    (state: AppState) => state.firestore.ordered.contacts || []
  );
  const getColumns = (group: Group, router: any): GridColDef[] => {
    return group === "EXTERNAL"
      ? [
          {
            field: "actions",
            type: "actions",
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<Visibility />}
                onClick={() => {
                  history.push(`/contacts/${params.row.id}`);
                }}
                label="Se"
              />,
            ],
          },
          { field: "name", headerName: "Firma navn", width: 150 },
          { field: "att", headerName: "Kontakt navn", width: 150 },
          { field: "email", headerName: "Email", width: 200 },
          { field: "phone", headerName: "Telefon", width: 150 },
        ]
      : [
          {
            field: "actions",
            type: "actions",
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<Visibility />}
                onClick={() => {
                  history.push(`/contacts/${params.row.id}`);
                }}
                label="Se"
              />,
            ],
          },
          { field: "firstName", headerName: "Fornavn", width: 150 },
          { field: "lastName", headerName: "Efternavn", width: 150 },
          { field: "email", headerName: "Email", width: 200 },
          { field: "phone", headerName: "Telefon", width: 150 },
        ];
  };

  const columns = getColumns(groupValue, history);

  const handleCreateNewContact = () => {
    history.push("/contacts/new");
  };

  const handleDeleteItems = (items: string[]) => {
    if (window) {
      if (
        window.confirm(
          `Slet ${items.length} kontakter? Dette vil fjerne kontakten samt den dertilhørende offentlige profil`
        )
      ) {
        dispatch(deleteContacts(items));
      }
    }
  };

  const handleSetContactGroup = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const group = event.target.value as Group;
    let localQuery: { isExternal?: boolean; isArchived?: boolean };
    switch (group) {
      case "INTERNAL":
        localQuery = { isExternal: false };
        break;
      case "EXTERNAL":
        localQuery = { isExternal: true };
        break;
      case "ARCHIVED":
        localQuery = { isArchived: true };
        break;
    }
    setQuery(localQuery);
    setGroupValue(group);
  };

  const handleMemberOfChange = (event: SelectChangeEvent<any>) => {
    setFilter(event.target.value);
  };

  let isExternal = groupValue === "EXTERNAL";
  let filteredContacts: Contact[] = contacts;
  if (filter !== "all") {
    if (groupValue === "INTERNAL") {
      isExternal = false;
      filteredContacts = contacts.filter(
        (c: Contact) =>
          c.memberOf && (c.memberOf as { [key: string]: any })[filter]
      );
    } else if (groupValue === "EXTERNAL") {
      filteredContacts = contacts.filter(
        (c: Contact) => c.group && c.group === filter
      );
    }
  }
  return (
    <StyledPaper className={classes.paper}>
      {!isLoaded(contacts) && <LinearProgress />}
      <Grid2 container>
        <Grid2 size={{ xs: 12 }}>
          <Grid2 container style={{ marginTop: 24 }}>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <Box sx={{ padding: 4 }}>
                <FormControl>
                  <FormLabel id="group-radio-button">Kontakt type</FormLabel>
                  <RadioGroup
                    aria-labelledby="group-radio-button"
                    value={groupValue}
                    onChange={handleSetContactGroup}
                    name="radio-buttons-group"
                  >
                    {groupings.map((group) => (
                      <FormControlLabel
                        key={group}
                        value={group}
                        control={<Radio />}
                        label={groupTranslations[group]}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <ContactsActionList
                  isExternal={isExternal}
                  handleCreateNewContact={handleCreateNewContact}
                  memberOfValue={filter}
                  memberOfDidChange={handleMemberOfChange}
                />
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 9 }}>
              <DataGrid
                rows={filteredContacts}
                columns={columns}
                pageSizeOptions={[10, 20, 50]}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </StyledPaper>
  );
};

export default ContactsContainer;
