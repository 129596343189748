import React from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import NewIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Divider } from "@mui/material";
import EmployeeIcon from "../../components/Icons/Employee";

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  cardRoot: `${PREFIX}-cardRoot`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardContent: `${PREFIX}-cardContent`,
  dischargeSelect: `${PREFIX}-dischargeSelect`,
  typography: `${PREFIX}-typography`,
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    marginTop: 30,
  },

  [`& .${classes.cardRoot}`]: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },

  [`& .${classes.cardHeader}`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },

  [`& .${classes.cardContent}`]: {
    padding: 10,
  },

  [`& .${classes.dischargeSelect}`]: {
    padding: theme.spacing(2),
    minWidth: 300,
  },

  [`& .${classes.typography}`]: { fontWeight: 600 },

  [`& .${classes.select}`]: {
    width: "100%",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }
}));

type HeaderProps = {
  selection: Record<string, unknown>[];
  newEmployee: () => void;
  deleteEmployees: () => void;
  createSpreadsheet: () => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  newEmployee,
  deleteEmployees,
  createSpreadsheet,
}) => {

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Ansatte"} />

            <CardContent className={classes.cardContent}>
              <Grid
                item
                xs={12}
                container
                spacing={6}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12} md={2}>
                  <div style={{ textAlign: "center" }}>
                    <EmployeeIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={3}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => newEmployee()}
                    >
                      Ny Ansat
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<ListAltIcon />}
                      onClick={() => createSpreadsheet()}
                      disabled={selection.length < 1}
                    >
                      Excel udtræk
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteEmployees()}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Header;
