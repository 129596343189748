import React from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import NewIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Divider } from "@mui/material";

import SubjectsIcon from "./SubjectsIcon";

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  cardRoot: `${PREFIX}-cardRoot`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardContent: `${PREFIX}-cardContent`,
  dischargeSelect: `${PREFIX}-dischargeSelect`,
  typography: `${PREFIX}-typography`,
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`,
  button: `${PREFIX}-button`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  radio: `${PREFIX}-radio`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    marginTop: 30,
  },

  [`& .${classes.cardRoot}`]: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },

  [`& .${classes.cardHeader}`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },

  [`& .${classes.cardContent}`]: {
    padding: 10,
  },

  [`& .${classes.dischargeSelect}`]: {
    padding: theme.spacing(2),
    minWidth: 300,
  },

  [`& .${classes.typography}`]: { fontWeight: 600 },

  [`& .${classes.select}`]: {
    width: "100%",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.radio}`]: {
    marginTop: theme.spacing(2),
  }
}));

type HeaderProps = {
  selection: Subject[];
  newSubject: () => void;
  deleteSubjects: (ids: string[]) => void;
  // filters: Filters;
  // setFilters: (filters: Filters) => void;
  semesters: { title: string }[];
  semester: string;
  setSemester: (semester: string) => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  newSubject,
  deleteSubjects,
  semester,
  setSemester,
  semesters,
}) => {

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Fag"} />

            <CardContent className={classes.cardContent}>
              <Grid item xs={12} container direction="row" alignItems="center">
                <Grid item xs={12} md={3}>
                  <div style={{ textAlign: "center" }}>
                    <SubjectsIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={2}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => newSubject()}
                    >
                      Nyt fag
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<ListAltIcon />}
                      onClick={() => console.log("Not implemented")}
                      disabled={selection.length < 1}
                    >
                      Excel udtræk
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteSubjects(selection.map((s) => s.id))}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet fag
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />
                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                      Vis fag for given årgang
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="semester-select-label">
                        Semester
                      </InputLabel>
                      <Select
                        labelId="semester-select-label"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value as string)}
                      >
                        <MenuItem value={""}>Vælg</MenuItem>
                        {semesters.map((semester: { title: string }) => (
                          <MenuItem value={semester.title} key={semester.title}>
                            {semester.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      onClick={() =>
                        // If the query is already set, reset it. Otherwise set it
                        // setQuery(
                        //   query
                        //     ? null
                        //     : {
                        //         line,
                        //         semester,
                        //         subject,
                        //       }
                        // )
                        console.log("not implemented")
                      }
                    >
                      {/* {query ? "Ryd" : "Vis"} */}
                    </Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Header;
