import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
const PREFIX = 'ChangesContainer';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: theme.spacing(3)
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2)
  }
}));

class ChangesContainer extends Component {
  render() {
    const { } = this.props;
    return (
      <StyledGrid container justifyContent="center" className={classes.root}>
        <Grid item sm={12} md={10}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Seneste ændringer</Typography>
            <Typography>
              Læs om de seneste ændringer i PPKBH intra herunder. Du finder den
              seneste version af intra øverst i venstre hjørne
            </Typography>
          </Paper>
        </Grid>
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.11"
          changes={["Nu kan Super Admins ændre brugeres privilegier"]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan studerende dimmiteres fra /studerende med et klik :)"
          ]}
          version="0.13.10"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={["Tilføjede flere grunde under arkiveret ansøger"]}
          version="0.13.9"
          changes={[
            "Rettede en fejl under kontakter hvor arkiverede kontaker viste sig under nuværende"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={["Tilføjede 'Oprettet i Economics felt under studerende"]}
          version="0.13.8"
          changes={[
            "Fjernede Reg., Konto og Betalers CPR under studerende",
            "Når et Excel udtræk med 'Kontrakt modtaget' bliver oprettet kommer datoen for kontrakten nu også med"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.5/6/7"
          changes={[
            "Fiksede et problem hvor en ny censor ikke blev vist efter oprettelse"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.4"
          changes={[
            "Der kan nu vælges en grund for udmeldelse for hhv. ansøgere og studerende pr. todoist: https://todoist.com/showTask?id=3164407734&sync_id=3164407728 "
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.3"
          changes={[
            "Der kommer ikke længere en popup efter en fil er blevet slettet",
            "Dashboard er blevet opdateret således at man kan sammeligne ansøgere for en given periode"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.2"
          changes={["Interne forbedringer"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.1"
          changes={["Nu kan der uploades filer til en kontakt"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.13.0"
          changes={[
            "Nu kan der oprettes instruktørbeviser for et hold under Studerende"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.21"
          changes={["Examensresultater virker nu igen"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.20"
          changes={[
            "Den offentlige profil er blevet fjernet fra interne kontakter"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.19"
          changes={[
            "En intern opdatering der ændrer nogle få parametre nå® en ansøger overføres til studerende"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={["Nu kan der oprettes excel udtrækt for kontakter"]}
          version="0.12.18"
          changes={[""]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Indmeldelsesgebyr modtaget er tilføjet til 'Ansøger' formularen"
          ]}
          version="0.12.17"
          changes={["Ændrede fag under Kontakter"]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan man vælge hvilke fag en intern kontakt er tilknyttet"
          ]}
          version="0.12.16"
          changes={[
            "Rettede en fejl under Censorer hvor filtre ikke virkede",
            "Tilføjede en ny tab-bar øvest ved censorer hvor man kan vise enten aktive censorer eller arkiverede"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.15"
          changes={[
            "Ekstra felter under pasoplysninger tilføjet: 'Nationalitet' og 'Navn'. Disse er også inkluderet i det excel udtræk der kan laves under Studerende > 'Flyverikon'"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.14"
          changes={[
            "Når du opretter Forsikringsoplysninger for studerende vil der nu stå 'MANGLER' under felter hvor der ikke er nogen data"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.13"
          changes={[
            "Studerende fanen i menuen viser nu kun de seneste 5 semestre. Alle semestre kan tilgås via Studerende > Arkiverede"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.12"
          changes={[
            "Der er nu en visuel indikator når et forsikringsinfo excel ark er igang med at blive oprettet"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.12.11"
          changes={["Journalmapper virker nu igen under studerende"]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Ændrede højden på listen over en censors filer. Fiksede en fejl på censor formen"
          ]}
          version="0.12.10"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Ændrede navn på billedesæt til billedesæt og studiekortbilleder"
          ]}
          version="0.12.9"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={["Tilføjet 'frem og tilbage' pile under kontaker"]}
          version="0.12.8"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Under kontakter: Tilføjede muligheden for at vælge hvilke nøgler den ansatte har modtager, hvilken linie en anset er tilknyttet og en titel på den ansatte",
            "Tilføjede pas infomation under en given studerende > Forsikring & Sikkerhed"
          ]}
          version="0.12.7"
          changes={[
            "Fikset så man kan manuelt oprette en studerende",
            "Når man filterer kontakt grupper bliver kun de aktuelle grupper (intern vs. ekstern) vist"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={["Fjerenede Fag, Offentlige profiler og Jobs"]}
          version="0.12.6"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan man sende beskeder til kontakter",
            "Der kan filtreres mellem Danmark, Udlandet og Højskole på eksterne kontakter"
          ]}
          version="0.12.5"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Tilføjede to ekstra felter til en privat kontakt i forbindelse med udstyrsliste"
          ]}
          version="0.12.4"
          changes={["Rettede fejl i søgefunktion"]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Du kan nu søge på studerende. Der kan søges på Fornavn, efternavn, email, CPR, hold og semester. Pt. tages der ikke forbehold for stavefejl, dvs. at hvis du søger på 'Martni' og ikke 'Martin' vil du ikke få nogen resultater"
          ]}
          version="0.12.0"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={["Nu kan der tilføjes og redigeres fag"]}
          version="0.11.0"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan der tilføjes kontakter med dertilhørende offentlig (hjemmeside) profil"
          ]}
          version="0.10.0"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan du se besked historik for en ansøger eller studerende."
          ]}
          version="0.9.1"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Du kan nu skrive en note hvis en ansøger er arkiveret",
            "Nu kan man udrtrække et excel ark med forsikringsinfo: Under en årgang, marker studerende, klik på flyveren og vent. Første gang du opretter et ark skal du tillade pop-ups i din browser"
          ]}
          version="0.9.0"
          changes={[
            "Telefonliste er nu fjernet",
            "Man kan nu udtrække et excel ark med telefon nummer og billede istedet for telefonlisten",
            "Ændrede alle hændelser af Classic Sport til Sport",
            "Fjernet VKH Kort og Nøglebrik felter fra studerende og tilføjet et Praktik kontrakt 2 felt"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.8.8"
          changes={[
            "Ændrede så en censor kan opdateres uden et telefon nummer"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.8.7"
          changes={["Rettede en fejl hvor censorer ikke kunne opdateres"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.8.5"
          changes={[
            "Graf på Dashboard virker nu",
            "Tilføjede Note felt på studerende excel udtræk",
            "Ændrede telefon liten så den kan laves på en linie istedet for en specifik klasse",
            "Rettede en fejl når et spreadsheet bliver oprettet hvor tomme felter ville skabe kaos"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan man rette brugere og tildele dem privilegier. Dette kan kun gøres af såkaldte 'Super Admins'",
            'Du vil nu se et badge under "Indstillinger" hvis du ikke har verificeret din email'
          ]}
          version="0.8.0"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Tilføjede muligheden for at trække adresse ud til excel ark under studerende"
          ]}
          version="0.7.2"
          changes={[
            "Rettede en fejl hvor man ikke længere kunne vælge 'Kontrakt modtaget' for en ansøger"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={["Man kan nu slette en given ansøger"]}
          version="0.7.1"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan man se om der er blevet afstendt en SMS til en ansøger dagen før. Hvis SMSen er fejlet vil den vise en 🛑 emoji på forsiden",
            "Der bliver pt. arbejdet på et forbedret dashboard med bedre statistik",
            "Nu kan man lave en excel oversigt over studerende med email",
            "Der er blevet tilføjet en dimitteret checkbox under hver enkel studerende. Datoen er pt. ikke sat automatisk",
            "Nu kan man se hvor en ansøger hørte om os fra, nederst på siden under en given ansøger"
          ]}
          version="0.7.0"
          changes={["Massere af små fejlrettelser"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.6.2"
          changes={["En fejl mht datoer for samtaler er blevet rettet"]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.6.1"
          changes={[
            "Nu kan man skifte linie på en studerende under Vælg linie"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan man tilføje en note til en studerende",
            "Under studerende kan der nu hentes en billedepakke med billeder og navne af alle studerende"
          ]}
          version="0.6.0 🎉"
          changes={[
            "Rette rækkeføljen på gade / by under studerende",
            "Rettede fejl hvor fødselsdag for en ansøger ikke var vist",
            "Nu bliver en ansøgers fødselsdag lavet om til CPR når de bliver rykket til studerende",
            "Ændrede layout på ansøgere så det er en smule mere overskueligt"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.5.7"
          changes={[
            "Rettede en fejl ved flytning af studerende -> ansøger",
            "Rettede fejl angående visning af datoer under ansøgere"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.5.6"
          changes={[
            "Fiksede en fejl hvor en studerende ikke kunne flyttes til udmeldte"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan du udmelde en studerende ved at vælge 'Skift Hold > Udmeldte'"
          ]}
          version="0.5.5"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Du kan nu oprette en ny ansøger under 'Ansøgere'. Flg. felter er påkrævede: Navn, By, Adresse, Postnr, Fødselsdag, email, telefon, motivation, erfaring og hvor de hørte om os."
          ]}
          version="0.5.4"
          changes={[
            "Nu virker det igen at sende beskeder til en specifik studerende",
            "Ændrede samtale person fra SRH til SH"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Du kan nu flytte en studerende tilbage til at være en ansøger",
            "Du kan se arkiverede ansøgere."
          ]}
          version="0.5.3"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.5.2"
          changes={[
            "Et par hotfixes der bla. fikser telefon listen og filvisning og upload til studerende"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nu kan du sortere efter 'Kontrakt modtaget' under studerende"
          ]}
          version="0.5.1"
          changes={[
            "Når en ansøger bliver opdateret bliver du automatisk taget tilbage til oversigten over ansøgere",
            "Fiksede et dobbelt tjek der var sneget sig ind når man flyttede en ansøger til studerende"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Tilføjede bank konto information til studerende",
            "Der kan oprette excel udtræk under studerende ved at vælge de relevante studerende og klikke på det lille 'tabel' ikon"
          ]}
          version="0.5.0"
          changes={[
            "En ansøger kan ikke længere konverteres til en studerende hvis der er mere end en linie valgt (Sports, X-Outdoor, Friluft)."
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Man kan nu oprette en studerende under et givent studieår"
          ]}
          version="0.4.0"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.3.3"
          changes={["Fiksede notifikationer som blev vist forkert"]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Tilføjede muligheden for at redigere og slette et eksamensfag"
          ]}
          version="0.3.2"
          changes={[]}
        />
        <ChangeItem
          classes={classes}
          additions={[]}
          version="0.3.1"
          changes={[
            "De forskellige bokse under en studerende overlapper ikke længere",
            "En censors fag er nu sorteret alfabetisk"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Nyt menupunkt samt funktionalitet: Censorer. Her kan man redigere, oprette og slette censorer."
          ]}
          version="0.3.0"
          changes={[
            "Telefonliste funktionen under studerende er blevet rettet så den viser en bedre udgave af telefonlisten",
            "En masse interne rettelser der får Intranettet til at fungere bedre",
            "Der er pt. en fejl hvor hvis du skal oprette en ny censor så kan formularen allerede være udfyldt med anden data. Hvis dette sker skal man bare opdatere din browser (⌘+R)"
          ]}
        />
        <ChangeItem
          classes={classes}
          additions={[
            "Ændringer bliver nu vist hvis man klikker på versions tallet øverst til venstre"
          ]}
          version="0.3.0"
          changes={[
            "Ændrede måden at filer bliver vist under en studerende",
            " Kosmetisk rettelse under en given årgang studerende",
            "Rettede filter listen under studerende så den giver bedre mening",
            "Telefon listen virker nu igen"
          ]}
        />
      </StyledGrid>
    );
  }
}

const ChangeItem = ({ version, changes, additions, }) => {
  return (
    <Grid item xs={12} md={10}>
      <Paper className={classes.paper}>
        <Typography variant="subtitle1">Version {version}</Typography>
        <Typography variant="body1">Tilføjelser</Typography>
        {additions.map((addition, i) => {
          return <Typography key={i}>• {addition}</Typography>;
        })}
        <Typography variant="body1">Ændringer</Typography>
        {changes.map((change, i) => {
          return <Typography key={i}>• {change}</Typography>;
        })}
      </Paper>
    </Grid>
  );
};

export default (ChangesContainer);
