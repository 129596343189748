import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  dischargeReasonsApplicants,
  dischargeReasonsStudents,
} from "../../helpers/dischargeReasons";
import SelectField from "../FormFields/SelectField";

const DischargedSelect = ({ classes, type, setFieldValue, disabled }) => {
  const dischargeReasons =
    type === "applicant"
      ? dischargeReasonsApplicants
      : dischargeReasonsStudents;
  return (
    <React.Fragment>
      <FormControl className={classes.dischargeSelect}>
        <InputLabel htmlFor="class">Grund for udmeldelse</InputLabel>
        <SelectField
          disabled={disabled}
          name="dischargeReasons"
          multiple={true}
          style={{ minWidth: 200, maxWidth: 350 }}
          onChange={(event) => {
            setFieldValue(
              "dischargeReasons",
              [].slice.call(event.target.value).map((option) => option)
            );
          }}
          options={dischargeReasons.map((reason) => ({
            value: reason,
            label: reason,
          }))}
        />
      </FormControl>
    </React.Fragment>
  );
};

DischargedSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DischargedSelect;
