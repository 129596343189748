import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  StudentForm,
  SafetyInsuranceForm,
  StudentTabBar,
  StudentGradeTable,
} from "..";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import StudentActionsMenu from "./StudentActionsMenu";
import { useFirebase } from "react-redux-firebase";
import { useHistory, useLocation } from "react-router";
import Student from "../../Classes/Student";
const PREFIX = "StudentComponent";

const classes = {
  leftIcon: `${PREFIX}-leftIcon`,
  rightIcon: `${PREFIX}-rightIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.leftIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const TabContainer = (props: any) => {
  return <div style={{ padding: 8 * 3 }}>{props.children}</div>;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface Props {
  saveStudent: (student: Student) => void;
  saveSafetyInfo: (safetyInfo: any) => void;
  progress: number;
  isUploading: boolean;
  isFetching: boolean;
  isSaving: boolean;
  student: Student;
  safetyInformation: any;
  semesters: string[];
  classNames: string[];
  creatingAccount: boolean;
  // createAccountSuccess
  // createAccountError
  handleCreateAccount: () => void;
  pagination: any;
  isFetchingPagination: boolean;
  removeSemester: () => void;
  handleSendMessage: () => void;
  handleViewMessages: () => void;
  handleFileChange: (path: string, file: File) => void;

  children: React.ReactNode;
}
const StudentComponent: React.FC<Props> = ({
  saveStudent,
  saveSafetyInfo,
  progress,
  isUploading,
  isFetching,
  isSaving,
  student,
  safetyInformation,
  semesters,
  classNames,
  creatingAccount,
  handleCreateAccount,
  pagination,
  isFetchingPagination,
  removeSemester,
  handleSendMessage,
  handleViewMessages,
  children,
}) => {
  const query = useQuery();
  const history = useHistory();
  const firebase = useFirebase();
  const { search } = useLocation();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (query.get("tab")) {
      const queryValue = parseInt(query.get("tab")!, 10);
      setTabValue(queryValue);
    }
  }, []);

  const handleChange = (_: any, value: number) => {
    history.push({ search: `?tab=${value}` });
    setTabValue(value);
  };
  // handleOpenMenu = (event) => {
  //   this.setState({ menuOpen: true, anchorEl: event.currentTarget });
  // };
  // handleCloseMenu = (event) => {
  //   this.setState({ menuOpen: false });
  // };

  // gradeDidSelect = (gradeId) => {
  //   this.props.gradeDidSelect(gradeId);
  // };

  const handleProfileImageFile = async (file: File) => {
    const storageRef = firebase.storage().ref();

    if (!student.id) {
      return;
    }

    if (!file.type.startsWith("image/")) {
      alert("Vælg venligst et billede");
      return;
    }
    const ext = file.name.split(".").pop();
    const fileName = `profile.${ext}`;
    const path = `students/${student.id}/${fileName}`;
    const fileRef = storageRef.child(path);
    try {
      await fileRef.put(file);
    } catch (error) {
      console.error(error);
    }

    // this.props.handleFileChange(path, file);
  };

  return (
    <>
      <StyledGrid container justifyContent="center" spacing={8}>
        <Grid item xs={12} lg={8}>
          <Paper elevation={4}>
            <StudentTabBar
              handleChange={handleChange}
              isFetching={isFetching}
              value={tabValue}
            />
            {(isSaving || isFetching) && <LinearProgress />}
            {isUploading && (
              <LinearProgress variant="determinate" value={progress} />
            )}

            {tabValue === 0 && (
              <TabContainer>
                {student && !isFetching && (
                  <StudentForm
                    saveStudent={(s) => saveStudent(s)}
                    removeSemester={removeSemester}
                    semesters={semesters}
                    classNames={classNames}
                    initialValues={student}
                    actionsMenu={
                      <StudentActionsMenu
                        creatingAccount={creatingAccount}
                        handleProfileImageFile={handleProfileImageFile}
                        handleSendMessage={handleSendMessage}
                        handleViewMessages={handleViewMessages}
                        handleCreateAccount={handleCreateAccount}
                      />
                    }
                  />
                )}
              </TabContainer>
            )}
            {tabValue === 1 && (
              <TabContainer>
                <SafetyInsuranceForm
                  saveSafetyInfo={(si: any) => saveSafetyInfo(si)}
                  safetyInformation={safetyInformation}
                />
              </TabContainer>
            )}
            {tabValue === 2 && <TabContainer>{children}</TabContainer>}
          </Paper>
          {tabValue === 3 && (
            <TabContainer>
              <StudentGradeTable studentId={student.id} />
            </TabContainer>
          )}
        </Grid>
      </StyledGrid>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <PreviousNextButtons
            pagination={pagination}
            isFetching={isFetchingPagination}
            semester={student.semester}
            search={search || ""}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface PaginationProps {
  pagination: any;
  search: string;
  semester: string;
  isFetching: boolean;
}
const PreviousNextButtons: React.FC<PaginationProps> = ({
  pagination,
  search,
  semester,
  isFetching,
}) => {
  const next = pagination["next"];
  const previous = pagination["previous"];
  const history = useHistory();
  let nextKey: string | null = null;
  let previousKey: string | null = null;
  if (next) {
    nextKey = Object.keys(next)[0];
  }
  if (previous) {
    previousKey = Object.keys(previous)[0];
  }
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-between"
      style={{ marginTop: 8, padding: 16 }}
    >
      <Button
        disabled={!previousKey || isFetching}
        color="primary"
        variant="contained"
        onClick={() =>
          history.push(`/semesters/${semester}/student/${previousKey}${search}`)
        }
      >
        <KeyboardArrowLeft className={classes.leftIcon} />
        {(previousKey && previous[previousKey]) || "Ej muligt"}
      </Button>
      <Button
        disabled={!nextKey || isFetching}
        color="primary"
        variant="contained"
        onClick={() =>
          history.push(`/semesters/${semester}/student/${nextKey}${search}`)
        }
      >
        {(nextKey && next[nextKey]) || "Ej muligt"}
        <KeyboardArrowRight className={classes.rightIcon} />
      </Button>
    </Grid>
  );
};

export default StudentComponent;
