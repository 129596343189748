import React, { useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AppState } from "../../reducers";
import { IEmployee } from "../Employee";
import { useHistory } from "react-router";

const EMPLOYEES_COLLECTION = "employees";

const columns = (history: any): GridColDef[] => [
  {
    field: "actions",
    type: "actions",
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        icon={<VisibilityIcon />}
        onClick={() => {
          history.push(`/employees/${params.row.id}`);
        }}
        label="Se"
      />,
    ],
  },
  {
    field: "firstName",
    headerName: "Fornavn",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Efternavn",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
  },
];

type EmployeesTableProps = {
  setSelection: (selection: IEmployee[]) => void;
};

const EmployeesTable: React.FC<EmployeesTableProps> = ({ setSelection }) => {
  const theme = useTheme();
  const history = useHistory();
  useFirestoreConnect([EMPLOYEES_COLLECTION]);
  const employees = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.employees || []
  );

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    const selectedEmployees = newSelection.map((id) =>
      employees.find((emp) => emp.id === id)
    );
    setSelection(selectedEmployees as IEmployee[]);
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <DataGrid
        rows={employees}
        columns={columns(history)}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
};

export default EmployeesTable;
