import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { compose } from "recompose";
import { MessagesComponent } from "../../components";
import { sendMessages, removeRecipient } from "../../actions/messages";

const PREFIX = "MessagesContainer";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(3),
  },
}));

class MessagesContainer extends Component {
  state = {
    message: "",
    messageLength: 0,
    phone: "",
    email: "",
    name: "",
    phoneError: "",
    isForEmailDelivery: false,
    isForSMSDelivery: false,
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    let phoneError = name === "phone" ? "" : this.state.phoneError;
    if (name === "phone") {
      phoneError = this.checkForPhoneError(value);
    }

    this.setState({
      [name]: value,
      messageLength:
        name === "message" ? value.length : this.state.messageLength,
      phoneError,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  checkForPhoneError = (phone) => {
    if (!phone.match(/^\+[1-9]{1}[0-9]{3,14}$/)) {
      return "Check at telefon nummeret inkluderer landekoden (eks. +45)";
    }
    return "";
  };

  handleRemoveRecipient = (recipient) => {
    this.props.removeRecipient(recipient);
  };

  componentDidMount() {
    this.shouldSetSingleRecipient();
  }

  shouldSetSingleRecipient = () => {
    const { recipients, multiple } = this.props;
    if (!multiple && recipients[0]) {
      let { name, phone, email, message } = recipients[0];
      if (!message) message = "";
      this.setState({
        name,
        phone,
        phoneError: this.checkForPhoneError(phone),
        email,
        message,
      });
    }
  };

  handleSend = () => {
    const { isForEmailDelivery, isForSMSDelivery } = this.state;
    const opts = { isForEmailDelivery, isForSMSDelivery };
    this.props.sendMessages(this.props.recipients, this.state.message, opts);
  };

  render() {
    const {
      classes,
      isSending,
      messageSendSuccess,
      error,
      multiple,
      recipients,
    } = this.props;
    return (
      <StyledGrid container justifyContent="center">
        <Grid item xs={12} lg={10} xl={8}>
          <Paper sx={{ margin: 1 }} elevation={8}>
            <MessagesComponent
              {...this.state}
              multiple={multiple}
              recipients={recipients}
              onMessageChange={this.handleChange("message")}
              onPhoneChange={this.handleChange("phone")}
              onEmailChange={this.handleChange("email")}
              onNameChange={this.handleChange("name")}
              handleSend={() => this.handleSend()}
              handleRemoveRecipient={this.handleRemoveRecipient}
              handleCheckboxChange={this.handleCheckboxChange}
              isSending={isSending}
              messageSendSuccess={messageSendSuccess}
              error={error}
            />
          </Paper>
        </Grid>
      </StyledGrid>
    );
  }
}
const mapStateToProps = ({ messages }) => {
  return {
    ...messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessages: (recipients, message, opts) =>
      dispatch(sendMessages(recipients, message, opts)),
    removeRecipient: (recipient) => dispatch(removeRecipient(recipient)),
  };
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MessagesContainer
);
