import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";

const PREFIX = "SettingsComponent";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  grid: `${PREFIX}-grid`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.grid}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    width: 200,
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
  },
}));

const SettingsComponent = ({

  email,
  emailVerified,
  handleNotificationChange,
  applicantsOverdue,
  newApplicant,
  handleVerifyEmail,
  isVerifying,
  verifySuccess,
  isUpdating,
  isFetching,
}) => {
  return (
    <Root>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} className={classes.root}>
          <Typography variant="caption">Verificer email</Typography>
          <Paper elevation={6} className={classes.paper}>
            <Typography variant="body1">Verificer email</Typography>

            {emailVerified ? (
              <Typography>Din email er verificeret</Typography>
            ) : (
              <Badge badgeContent={1} color="secondary">
                <Typography>
                  For at kunne modtage notifikationer og email skal du
                  verificere din email.
                </Typography>
              </Badge>
            )}

            <Grid container>
              <Grid item xs={10}>
                <form noValidate autoComplete="off">
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={email}
                    disabled={true}
                    margin="normal"
                  />
                </form>
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={isVerifying || verifySuccess || emailVerified}
                  className={classes.button}
                  onClick={handleVerifyEmail}
                  color="primary"
                >
                  Verificer
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} className={classes.root}>
          <Typography variant="caption">Notifikationer</Typography>
          <Paper elevation={6} className={classes.paper}>
            <Grid container>
              <Grid item xs={12} className={classes.grid}>
                <Typography variant="body1">
                  Indstil hvilke notifikationer du får
                </Typography>
                <Typography>
                  Dine notifikationer vil blive sendt til den email du er logget
                  ind med: <i>{email}</i>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Ansøgere
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1" gutterBottom>
                  Nye ansøgere
                </Typography>
                <Typography gutterBottom>
                  Modtag notifikationer for nye ansøgere
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={newApplicant}
                  disabled={isUpdating || isFetching}
                  onChange={handleNotificationChange("newApplicant")}
                  aria-label="new-applicant"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1" gutterBottom>
                  Ansøgere der har overskredet 2 uger
                </Typography>
                <Typography gutterBottom>
                  Modtag notifikationer for ansøgere der har overskredet 2 uger
                  siden afsendt kontrakt
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={applicantsOverdue}
                  disabled={isUpdating || isFetching}
                  onChange={handleNotificationChange("applicantsOverdue")}
                  aria-label="applicants-overdue"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default SettingsComponent;
