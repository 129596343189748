import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  internalContactGroups,
  externalContactGroups
} from "../../helpers/contactGroups";
import Button from "@mui/material/Button";

const PREFIX = 'ContactsActionList';

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: "100%"
  }
}));

const ContactsActionList = ({
  handleCreateNewContact,
  memberOfValue,
  memberOfDidChange,
  isExternal
}) => {
  const contactGroups = isExternal
    ? externalContactGroups
    : internalContactGroups;

  return (
    <Root className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%", margin: 8 }}
        onClick={handleCreateNewContact}
      >
        Ny Kontakt
      </Button>
      <Divider />
      <FormControl className={classes.formControl}>
        <InputLabel>Vælg gruppe</InputLabel>
        <Select value={memberOfValue} onChange={memberOfDidChange}>
          <MenuItem value="all">Alle</MenuItem>
          {Object.keys(contactGroups).map(key => {
            return (
              <MenuItem key={key} value={key}>
                {contactGroups[key]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Root>
  );
};

ContactsActionList.propTypes = {
  handleCreateNewContact: PropTypes.func.isRequired
};

export default (ContactsActionList);
