import {
  FETCHING_CONTACTS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_ERROR,
  CREATING_PRIVATE_CONTACT,
  CREATE_PRIVATE_CONTACT_SUCCESS,
  CREATE_PRIVATE_CONTACT_ERROR,
  FETCHING_CONTACT,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_ERROR,
  SAVING_PRIVATE_CONTACT,
  SAVE_PRIVATE_CONTACT_SUCCESS,
  SAVE_PRIVATE_CONTACT_ERROR,
  DELETING_CONTACTS,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_ERROR,
  FETCHING_CONTACTS_PAGINATION,
  FETCH_CONTACTS_PAGINATION_SUCCESS,
  FETCH_CONTACTS_PAGINATION_ERROR,
} from "../constants";
import { db } from "../helpers/firebaseHelper";
import { openSnackbar } from "./snackbar";
import { push } from "connected-react-router";
import * as _ from "lodash";

export function createPrivateContact(contact) {
  return async (dispatch) => {
    dispatch({ type: CREATING_PRIVATE_CONTACT });
    const batch = db.batch();
    const profileRef = db.collection("contacts").doc();
    // Set the following bools as we need them to query items
    batch.set(profileRef, {
      ...contact,
      id: profileRef.id,
      createdAt: new Date(),
    });

    try {
      await batch.commit();
      dispatch({ type: CREATE_PRIVATE_CONTACT_SUCCESS });
      dispatch(openSnackbar("✅ Kontakten blev oprettet"));
      dispatch(push(`/contacts/${profileRef.id}`));
    } catch (e) {
      dispatch({ type: CREATE_PRIVATE_CONTACT_ERROR, error: e });
      dispatch(openSnackbar(`🛑 Kontakten kunne ikke oprettes: ${e}`));
    }
  };
}

export function fetchContacts(
  query = { isExternal: false, isArchived: false }
) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_CONTACTS });
    let contactsRef = db.collection("contacts");

    if (!_.isEmpty(query)) {
      //eslint-disable-next-line no-unused-vars
      for (let key in query) {
        contactsRef = contactsRef.where(key, "==", query[key]);
      }
    }

    try {
      const docs = await contactsRef.get();

      let contacts = docs.docs;
      contacts = contacts.filter((c) => c.exists).map((c) => c.data());

      dispatch({ type: FETCH_CONTACTS_SUCCESS, contacts: contacts });
    } catch (e) {
      console.log(e);
      dispatch({ type: FETCH_CONTACTS_ERROR, error: e });
      dispatch(openSnackbar(`🛑 Kunne ikke hente kontakter: ${e}`));
    }
  };
}

export function fetchContact(id) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_CONTACT });
    if (!id) {
      dispatch({
        type: FETCH_CONTACT_ERROR,
        error: { message: "No id specified" },
      });
      dispatch(
        openSnackbar(`🛑 Kunne ikke hente kontakt: Ingen id specificeret`)
      );
      return;
    }

    const docRef = db.collection("contacts").doc(id);
    try {
      const doc = await docRef.get();
      dispatch({ type: FETCH_CONTACT_SUCCESS, contact: doc.data() });

      dispatch(fetchPagination(doc.data()));
    } catch (e) {
      dispatch({
        type: FETCH_CONTACT_ERROR,
        error: e,
      });
      dispatch(openSnackbar(`🛑 Kunne ikke hente kontakt: ${e}`));
    }
  };
}

export function savePrivateContact(contact) {
  return async (dispatch) => {
    dispatch({ type: SAVING_PRIVATE_CONTACT });

    if (!contact.id) return;

    const privateDocRef = db.doc(`contacts/${contact.id}`);
    try {
      await privateDocRef.update({ ...contact, updatedAt: new Date() });
      // Update the contact in the store with the updated values from the form
      // Do so before the save success is fired to avoid form flickering
      dispatch({ type: FETCH_CONTACT_SUCCESS, contact: contact });
      dispatch({ type: SAVE_PRIVATE_CONTACT_SUCCESS });
      dispatch(openSnackbar("✅ Kontakten blev opdateret"));
    } catch (e) {
      dispatch({
        type: SAVE_PRIVATE_CONTACT_ERROR,
        error: e,
      });
      dispatch(openSnackbar(`🛑 Kunne ikke gemme kontakt: ${e}`));
    }
  };
}

export function deleteContacts(ids) {
  return async (dispatch) => {
    dispatch({ type: DELETING_CONTACTS });
    const batch = db.batch();

    ids.map((id) => {
      const docRef = db.collection("contacts").doc(id);
      const privateRef = docRef;
      const publicRef = docRef.collection("public").doc("profile");
      batch.delete(docRef);
      batch.delete(privateRef);
      batch.delete(publicRef);
      return id;
    });
    try {
      await batch.commit();
      dispatch(openSnackbar(`✅ Slettede ${ids.length} kontakter`));
      dispatch({ type: DELETE_CONTACTS_SUCCESS });
      dispatch(fetchContacts());
    } catch (e) {
      dispatch({ type: DELETE_CONTACTS_ERROR, error: e });
      dispatch(openSnackbar(`🛑 Kunne ikke slette kontakter: ${e}`));
    }
  };
}

export function fetchPagination(contact) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_CONTACTS_PAGINATION });
    let baseQuery = db
      .collection("contacts")
      .where("isExternal", "==", contact.isExternal)
      // If the current contact is not archived, the next contact we fetch shouldn't be either
      .where("isArchived", "==", contact.isArchived);

    //Get the previous and next contacts with these two queries below
    try {
      const pagination = {};
      let next;
      let previous;
      // We query on different props depending on wether the contact is internal or external
      if (!contact.isExternal) {
        next = await baseQuery
          .orderBy("firstName")
          .orderBy("lastName")
          .startAfter(contact.firstName, contact.lastName)
          .limit(1)
          .get();

        previous = await baseQuery
          .orderBy("firstName", "desc")
          .orderBy("lastName", "desc")
          .startAfter(contact.firstName, contact.lastName)
          .limit(1)
          .get();
      } else {
        next = await baseQuery
          .orderBy("name")
          .startAfter(contact.name)
          .limit(1)
          .get();

        previous = await baseQuery
          .orderBy("name", "desc")
          .startAfter(contact.name)
          .limit(1)
          .get();
      }

      pagination["next"] = getPageinationName(next.docs, contact.isExternal);
      pagination["previous"] = getPageinationName(
        previous.docs,
        contact.isExternal
      );

      dispatch({ type: FETCH_CONTACTS_PAGINATION_SUCCESS, pagination });
    } catch (e) {
      console.log("Could not fetch pagination");
      console.log(e);
      dispatch({ type: FETCH_CONTACTS_PAGINATION_ERROR, error: e });
    }
  };
}

const getPageinationName = (docs, isExternal) => {
  if (docs.length > 0) {
    if (docs[0].exists) {
      const data = docs[0].data();
      //Internal contact are names of persons, external can be both persons or companies
      return {
        [data.id]: !isExternal
          ? `${data.firstName} ${data.lastName}`
          : `${data.name}`,
      };
    }
  }
};
