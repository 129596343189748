import * as React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import {
  FormLabel,
  OutlinedInput as MuiOutlinedInput,
  OutlinedInputProps as MuiOutlinedInputProps,
} from "@mui/material";
import { useField, getIn, FieldAttributes, FieldProps } from "formik";

type OutlinedFieldProps = {
  label: string;
  disabled?: boolean;
} & FieldAttributes<unknown>;

export function OutlinedField({ label, ...props }: OutlinedFieldProps) {
  const [field, meta] = useField(props);
  const errorText =
    getIn(meta.touched, field.name) && getIn(meta.error, field.name);

  return (
    <>
      <FormLabel
        htlm-for={props.id}
        required={props.required}
        error={!!errorText}
      >
        {errorText ? errorText : label}
      </FormLabel>
      <MuiOutlinedInput id={props.id} {...field} />
    </>
  );
}

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

export function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export default function TextField({ children, ...props }: TextFieldProps) {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}
