import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import Transition from "react-transition-group/Transition";
import DoneIcon from "@mui/icons-material/Done";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import blue from "@mui/material/colors/blue";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import { Grid2 } from "@mui/material";

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const Fade = ({
  in: inProp,
  children,
}: {
  in: any;
  children: React.ReactNode;
}) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...(transitionStyles[state] as any),
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

type Recipient = {
  name: string;
  status: string;
  error?: string;
};

export const RecipientChip = ({
  recipient,

  handleRemoveRecipient,
}: {
  recipient: Recipient;

  handleRemoveRecipient: (recipient: Recipient) => void;
}) => {
  const status = recipient.status;
  const not_submitted = status === "not_submitted";
  const iconPending = status === "not_submitted" || status === "pending";
  let icon;
  if (iconPending) {
    icon = (
      <CancelIcon
        sx={(theme) => ({ color: theme.palette.getContrastText(chipColor) })}
      />
    );
  }
  if (status === "success") {
    icon = (
      <DoneIcon
        sx={(theme) => ({ color: theme.palette.getContrastText(chipColor) })}
      />
    );
  }
  if (status === "error") {
    icon = (
      <Tooltip placement="bottom" title={recipient.error}>
        <ErrorIcon
          sx={(theme) => ({ color: theme.palette.getContrastText(chipColor) })}
        />
      </Tooltip>
    );
  }
  return (
    <Chip
      label={recipient.name}
      sx={(theme) => ({
        margin: 4,
        backgroundColor: status === "error" ? chipErrorColor : chipColor,
        color:
          status === "error"
            ? theme.palette.getContrastText(chipErrorColor)
            : theme.palette.getContrastText(chipColor),
      })}
      deleteIcon={icon}
      onDelete={
        not_submitted ? () => handleRemoveRecipient(recipient) : undefined
      }
    />
  );
};

const chipColor = blue[500];
const chipErrorColor = red[500];

type MessagesComponentProps = {
  message: string;
  recipients: Recipient[];
  phone: string;
  email: string;
  name: string;
  multiple: boolean;
  phoneError: string;
  onMessageChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onPhoneChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onEmailChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onNameChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  messageLength: number;
  isForEmailDelivery: boolean;
  isForSMSDelivery: boolean;
  isSending: boolean;
  messageSendSuccess: boolean;
  handleSend: () => void;
  handleRemoveRecipient: (recipient: Recipient) => void;
  handleCheckboxChange: (
    field: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  // Add new props here if needed
  // someNewProp: SomeType;
};

const MessagesComponent = ({
  message,
  phone,
  email,
  name,
  multiple,
  messageLength,
  recipients,

  onMessageChange,
  onPhoneChange,
  onEmailChange,
  onNameChange,
  handleSend,
  isForSMSDelivery,
  isForEmailDelivery,
  handleCheckboxChange,
  isSending,
  messageSendSuccess,
  phoneError,
  handleRemoveRecipient,
}: MessagesComponentProps) => (
  <div>
    <Grid2 container sx={{ padding: 4 }}>
      <Grid2 size={{ xs: 12 }}>
        <Fade in={!isSending}>
          <Typography variant="h4">
            {multiple ? "Send beskeder" : "Send besked"}
          </Typography>
          <Typography variant="subtitle1">
            {multiple
              ? "Husk: For at du kan sende SMS'er skal telefonnumre have en lande kode. Hvis du sender SMSer til personer uden landekode vil disse ikke blive sendt."
              : "Send en besked ved at indtaste email og/eller telefon nummer. Telefonnummeret skal være inklusive landekoden. Eks. +4533298025"}
          </Typography>
        </Fade>
        <Fade in={isSending}>
          <Typography sx={{ color: blue[500] }} variant="h2">
            Din besked er igang med at blive sendt. Du kan sikkert navigere væk
            fra denne side.
          </Typography>
        </Fade>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        {!multiple && (
          <TextField
            sx={{ width: 300 }}
            id="name"
            label="Navn"
            required
            disabled={multiple}
            onChange={onNameChange}
            value={name}
            type="text"
            margin="normal"
          />
        )}
      </Grid2>
      {!multiple && [
        <Grid2 key="grid-email" size={{ xs: 12, md: 6 }}>
          <TextField
            sx={{ width: 300 }}
            id="email"
            label="Email"
            disabled={multiple}
            onChange={onEmailChange}
            value={email}
            type="email"
            margin="normal"
          />
        </Grid2>,
        <Grid2 key="grid-tel" size={{ xs: 12, md: 6 }}>
          <TextField
            sx={{ width: 300 }}
            error={phoneError.length > 1 && phone !== ""}
            id="phone"
            label="Telefon"
            disabled={messageLength > 160 || multiple}
            onChange={onPhoneChange}
            value={phone}
            type="tel"
            margin="normal"
          />
          {phoneError.length > 1 && phone !== "" && (
            <Typography variant="caption">{phoneError}</Typography>
          )}
        </Grid2>,
      ]}
      <Grid2 size={{ xs: 12 }}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={isForEmailDelivery}
                onChange={handleCheckboxChange("isForEmailDelivery")}
                value="email"
              />
            }
            label={"Email"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isForSMSDelivery}
                onChange={handleCheckboxChange("isForSMSDelivery")}
                value="sms"
              />
            }
            label={"SMS"}
          />
        </FormGroup>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <TextField
          id="message"
          label="Besked"
          placeholder="Besked"
          onChange={onMessageChange}
          multiline
          value={message}
          rows={6}
          fullWidth
          margin="normal"
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Typography variant="caption">
          Du kan maks bruge 160 bogstaver når du sender sms. {messageLength}/160
          bogstaver. Beskeden skal minimum være 15 bogstaver.
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 4 }}>
        {isSending ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={
              message.length < 15 ||
              messageSendSuccess ||
              !(isForEmailDelivery || isForSMSDelivery)
            }
            onClick={handleSend}
          >
            Send
            <SendIcon />
          </Button>
        )}
        {messageSendSuccess && (
          <Avatar
            sx={{
              margin: 10,
              color: "#fff",
              backgroundColor: green[500],
              float: "right",
              width: "35px",
              height: "35px",
            }}
          >
            <DoneIcon color="secondary" />
          </Avatar>
        )}
      </Grid2>
    </Grid2>
    <Grid2 sx={{ padding: 4 }} container>
      <Grid2 size={{ xs: 12 }}>
        <Typography variant="subtitle1">Modtagere:</Typography>
        <div>
          {recipients.map((recipient: Recipient, i: number) => {
            return (
              <RecipientChip
                recipient={recipient}
                key={i}
                handleRemoveRecipient={handleRemoveRecipient}
              />
            );
          })}
        </div>
      </Grid2>
    </Grid2>
  </div>
);

MessagesComponent.propTypes = {
  message: PropTypes.string.isRequired,
  recipients: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  phoneError: PropTypes.string.isRequired,
  messageLength: PropTypes.number.isRequired,
  isForEmailDelivery: PropTypes.bool.isRequired,
  isForSMSDelivery: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  handleRemoveRecipient: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};
export default MessagesComponent;
