import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import NotificationCardComponent from "./NotificationCardComponent";
import green from "@mui/material/colors/green";
import red from "@mui/material/colors/red";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

const PREFIX = 'NotificationsComponent';

const classes = {
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  flex: `${PREFIX}-flex`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  listFull: `${PREFIX}-listFull`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  avatar: `${PREFIX}-avatar`,
  greenAvatar: `${PREFIX}-greenAvatar`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.list}`]: {},
  [`& .${classes.listItem}`]: {},

  [`& .${classes.flex}`]: {
    flex: 1,
  },

  [`& .${classes.drawerPaper}`]: {
    height: "100%",
    width: 400,
  },

  [`& .${classes.listFull}`]: {
    width: "auto",
  },

  [`& .${classes.expand}`]: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: red[500],
  },

  [`& .${classes.greenAvatar}`]: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  }
}));

const NotificationsComponent = ({  open, toggleNotifications }) => {
  const uid = useSelector((state) => state.auth.user.uid || "");
  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: "users",
      doc: uid,
      subcollections: [
        { collection: "notifications", orderBy: ["created_at", "desc"] },
      ],
      storeAs: `${uid}-notifications`,
    },
  ]);

  const notifications = useSelector(
    (state) => state.firestore.ordered[`${uid}-notifications`] || []
  );

  const clearNotifications = async (ids) => {
    const ref = firestore
      .collection("users")
      .doc(uid)
      .collection("notifications");
    const batch = firestore.batch();
    ids.map((id) => {
      batch.delete(ref.doc(id));
      return null;
    });
    await batch.commit();
  };

  const setNotificationsRead = async (ids) => {
    const ref = firestore
      .collection("users")
      .doc(uid)
      .collection("notifications");
    const batch = firestore.batch();
    ids.map((id) => {
      batch.update(ref.doc(id), { read: true });
      return null;
    });
    await batch.commit();
  };

  return (
    <Root>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => toggleNotifications()}
        open={open}
      >
        <List className={classes.list}>
          <ListItem
            button
            className={classes.listItem}
            id="list-button"
            onClick={() => {
              clearNotifications(notifications.map((n) => n.id));
            }}
          >
            <ListItemIcon>
              <ClearAllIcon />
            </ListItemIcon>
            <ListItemText primary={"Ryd alle"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNotificationsRead(notifications.map((n) => n.id));
            }}
          >
            <ListItemIcon>
              <DoneAllIcon />
            </ListItemIcon>
            <ListItemText primary={"Marker alle som læst"} />
          </ListItem>
        </List>
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification, i) => {
            return (
              <NotificationCardComponent
                key={i}
                notification={notification}
                notificationRead={(ids) => {
                  setNotificationsRead(ids);
                }}
                clearNotification={(ids) => {
                  clearNotifications(ids);
                }}
              />
            );
          })
        ) : (
          <Card>
            <CardHeader
              avatar={
                <Avatar className={classes.greenAvatar} size={50}>
                  <ThumbUpIcon />
                </Avatar>
              }
              title={"Du er up-to-date"}
            />
          </Card>
        )}
      </Drawer>
    </Root>
  );
};
NotificationsComponent.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default (NotificationsComponent);
