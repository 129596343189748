import React from "react";
import { useField, FieldAttributes } from "formik";
import { convertToPlainDate } from "../../helpers/parser";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Delete from "@mui/icons-material/Delete";

type DateFieldProps = {
  label: string;
  disabled?: boolean;
  maxDate?: Date;
  showDeleteIcon?: boolean | null;
} & FieldAttributes<unknown>;

export const DateField: React.FC<DateFieldProps> = ({
  label,
  disabled,
  maxDate,
  showDeleteIcon = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const valueOrNull = (value: moment.Moment | null): Date | null => {
    return convertToPlainDate(value);
  };

  return (
    <DatePicker
      disabled={disabled}
      {...field}
      label={label}
      maxDate={maxDate}
      value={valueOrNull(field.value as moment.Moment)}
      // inputFormat="DD MMM YYYY"
      views={["day", "month", "year"]}
      //InputProps={{
      //endAdornment: showDeleteIcon ? (
      //<InputAdornment position="end">
      //<IconButton onClick={() => setValue(null)} size="large">
      //<Delete />
      //</IconButton>
      //</InputAdornment>
      //) : null,
      //}}
      slotProps={{ textField: { variant: "outlined" } }}
      onChange={(value) => {
        if (value) {
          setValue(value);
        }
      }}
    />
  );
};

export const DateTimeField: React.FC<DateFieldProps> = ({
  label,
  disabled,
  showDeleteIcon = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const valueOrNull = (value: moment.Moment | null): Date | null => {
    return convertToPlainDate(value);
  };

  return (
    <DateTimePicker
      {...field}
      disabled={disabled}
      label={label}
      ampm={false}
      value={valueOrNull(field.value as moment.Moment)}
      // inputFormat="LLL"
      //InputProps={{
      //  endAdornment: showDeleteIcon ? (
      //    <InputAdornment position="end">
      //      <IconButton onClick={() => setValue(null)} size="large">
      //        <Delete />
      //      </IconButton>
      //    </InputAdornment>
      //  ) : null,
      //}}
      // renderInput={(params) => <TextField sx={{ minWidth: 200 }} {...params} />}
      slotProps={{ textField: { variant: "outlined", sx: { minWidth: 200 } } }}
      onChange={(value) => {
        if (value) {
          setValue(value);
        }
      }}
    />
  );
};
