import { push } from "connected-react-router";
import { db, FieldValue } from "../helpers/firebaseHelper";
import { openSnackbar } from "./snackbar";
import { firebaseAuth } from "../helpers/firebaseHelper";
import _ from "lodash";
import {
  DEFAULT_PROFILE_IMG_URL,
  FETCHING_STUDENTS,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_ERROR,
  FETCHING_STUDENT,
  FETCH_STUDENT_SUCCESS,
  FETCH_STUDENT_ERROR,
  SAVING_STUDENT,
  SAVE_STUDENT_SUCCESS,
  SAVE_STUDENT_ERROR,
  FETCHING_SAFETY_INFORMATION,
  FETCH_SAFETY_INFORMATION_SUCCESS,
  FETCH_SAFETY_INFORMATION_ERROR,
  SAVING_SAFETY_INFORMATION,
  SAVE_SAFETY_INFORMATION_SUCCESS,
  SAVE_SAFETY_INFORMATION_ERROR,
  DELETING_STUDENTS,
  DELETE_STUDENTS_SUCCESS,
  DELETE_STUDENTS_ERROR,
  FETCHING_CLASSES,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASSES_ERROR,
  CREATING_ACCOUNT_FOR_STUDENT,
  CREATE_ACCOUNT_FOR_STUDENT_ERROR,
  CREATE_ACCOUNT_FOR_STUDENT_SUCCESS,
  FETCHING_STUDENT_GRADES,
  FETCH_STUDENT_GRADES_SUCCESS,
  FETCH_STUDENT_GRADES_ERROR,
  FETCHING_STUDENT_PAGINATION,
  FETCH_STUDENT_PAGINATION_SUCCESS,
  FETCH_STUDENT_PAGINATION_ERROR,
  CREATING_STUDENT,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_ERROR,
  SET_TAB_VALUE_STUDENTS,
  SET_QUERY_FOR_STUDENTS,
  TOGGLE_GRADING_DIALOG,
  SETTING_STUDENT_PROPERTY,
  SETTING_PROPERTY_SUCCESS,
  SETTING_PROPERTY_ERROR,
} from "../constants";

export function fetchingStudents() {
  return {
    type: FETCHING_STUDENTS,
  };
}

export function fetchingStudent() {
  return {
    type: FETCHING_STUDENT,
  };
}

export function fetchingSafetyInformation() {
  return {
    type: FETCHING_SAFETY_INFORMATION,
  };
}

export function fetchStudentsSuccess(students) {
  return {
    type: FETCH_STUDENTS_SUCCESS,
    students: students,
  };
}

export function fetchStudentSuccess(student) {
  return {
    type: FETCH_STUDENT_SUCCESS,
    student: student,
  };
}

export function fetchSafetyInformationSuccess(information) {
  return {
    type: FETCH_SAFETY_INFORMATION_SUCCESS,
    safetyInformation: information,
  };
}

export function fetchStudentsError(e) {
  return {
    type: FETCH_STUDENTS_ERROR,
    error: e,
  };
}

export function fetchStudentError(e) {
  return {
    type: FETCH_STUDENT_ERROR,
    error: e,
  };
}

export function fetchSafetyInformationError(e) {
  return {
    type: FETCH_SAFETY_INFORMATION_ERROR,
    error: e,
  };
}

export function savingStudent() {
  return {
    type: SAVING_STUDENT,
  };
}

export function saveStudentSuccess(docRef) {
  return {
    type: SAVE_STUDENT_SUCCESS,
    docRef: docRef,
  };
}

export function saveStudentError(e) {
  return {
    type: SAVE_STUDENT_ERROR,
    error: e,
  };
}

export function savingSafetyInformation() {
  return {
    type: SAVING_SAFETY_INFORMATION,
  };
}

export function saveSafetyInformationSuccess() {
  return {
    type: SAVE_SAFETY_INFORMATION_SUCCESS,
  };
}

export function saveSafetyInformationError(e) {
  return {
    type: SAVE_SAFETY_INFORMATION_ERROR,
    error: e,
  };
}

export function deletingStudents() {
  return {
    type: DELETING_STUDENTS,
  };
}

export function deleteStudentsSuccess() {
  return {
    type: DELETE_STUDENTS_SUCCESS,
  };
}

export function deleteStudentsError(e) {
  return {
    type: DELETE_STUDENTS_ERROR,
    error: e,
  };
}

export function fetchingClasses() {
  return {
    type: FETCHING_CLASSES,
  };
}

export function fetchClassesSuccess(classes) {
  return {
    type: FETCH_CLASSES_SUCCESS,
    classes: classes,
  };
}
export function fetchClassesError(e) {
  return {
    type: FETCH_CLASSES_ERROR,
    error: e,
  };
}

function creatingAccountForStudent() {
  return {
    type: CREATING_ACCOUNT_FOR_STUDENT,
  };
}

function createAccountForStudentSuccess() {
  return {
    type: CREATE_ACCOUNT_FOR_STUDENT_SUCCESS,
  };
}

function createAccountForStudentError(e) {
  return {
    type: CREATE_ACCOUNT_FOR_STUDENT_ERROR,
    error: e,
  };
}

function fetchingStudentGrades() {
  return {
    type: FETCHING_STUDENT_GRADES,
  };
}

function fetchStudentGradesSuccess(grades) {
  return {
    type: FETCH_STUDENT_GRADES_SUCCESS,
    grades,
  };
}

function fetchStudentGradesError(error) {
  return {
    type: FETCH_STUDENT_GRADES_ERROR,
    error,
  };
}

function fetchingPagination() {
  return {
    type: FETCHING_STUDENT_PAGINATION,
  };
}

function fetchPaginationSuccess(pagination) {
  return {
    type: FETCH_STUDENT_PAGINATION_SUCCESS,
    pagination,
  };
}

function fetchPaginationError(error) {
  return {
    type: FETCH_STUDENT_PAGINATION_ERROR,
    error,
  };
}

function fetchSafetyInformation(studentId) {
  return (dispatch) => {
    dispatch(fetchingSafetyInformation());
    db.collection("students")
      .doc(studentId)
      .collection("additionalInformation")
      .doc("safetyInformation")
      .onSnapshot((doc) => {
        if (!doc.exists) {
          dispatch(
            fetchSafetyInformationError({
              error:
                "Sikkerhedsinformation eksisterer ikke for denne studerende",
            })
          );
          return;
        }
        dispatch(fetchSafetyInformationSuccess(doc.data()));
      });
  };
}

export function fetchStudents(q = {}) {
  return (dispatch, getState) => {
    dispatch(fetchingStudents());
    let queryRef = db.collection("students");

    // The query is being set in the students container, however
    // there can be other queries passed directly as an argument to this function
    let { query } = getState().students;
    console.log(query);
    // Check if the query has been set in redux or as a parameter
    if (_.isEmpty(query) && _.isEmpty(q)) {
      dispatch(
        openSnackbar("🛑 Uventet fejl: Intet semester eller år angivet")
      );
      return;
    }

    if (!_.isEmpty(q)) {
      query = q;
    }
    //eslint-disable-next-line no-unused-vars
    for (let key in query) {
      queryRef = queryRef.where(key, "==", query[key]);
    }
    queryRef.onSnapshot(
      (docs) => {
        const students = [];
        docs.forEach((doc) => {
          const student = doc.data();
          student.id = doc.id;
          student.interviewer = students.interviewer
            ? student.interviewer.toLowerCase()
            : "";
          students.push(student);
        });
        dispatch(fetchStudentsSuccess(students));
      },
      (error) => dispatch(fetchStudentsError(error))
    );
  };
}

export function fetchStudent(id) {
  return async (dispatch) => {
    dispatch(fetchingStudent());

    try {
      const doc = await db.collection("students").doc(id).get();
      if (!doc.exists) {
        dispatch(fetchStudentError("Den studerende eksisterer ikke"));
        return;
      }

      const student = doc.data();
      student.id = doc.id;
      dispatch(fetchSafetyInformation(student.id));
      dispatch(fetchStudentSuccess(student));
      dispatch(fetchPagination(doc));
    } catch (e) {
      console.log(e);
      dispatch(fetchStudentError(e));
    }
  };
}

function fetchPagination(studentDoc) {
  return async (dispatch) => {
    dispatch(fetchingPagination());
    const student = studentDoc.data();
    let baseQuery = db
      .collection("students")
      .where("semester", "==", student.semester)
      .where("line", "==", student.line);
    // A student might not have a class assigned
    if (student.class) {
      baseQuery = baseQuery.where("class", "==", student.class);
    }

    //Get the previous and next student with these two queries below
    try {
      const pagination = {};
      const next = await baseQuery
        .orderBy("firstName")
        .orderBy("lastName")
        .startAfter(studentDoc)
        .limit(1)
        .get();
      pagination["next"] = getPageinationName(next.docs);

      const previous = await baseQuery
        .orderBy("firstName", "desc")
        .orderBy("lastName", "desc")
        .startAfter(student.firstName, student.lastName)
        .limit(1)
        .get();
      pagination["previous"] = getPageinationName(previous.docs);
      dispatch(fetchPaginationSuccess(pagination));
    } catch (e) {
      console.log("Could not fetch pagination");
      console.log(e);
      dispatch(fetchPaginationError(e));
    }
  };
}

const getPageinationName = (docs) => {
  if (docs.length > 0) {
    if (docs[0].exists) {
      const data = docs[0].data();
      return {
        [data.id]: `${data.firstName} ${data.lastName}`,
      };
    }
  }
};

export function saveSafetyInformation(id, si) {
  return async (dispatch) => {
    dispatch(savingSafetyInformation());
    try {
      await db
        .collection("students")
        .doc(id)
        .collection("additionalInformation")
        .doc("safetyInformation")
        .set(si, { merge: true });
      dispatch(saveSafetyInformationSuccess());
    } catch (e) {
      dispatch(saveSafetyInformationError(e));
    }
  };
}

export function saveStudent(student) {
  return async (dispatch) => {
    dispatch(savingStudent());
    try {
      student["updatedAt"] = FieldValue.serverTimestamp();

      // If the discharged checkbox is selected, set the line to discharged for queries
      if (student.discharged) {
        student["line"] = "discharged";
      }

      const docRef = await db
        .collection("students")
        .doc(student.id)
        .update(student);
      dispatch(saveStudentSuccess(docRef));
      dispatch(
        openSnackbar(
          `✅ ${student.firstName || "Den studerende"} blev opdateret`
        )
      );
    } catch (e) {
      dispatch(saveStudentError(e));
      dispatch(
        openSnackbar(`🛑 ${student.firstName} kunne ikke opdateres: ${e}`)
      );
    }
  };
}

export function deleteStudents(ids) {
  return async (dispatch) => {
    dispatch(deletingStudents());
    if (ids.length < 1) {
      dispatch(deleteStudentsError({ error: "No students found to delete" }));
      return;
    }
    const batch = db.batch();
    ids.map((id) => {
      const ref = db.collection("students").doc(id);
      return batch.delete(ref);
    });
    try {
      await batch.commit();
      dispatch(deleteStudentsSuccess());
    } catch (e) {
      dispatch(deleteStudentsError(e));
    }
  };
}

export function fetchClasses(semester) {
  return async (dispatch) => {
    dispatch(fetchingClasses());
    try {
      const doc = await db.collection("semesters").doc(semester).get();

      if (!doc.exists) return;
      dispatch(fetchClassesSuccess(doc.data().classes));
    } catch (e) {
      dispatch(fetchClassesError(e));
    }
  };
}

export function createAccountForStudent(student) {
  return async (dispatch) => {
    dispatch(creatingAccountForStudent());
    try {
      const { email, firstName, lastName, phone, id } = student;
      let { imageUrl } = student;
      if (!imageUrl) {
        imageUrl = DEFAULT_PROFILE_IMG_URL;
      }

      if (!id || !email) throw new Error("ID or EMAIL missing");
      const accountInfo = { email, firstName, lastName, phone, id, imageUrl };
      const action = {
        actionType: "createAccount",
        status: "pending",
        accountInfo,
      };
      const ref = await db.collection("actions").add(action);
      const unsubscribe = ref.onSnapshot((doc) => {
        if (!doc.exists) return;
        const { status } = doc.data();
        if (status === "success") {
          dispatch(openSnackbar("Konto oprettet for studerende"));
          dispatch(createAccountForStudentSuccess());
          unsubscribe();
        } else if (status === "error") {
          const errMsg = doc.data().error.errorInfo.message;
          dispatch(openSnackbar(errMsg));
          dispatch(createAccountForStudentError({ message: errMsg }));
          unsubscribe();
        }
      });
    } catch (e) {
      dispatch(createAccountForStudentError(e));
      console.log(e);
    }
  };
}

export function fetchGrades(studentId) {
  return async (dispatch) => {
    dispatch(fetchingStudentGrades());
    try {
      const gradeDocs = await db
        .collection("students")
        .doc(studentId)
        .collection("grades")
        .get();
      const grades = {};
      gradeDocs.forEach((doc) => {
        if (!doc.exists) return;
        grades[doc.id] = doc.data();
      });
      dispatch(fetchStudentGradesSuccess(grades));
    } catch (e) {
      dispatch(fetchStudentGradesError(e));
      dispatch(openSnackbar(`Kunne ikke hente karakterer: ${e}`));
    }
  };
}

export function createStudent(values) {
  return async (dispatch) => {
    dispatch({ type: CREATING_STUDENT });

    const doc = db.collection("students").doc(values.id);
    try {
      const student = { ...values, studentId: doc.id, origin: "intra" };

      await doc.set(student);
      dispatch({ type: CREATE_STUDENT_SUCCESS });
      dispatch(push(`/semesters/${student.semester}/student/${doc.id}`));
    } catch (e) {
      dispatch({ type: CREATE_STUDENT_ERROR, error: e });
      dispatch(openSnackbar(`🛑 Kunne ikke hente oprette studerende: ${e}`));
    }
  };
}

export function moveStudentToApplicants(student) {
  return async (dispatch) => {
    delete student["line"];
    delete student["semester"];
    const batch = db.batch();
    try {
      const fromRef = db.collection("students").doc(student.studentId);
      const toRef = db.collection("applicants").doc(student.studentId);

      student["name"] = `${student.firstName} ${student.lastName}`;
      student["id"] = student.id;
      batch.set(toRef, student);
      batch.delete(fromRef);
      await batch.commit();

      dispatch(push(`/applicants/${toRef.id}`));
      dispatch(openSnackbar(`✅ ${student.firstName} blev flyttet`));
    } catch (e) {
      console.log(student);
      dispatch(openSnackbar(`🛑 Kunne ikke flytte studerende: ${e}`));
    }
  };
}

export function setQueryForStudents(query) {
  return (dispatch) => {
    dispatch({ type: SET_QUERY_FOR_STUDENTS, query });
    dispatch(fetchStudents());
  };
}

export function setTabValue(value) {
  return (dispatch, getState) => {
    // When the tab value changes, set it globally so we can fetch it when fetching
    // the students
    dispatch({ type: SET_TAB_VALUE_STUDENTS, value });

    // If the lines changes we need to reset the class prop on the query object as it otherwise
    // wouldnt fetch anything
    const { query } = getState().students;
    delete query["class"];
    dispatch({ type: SET_QUERY_FOR_STUDENTS, query });

    // Start a refetch
    dispatch(fetchStudents());
  };
}

export function getClassImages() {
  return async (dispatch, getState) => {
    try {
      const requestRef = db.collection("imagesRequest").doc();
      // If no user id exists bail, we need this to notify the user of the download later on
      const { uid } = firebaseAuth().currentUser;
      if (!uid) throw new Error("Kunne ikke finde bruger id");

      const { query } = getState().students;
      const { semester, line } = query;
      const request = { semester, line, uid };
      if (query.class) {
        request.className = query.class;
      }

      await requestRef.set(request);
      dispatch(
        openSnackbar(
          `✅ Dit billede sæt bliver nu oprettet. Du vil få en notifikation når det er færdigt`
        )
      );
    } catch (e) {
      dispatch(openSnackbar(`🛑 Kunne ikke oprette billedesæt: ${e}`));
    }
  };
}

export function setProperty(prop, ids) {
  return async (dispatch, getState) => {
    dispatch({ type: SETTING_STUDENT_PROPERTY });
    const { students } = getState().students;
    let arr = students.filter((s) => ids.includes(s.id));
    try {
      if (_.isEmpty(prop)) {
        throw new Error(`Værdi: ${prop} er inkorrekt`);
      }
      //eslint-disable-next-line no-unused-vars
      for (let student of arr) {
        const ref = db.collection("students").doc(student.id);

        await ref.update({ ...prop });
        dispatch({ type: SETTING_PROPERTY_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: SETTING_PROPERTY_ERROR, error });
    }
  };
}

export function toggleGradingDialog() {
  return async (dispatch, getState) => {
    const { gradingDialogOpen } = getState().students;
    dispatch({
      type: TOGGLE_GRADING_DIALOG,
      gradingDialogOpen: !gradingDialogOpen,
    });
  };
}
