import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import NotificationIcon from "@mui/icons-material/Notifications";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Search from "@mui/icons-material/Search";
import { drawerWidth } from "../../constants";
import { login, logout } from "../../helpers/auth";
import { useSelector } from "react-redux";
import { SearchContainer } from "../../containers";
import grey from "@mui/material/colors/grey";
import Settings from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";
import NavButtons from "./NavButtons";
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AppState } from "../../reducers";

const PREFIX = "NarBar";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  typography: `${PREFIX}-typography`,
  displayName: `${PREFIX}-displayName`,
  badge: `${PREFIX}-badge`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: "absolute",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    background: "white",
  },
  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.typography}`]: { color: grey[500] },
  [`& .${classes.displayName}`]: {
    flex: 1,
    color: grey[500],
    fontWeight: 600,
    fontSize: "1.3rem",
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.badge}`]: {
    margin: `${theme.spacing(2)}px`,
  },
  [`& .${classes.button}`]: {
    height: 60, // setting height/width is optional
    color: grey[500],
  },
}));

const NavBar = ({
  open,
  handleToggleDrawer,
  toggleNotifications,
}: {
  open: boolean;
  handleToggleDrawer: () => void;
  toggleNotifications: () => void;
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const user = useSelector((state: any) => state.auth.user || {});
  const history = useHistory();
  const { uid, displayName } = user;

  const notifications = useSelector(
    (state: AppState) => state.firestore.ordered[`${uid}-notifications`] || []
  );
  const matches = useMediaQuery("(min-width:1280px)");
  const notificationCount = notifications.filter((n) => !n.read).length;
  return (
    <Root>
      <div className="no-print">
        <SearchContainer open={searchOpen} setOpen={setSearchOpen} />

        <AppBar
          className={classNames(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar disableGutters={!open}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="text"
                  endIcon={<Settings />}
                  className={classes.displayName}
                  onClick={() => history.push("/settings")}
                >
                  {user.uid && matches ? displayName : ""}
                </Button>
              </Grid>
              <Grid item>
                <NavButtons />
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={() => setSearchOpen(true)}
                  endIcon={<Search />}
                >
                  Søg
                </Button>
              </Grid>
              <Grid item>
                {user.uid ? (
                  <Button
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    onClick={() => {
                      login();
                    }}
                  >
                    Login
                  </Button>
                )}
                <IconButton
                  color="inherit"
                  onClick={() => toggleNotifications()}
                >
                  {notificationCount > 0 ? (
                    <Badge
                      className={classes.badge}
                      color="primary"
                      badgeContent={notificationCount}
                    >
                      <NotificationIcon style={{ color: grey[500] }} />
                    </Badge>
                  ) : (
                    <NotificationIcon style={{ color: grey[500] }} />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </Root>
  );
};

NavBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
};

export default NavBar;
