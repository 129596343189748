import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import {
  Index,
  Hits,
  InstantSearch,
  Highlight,
  connectSearchBox,
} from "react-instantsearch-dom";
import { algoliasearch } from "algoliasearch";
import Typography from "@mui/material/Typography";
import Box from "../../BaseComponents/Box";
import { AppSearch } from "../../components";
import { Link } from "react-router-dom";
import "./search.css";

const PREFIX = "SearchContainer";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  result: `${PREFIX}-result`,
  a: `${PREFIX}-a`,
  headline: `${PREFIX}-headline`,
  column: `${PREFIX}-column`,
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.root}`]: {
    marginTop: 24,
    minHeight: 600,
  },
  [`& .${classes.paper}`]: {
    padding: 16,
    minHeight: 400,
  },
  [`& .${classes.result}`]: {
    marginTop: 8,
  },
  [`& .${classes.a}`]: {
    textDecoration: "none",
    color: "inherit",
  },
  [`& .${classes.headline}`]: {
    color: "#fc4f4f",
  },
  [`& .${classes.column}`]: {
    display: "flex",
    flexDirection: "column",
  },
});

// Algolia search
const searchClient = algoliasearch(
  "9QYZAZ7D0L",
  "a4e74a7a956dba71873600a68a6cd0a6"
);

const CustomSearchBox = connectSearchBox(AppSearch);

const StudentHit = ({ hit, setOpen }) => {
  return (
    <Link
      to={`/semesters/${hit.semester}/student/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
        <Typography variant="overline">
          <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
          <Highlight hit={hit} attribute="lastName" className="hit-name" />{" "}
        </Typography>
        <Typography variant="caption" className="hit-email">
          <Highlight hit={hit} attribute="email" />{" "}
          <Highlight hit={hit} attribute="phone" />
        </Typography>
      </div>
    </Link>
  );
};

const ApplicantHit = ({ hit, setOpen }) => {
  return (
    <Link
      to={`/applicants/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
        <Typography variant="overline">
          <Highlight hit={hit} attribute="name" className="hit-name" />{" "}
        </Typography>
        <Typography variant="caption" className="hit-email">
          <Highlight hit={hit} attribute="email" />{" "}
          <Highlight hit={hit} attribute="phone" />
        </Typography>
      </div>
    </Link>
  );
};

const ContactsHit = ({ hit, setOpen }) => {
  return (
    <Link
      to={`/contacts/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
        <Typography variant="overline">
          <Highlight hit={hit} attribute="name" className="hit-name" />{" "}
        </Typography>
        <Typography variant="caption" className="hit-email">
          <Highlight hit={hit} attribute="email" />{" "}
          <Highlight hit={hit} attribute="phone" />
        </Typography>
      </div>
    </Link>
  );
};

const ExaminersHit = ({ hit, setOpen }) => {
  return (
    <Link
      to={`/examiners/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
        <Typography variant="overline">
          <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
          <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
        </Typography>
        <Typography variant="caption" className="hit-email">
          <Highlight hit={hit} attribute="email" />{" "}
          <Highlight hit={hit} attribute="phone" />
        </Typography>
      </div>
    </Link>
  );
};

const SearchContainer = ({ open, setOpen }) => {
  return (
    <StyledDialog fullScreen={true} open={open}>
      <DialogContent>
        <InstantSearch indexName="applicants" searchClient={searchClient}>
          <CustomSearchBox setOpen={setOpen} />
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Box p={1} width={1 / 4}>
              <Index indexName="applicants">
                <Typography variant="h5">Ansøgere</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ApplicantHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="students">
                <Typography variant="h5">Studerende</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <StudentHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="contacts">
                <Typography variant="h5">Kontakter</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ContactsHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="examiners">
                <Typography variant="h5">Censorer</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ExaminersHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
          </Box>
        </InstantSearch>
      </DialogContent>
    </StyledDialog>
  );
};

export default SearchContainer;
