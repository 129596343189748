import { login, logout } from "../helpers/auth";
import { firebaseAuth } from "../helpers/firebaseHelper";
import { openSnackbar } from "./snackbar";
import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  UN_AUTH_SUCCESS,
  AUTHENICATING,
  VERIFYING_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
} from "../constants";

export function authenticating() {
  return {
    type: AUTHENICATING,
  };
}
export function authFailed(message) {
  return {
    type: AUTH_FAILURE,
    error: message,
  };
}

export function authSuccess(user) {
  return {
    type: AUTH_SUCCESS,
    user: user,
  };
}

export function unAuthSuccess() {
  return {
    type: UN_AUTH_SUCCESS,
  };
}

function verifyingEmail() {
  return {
    type: VERIFYING_EMAIL,
  };
}

function verifyEmailSuccess() {
  return {
    type: VERIFY_EMAIL_SUCCESS,
  };
}

function verifyEmailError() {
  return {
    type: VERIFY_EMAIL_ERROR,
  };
}

export function authUser() {
  return (dispatch) => {
    dispatch(authenticating());
    login();
    firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        return dispatch(authSuccess(user));
      } else {
        return dispatch(authFailed("Could not sign in"));
      }
    });
  };
}

export function unAuth() {
  return (dispatch) => {
    logout().then(() => {
      dispatch(unAuthSuccess());
    });
  };
}

export function verifyEmail(email) {
  return async (dispatch) => {
    dispatch(verifyingEmail());
    if (!isValidEmail) {
      dispatch(verifyEmailError());
      dispatch(openSnackbar("Fejl: Ikke en email"));
      return;
    }
    try {
      const user = firebaseAuth().currentUser;
      await user.updateEmail(email);
      await user.sendEmailVerification(null);
      dispatch(verifyEmailSuccess());
    } catch (e) {
      console.log(e);
      if (e.code === "auth/requires-recent-login") {
        await login();
      }
      dispatch(verifyEmailError());
      dispatch(openSnackbar("Kunne ikke verificere email"));
    }
  };
}

function isValidEmail(email) {
  const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return emailRegex.test(email);
}
