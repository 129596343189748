import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PREFIX = 'ExamClassSelect';

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  select: `${PREFIX}-select`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120
  },

  [`& .${classes.select}`]: {
    width: 200
  }
}));

const ExamClassSelect = ({
  classes,
  classNames,
  selectedClass,
  handleClassChange,
  isFetching
}) => (
  <Root className={classes.root} autoComplete="off">
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="examClassSelect">Hold</InputLabel>
      <Select
        className={classes.select}
        value={selectedClass}
        onChange={handleClassChange}
        disabled={isFetching}
        inputProps={{
          name: 'examClass',
          id: 'examClassSelect'
        }}>
        {Object.keys(classNames).map(key => {
          const className = classNames[key];
          return (
            <MenuItem key={key} value={key}>
              {className}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </Root>
);

ExamClassSelect.propTypes = {
  classNames: PropTypes.object.isRequired,
  selectedClass: PropTypes.string.isRequired,
  handleClassChange: PropTypes.func.isRequired
};

export default (ExamClassSelect);
