import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import FontAwesome from "react-fontawesome";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Message from "@mui/icons-material/Message";
const PREFIX = 'PersonsTableToolbar';

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  button: `${PREFIX}-button`,
  spacer: `${PREFIX}-spacer`,
  actions: `${PREFIX}-actions`,
  title: `${PREFIX}-title`
};

const StyledToolbar = styled(Toolbar)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    paddingRight: 2
  },

  [`&.${classes.highlight}`]: theme.palette.mode === "light"
    ? {
        color: theme.palette.primary[100],
        backgroundColor: theme.palette.primary[100]
      }
    : {
        color: theme.palette.primary[100],
        backgroundColor: theme.palette.primary[100]
      },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.spacer}`]: {
    flex: "1 1 100%"
  },

  [`& .${classes.actions}`]: {
    color: theme.palette.text.primary,
    width: "30%"
  },

  [`& .${classes.title}`]: {
    flex: "0 0 auto"
  }
}));

let PersonsTableToolbar = props => {
  const {
    numSelected,

    count,
    handleDeleteItems,
    handleSendMessages,
    handleCreateSheet,
    canonicalName
  } = props;

  return (
    <StyledToolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography type="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography type="headline">
            {count} {canonicalName}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div>
            <IconButton
              className={classes.button}
              aria-label="Slet"
              onClick={() => handleDeleteItems()}
              size="large">
              <DeleteIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Send besked"
              onClick={() => handleSendMessages()}
              size="large">
              <Message />
            </IconButton>
            {handleCreateSheet && (
              <IconButton
                className={classes.button}
                aria-label="Create spreadsheet"
                onClick={() => handleCreateSheet()}
                size="large">
                <FontAwesome name="table" />
              </IconButton>
            )}
          </div>
        ) : null}
      </div>
    </StyledToolbar>
  );
};

PersonsTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  canonicalName: PropTypes.string.isRequired,
  handleSendMessages: PropTypes.func.isRequired
};

PersonsTableToolbar = (PersonsTableToolbar);
export default PersonsTableToolbar;
