import React from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = 'UsersComponent';

const classes = {
  avatar: `${PREFIX}-avatar`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.avatar}`]: {
    margin: 10,
  },

  [`& .${classes.root}`]: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.table}`]: {
    minWidth: 700,
  }
}));

type Props = {
  users: {
    providerData: any[];
    uid: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    avatarUrl: string;
  }[];
};

const UsersComponent: React.FC<Props> = ({ users }) => {

  const history = useHistory();
  return (
    <Root>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Billede</TableCell>
                  <TableCell>Navn</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Email bekræftet</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length !== 0 ? (
                  users.map((user) => {
                    const uid = user.uid;
                    const providerData = user.providerData;
                    const providerDisplayName = providerData
                      ? providerData[0].displayName
                      : "";
                    const providerEmail = providerData
                      ? providerData[0].email
                      : "";
                    return (
                      <TableRow
                        key={uid}
                        hover
                        onClick={() => history.push(`/users/${uid}`)}
                      >
                        <TableCell>
                          <Avatar
                            alt={user.displayName}
                            src={user.avatarUrl}
                            className={classes.avatar}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.displayName
                            ? user.displayName
                            : providerDisplayName}
                        </TableCell>
                        <TableCell>
                          {user.email ? user.email : providerEmail}
                        </TableCell>
                        <TableCell>
                          {user.emailVerified ? "✅" : "🛑"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <CircularProgress />
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default UsersComponent;
