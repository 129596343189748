import React from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field } from "formik";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { FormLabel, Grid2 } from "@mui/material";
import Button from "@mui/material/Button";
import { referrers } from "../../helpers/referrers";
import { parseDate } from "../../helpers/parser";
import {
  DischargedSelect,
  DateField,
  DateTimeField,
  SelectField,
  GenderField,
  CheckboxGroupField,
  RadioField,
  CheckboxField,
  TextField,
} from "../../components";

import ApplicantsActionMenu from "./ApplicantsActionMenu";
import { interviewers } from "../../helpers/interviewers";
import { lines } from "../../helpers/lines";
import { IApplicant } from "../Applicant";

const PREFIX = "Form";

const classes = {
  textFieldContainer: `${PREFIX}-textFieldContainer`,
  textfield: `${PREFIX}-textfield`,
  textFieldSmall: `${PREFIX}-textFieldSmall`,
  textArea: `${PREFIX}-textArea`,
  topButton: `${PREFIX}-topButton`,
  typography: `${PREFIX}-typography`,
  progress: `${PREFIX}-progress`,
  radio: `${PREFIX}-radio`,
  button: `${PREFIX}-button`,
  dates: `${PREFIX}-dates`,
  select: `${PREFIX}-select`,
  leftIcon: `${PREFIX}-leftIcon`,
  dischargeSelect: `${PREFIX}-dischargeSelect`,
  label: `${PREFIX}-label`,
};

const StyledFormik = styled(Formik)(({ theme }) => ({
  [`& .${classes.textFieldContainer}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.textfield}`]: {},

  [`& .${classes.textFieldSmall}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
  },

  [`& .${classes.textArea}`]: {
    padding: 10,
    backgroundColor: "rgba(128, 128, 128, 0.1)",
  },

  [`& .${classes.topButton}`]: {
    padding: "8px 16px 8px 0",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.typography}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.progress}`]: {
    margin: `0 ${theme.spacing(2)}`,
  },

  [`& .${classes.radio}`]: {
    display: "flex",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.dates}`]: {
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.select}`]: {
    margin: theme.spacing(1),
    minWidth: 150,
  },

  [`& .${classes.leftIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.dischargeSelect}`]: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 600,
  },

  [`& .${classes.label}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().label("Navn").min(2).required(),
  email: Yup.string().email().required(),
  line: Yup.string().label("Linie").oneOf(lines),
  gender: Yup.string().label("Køn").oneOf(["male", "female"]).required(),
});

type FormProps = {
  applicant: IApplicant;
  doSubmit: (updated: any) => Promise<void>;
  moveApplicant: () => void;
  sendReminder: (type: string) => void;
  handleDeleteApplicant: () => void;
  semesters: { title: string }[];
};
const Form: React.FC<FormProps> = ({
  applicant,
  doSubmit,
  semesters,
  moveApplicant,
  sendReminder,
  handleDeleteApplicant,
  applicant: { sms, id, textMessageTemplate },
}) => {
  const history = useHistory();

  const onDeleteApplicant = () => {
    handleDeleteApplicant();
  };

  return (
    <StyledFormik
      initialValues={applicant}
      onSubmit={async (values) => {
        await doSubmit(values);
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        values: {
          isArchived,
          line,
          semester,
          contractSent,
          contractReceived,
          interviewScheduled,
        },
        touched,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        errors,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid2 container spacing={4}>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <ApplicantsActionMenu
                handleSendMessage={() => sendReminder("applicant")}
                handleViewMessages={() =>
                  history.push(`/messages/applicants/${id}`)
                }
                handleMoveToStudents={() => moveApplicant()}
                moveDisabled={line === "" || semester === ""}
                deleteApplicant={() => onDeleteApplicant()}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 9,
              }}
            >
              <DateField name="dateSent" label={"Ansøger indsendt d."} />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="name"
                label="Navn*"
                type="text"
                component={TextField}
                fullWidth
              />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="email"
                label="Email*"
                component={TextField}
                placeholder={"Email"}
                type="text"
                fullWidth
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="phone"
                label="Telefon"
                component={TextField}
                placeholder={"Telefon"}
                type="text"
                fullWidth
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <DateField name="birthday" label="Fødselsdag" />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="street"
                label="Gade"
                component={TextField}
                placeholder={"Gade"}
                type="text"
                fullWidth
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="postcode"
                component={TextField}
                label="Postnr."
                placeholder={"Postnummer"}
                type="text"
                fullWidth
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <Field
                name="city"
                label="By"
                component={TextField}
                placeholder={"By"}
                type="text"
                fullWidth
              />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <GenderField name="gender" label="Køn" />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Field
                name="education"
                label="Uddannelse"
                component={TextField}
                placeholder={"Uddannelse"}
                type="text"
                className={classes.textArea}
                multiline
                fullWidth
                rows={6}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Field
                name="motivation"
                label="Motivation"
                component={TextField}
                type="text"
                className={classes.textArea}
                multiline
                fullWidth
                rows={6}
              />
            </Grid2>

            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              {sms && (
                <Typography variant="caption">
                  SMS:{" "}
                  {sms.status === 400
                    ? `🛑 ${sms.message}`
                    : `✅ afsendt d. ${parseDate(sms.dateCreated)}`}
                </Typography>
              )}
              <Field
                name="textMessageTemplate"
                component={TextField}
                className={classes.textArea}
                label={`SMS besked (${
                  textMessageTemplate ? 160 - textMessageTemplate.length : 160
                })`}
                type="text"
                multiline
                fullWidth
                rows={6}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <FormLabel component="legend">Valgte linier</FormLabel>
              <CheckboxField name="sport" label="Sports" />
              <CheckboxField name="outdoor" label="Friluft" />
              <CheckboxField name="xOutdoor" label="X-Outdoor" />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <CheckboxGroupField
                name="startingYear"
                label="Valgte startdatoer:"
                row
                options={["2023", "2024", "2025", "2026", "2027"].map(
                  (year) => ({ label: year, value: year })
                )}
              />
            </Grid2>
            <Grid2 size={12}>
              <RadioField
                row
                name="line"
                label="Tildel linie"
                options={[
                  { value: "sport", label: "Sports" },
                  { value: "x-outdoor", label: "X-Outdoor" },
                  { value: "outdoor", label: "Friluft" },
                ]}
              />
            </Grid2>
            <Grid2
              container
              direction="column"
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <CheckboxField name="interviewScheduled" label="Samtale aftalt" />

              <CheckboxField
                disabled={!interviewScheduled}
                name="noShow"
                label="Mødte ikke op"
              />
              <DateTimeField
                disabled={!interviewScheduled}
                name="interviewDate"
                label={"Dato for samtale"}
                showDeleteIcon
              />
            </Grid2>
            <Grid2
              container
              direction={"column"}
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <Grid2>
                <CheckboxField name="contractSent" label="Kontrakt afsendt" />

                <DateField
                  name="contractSentDate"
                  disabled={!contractSent}
                  label={"Dato for afsendelse"}
                  showDeleteIcon
                />
              </Grid2>
            </Grid2>
            <Grid2
              container
              direction="column"
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <CheckboxField
                name="contractReceived"
                label="Kontrakt modtaget"
              />

              <DateField
                disabled={!contractReceived}
                name="contractReceivedTimestamp"
                label={"Dato for modtagelse"}
                showDeleteIcon
              />
            </Grid2>
            <Grid2 size={12}>
              <CheckboxField
                name="admissionFeeReceived"
                label="Indmeldelsesgebyr Modtaget"
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <RadioField
                row
                name="interviewer"
                label="Samtale med"
                options={interviewers.map((interviewer) => ({
                  value: interviewer,
                  label: _.upperCase(interviewer),
                }))}
              />
            </Grid2>
            <Grid2
              container
              direction="column"
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <DateField
                name="firstContactedTimestamp"
                label={"1. Opkald"}
                showDeleteIcon
              />
              <DateField
                name="secondContactedTimestamp"
                label={"2. Opkald"}
                showDeleteIcon
              />
              <DateField
                name="thirdContactedTimestamp"
                label={"3. Opkald"}
                showDeleteIcon
              />
            </Grid2>

            <Grid2
              container
              direction="column"
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <SelectField
                name="semester"
                label="Vælg årgang"
                // Only show the latest five semesters
                options={semesters
                  .slice(Math.max(semesters.length - 5, 0))
                  .map((semester) => ({
                    label: semester.title,
                    value: semester.title,
                  }))}
              />
              <SelectField
                name="referrer"
                label="Hørte om os"
                options={referrers.map((r) => ({ value: r, label: r }))}
              />
              <SelectField
                name="origin"
                label="Ansøgning via"
                options={[
                  { label: "Hjemmeside", value: "hjemmeside" },
                  { label: "Indsendt via email", value: "email" },
                  { label: "Telefon", value: "telephone" },
                  { label: "Info aften", value: "info-aften" },
                ]}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Field
                name="note"
                label="Note"
                component={TextField}
                placeholder={"Note"}
                type="text"
                className={classes.textArea}
                multiline
                fullWidth
                rows={6}
              />
            </Grid2>
            <Grid2 container alignItems="center" size={12}>
              <CheckboxField name="isArchived" label="Arkiveret" />
              <DischargedSelect
                type="applicant"
                classes={classes}
                disabled={!isArchived}
                setFieldValue={setFieldValue}
              />
            </Grid2>
            <Grid2 size={12}>
              {Object.keys(errors).length > 0 &&
                Object.values(errors).map((error, i) => (
                  <pre key={`${error}-${i}`} style={{ color: "red" }}>
                    {error}
                  </pre>
                ))}
            </Grid2>
            <Grid2 size={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={isSubmitting}
              >
                Opdater
              </Button>

              <Button
                className={classes.button}
                disabled={!touched}
                onClick={() => resetForm()}
              >
                Fortryd
              </Button>
            </Grid2>
          </Grid2>
        </form>
      )}
    </StyledFormik>
  );
};

export default Form;
