import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Add from "@mui/icons-material/Add";
import Collections from "@mui/icons-material/Collections";
const PREFIX = 'StudentsActionsList';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper
  }
}));

const StudentsActionsList = ({

  handleCreateNewStudent,
  handleGetClassImages
}) => {
  return (
    <Root className={classes.root}>
      <List>
        <ListItem dense button onClick={handleCreateNewStudent}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Opret ny studerende" />
        </ListItem>
        <ListItem button onClick={handleGetClassImages}>
          <ListItemIcon>
            <Collections />
          </ListItemIcon>
          <ListItemText primary="Billedesæt og studiekortbilleder" />
        </ListItem>
      </List>
      <Divider />
    </Root>
  );
};

StudentsActionsList.propTypes = {
  handleCreateNewStudent: PropTypes.func.isRequired,
  handleGetClassImages: PropTypes.func.isRequired
};

export default (StudentsActionsList);
