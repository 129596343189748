import React from "react";
import { styled } from "@mui/material/styles";
import { Field, FieldArray, Formik, FormikValues, FormikHelpers } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import { CheckboxField, TextField } from "../../components";
import { DropzoneFileList } from "../../containers";

import { DropzoneFileTypes } from "../../containers/DropzoneFileList/DropzoneTypes";
import { DateField } from "../../components";
import SubjectsTransferList from "./SubjectsTransferList";

import { subjectsList } from "../../helpers/subjectsList";
import ListItemIcon from "@mui/material/ListItemIcon";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import green from "@mui/material/colors/green";

const PREFIX = "ExaminerForm";

const classes = {
  button: `${PREFIX}-button`,
  list: `${PREFIX}-list`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  checkmark: `${PREFIX}-checkmark`,
  approvedSubjectsList: `${PREFIX}-approvedSubjectsList`,
};

const StyledFormik = styled(Formik)(({ theme }) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.list}`]: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 400,
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.checkmark}`]: { color: green[500] },

  [`& .${classes.approvedSubjectsList}`]: {},
}));

type FormProps = {
  examiner: Examiner;
  semesters: { title: string }[];
  handleSubmit: (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => void;
};

const ExaminerForm: React.FC<FormProps> = ({
  examiner,
  semesters,
  handleSubmit,
}) => {
  return (
    <StyledFormik initialValues={examiner} onSubmit={handleSubmit}>
      {({
        values: { id, examinations, approvedSubjects },
        handleSubmit,
        resetForm,
        touched,
        isSubmitting,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Field
                name="firstName"
                label="Fornavn*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="lastName"
                label="Efternavn*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="phone"
                label="Telefon*"
                type="tel"
                style={{ marginBottom: "8px" }}
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="email"
                label="email*"
                type="email"
                style={{ marginBottom: "8px" }}
                component={TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={3}>
                <CheckboxField
                  name="paymentInformationForwarded"
                  label="Løninformation videresendt"
                />
              </Grid>
              <Grid item xs={3}>
                <CheckboxField
                  name="examinerReport"
                  label="Censor Indeberetning"
                />
              </Grid>
              <Grid item xs={3}>
                <DateField name="lastUsed" label="Sidst anvendt" />
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <CheckboxField name="approved" label="Godkendt" />
              <DateField name="approvedUntil" label={"Godkendt til"} />
              <FieldArray
                name="approvedSubjects"
                render={({ remove, push }) => (
                  <List
                    sx={{
                      width: 300,
                      maxHeight: 350,
                      overflowY: "scroll",
                      height: 300,
                    }}
                    aria-label="approved subjects"
                    subheader={
                      <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        style={{ backgroundColor: "white" }}
                      >
                        Godkendt I
                      </ListSubheader>
                    }
                  >
                    {subjectsList.sort().map((subject) => {
                      const isAssigned = approvedSubjects
                        ? approvedSubjects.includes(subject)
                        : false;
                      const idx = approvedSubjects
                        ? approvedSubjects.indexOf(subject)
                        : -1;
                      return (
                        <ListItemButton
                          key={subject}
                          onClick={() =>
                            isAssigned ? remove(idx) : push(subject)
                          }
                        >
                          <ListItemIcon>
                            {isAssigned ? (
                              <CheckCircleOutlineIcon
                                className={classes.checkmark}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={subject} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12}>
                <Field
                  name="note"
                  label="Note"
                  type="text"
                  style={{ marginBottom: "8px" }}
                  component={TextField}
                  fullWidth
                  multiline
                  rows={6}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12}>
                <DropzoneFileList
                  multiple
                  heading="Filer"
                  type={DropzoneFileTypes.Files}
                  collection="examiners"
                  id={id}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} md={6} container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Legacy - bliver snart fjernet
                </Typography>
                <List subheader={<ListSubheader />} className={classes.list}>
                  <ListSubheader>{`Har været censor i:`}</ListSubheader>
                  {examinations &&
                    examinations.map((examination: any) => (
                      <ListItem
                        key={`${examination.line}${examination.subject}${examination.semester}`}
                      >
                        <ListItemText
                          primary={`${examination.subject}`}
                          secondary={`${parseLine(examination.line)} ${
                            examination.semester
                          }`}
                        />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid> */}
            <Grid item xs={12} container>
              {/* <ExaminationsComponent
                  examinerID={id}
                  semesters={semesters}
                  examinations={examinations || []}
                /> */}
              <SubjectsTransferList examinerID={id} semesters={semesters} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={isSubmitting || !dirty}
              >
                Opdater
              </Button>

              <Button
                className={classes.button}
                disabled={!touched}
                onClick={() => resetForm()}
              >
                Fortryd
              </Button>
              <CheckboxField
                name="isArchived"
                label="Arkiveret"
                style={{ marginTop: 16 }}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </StyledFormik>
  );
};

export default ExaminerForm;
