import React from "react";
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { parseDate } from "../../helpers/parser";
import { AppState } from "../../reducers";
const PREFIX = 'MessageHistoryContainer';

const classes = {
  card: `${PREFIX}-card`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`
};

const StyledGrid = styled(Grid)({
  [`& .${classes.card}`]: {
    minWidth: 275,
    marginBottom: 16,
  },
  [`& .${classes.bullet}`]: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  [`& .${classes.title}`]: {
    marginBottom: 16,
    fontSize: 14,
  },
  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },
});

const MessageHistoryContainer: React.FC = () => {

  const history = useHistory();

  const { collection, id }: { collection: string; id: string } = useParams();

  const messages = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.messages || []
  );

  useFirestoreConnect({
    collection: collection,
    doc: id,
    subcollections: [{ collection: "messages" }],
    storeAs: "messages",
    orderBy: ["sent", "desc"],
  });

  return (
    <StyledGrid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Button color="primary" onClick={() => history.goBack()}>
          Tilbage
        </Button>
      </Grid>
      {messages.map((message: Message, idx: number) => {
        return (
          <Grid key={idx} item xs={12} lg={8}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  {parseDate(message.sent, "LLL")}
                </Typography>
                <Typography variant="subtitle1">
                  Sendt til: {message.name}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {message.isForEmailDelivery ? message.email : null} •{" "}
                  {message.isForSMSDelivery ? message.phone : null}
                </Typography>
                <Typography component="p">{message.message}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default MessageHistoryContainer;
