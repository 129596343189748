import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

const studentProps: { [key: string]: string } = {
  contractSent: "Kontrakt sendt",
  contractReceived: "Kontrakt modtaget",
  admissionFeeReceived: "Indmeldelsesgebyr modtaget",
  confirmationSent: "Bekræftelse om optagelse sendt",
  infoLetter: "Informationsbrev sendt",
  introductionEmail: "Velkomst email sendt",
  lectio: "Oprettet i Lectio",
  createdInSU: "Oprettet i SU",
  studentCard: "Studiekort",
  consentSigned: "Samtykkeerklæring underskrevet",
  rulesSigned: "Regelsæt underskrevet",
  workExperienceContract: "Praktik kontrakt",
  workExperienceContract2: "Praktik kontrakt 2",
  createdInEconomics: "Oprettet i Economics",
};

type Props = { handleSetProp: (value: string) => void };
const StudentsPropMenu: React.FC<Props> = ({ handleSetProp }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setProp = (prop: string) => {
    if (
      window.confirm(
        `Er du sikker på du vil sætte '${studentProps[prop]}' på de valgte studerende?`
      )
    ) {
      handleSetProp(prop);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<AssignmentTurnedInIcon />}
      >
        Handlinger
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(studentProps).map((prop) => (
          <MenuItem key={prop} onClick={() => setProp(prop)}>
            {studentProps[prop]}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StudentsPropMenu;
