import React from "react";
import { Formik, Field } from "formik";
import { IEmployee } from "../Employee";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import { DropzoneFileList } from "../../containers";
import { CheckboxField, DisplayImage, TextField } from "../../components";
import EquipmentForm from "./EquipmentForm";
import { keyOptions } from "../../helpers/keyOptions";
import { DropzoneFileTypes } from "../../containers/DropzoneFileList/DropzoneTypes";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().label("Fornavn").min(2).required(),
  lastName: Yup.string().label("Efternavn").min(2).required(),
  email: Yup.string().email().required(),
  phone: Yup.string(),
});

type FormProps = {
  employee: IEmployee;
  doSubmit: (updated: IEmployee) => Promise<void>;
};

const Form: React.FC<FormProps> = ({ employee, doSubmit }) => {
  return (
    <Formik
      initialValues={employee}
      onSubmit={async (values) => {
        await doSubmit(values);
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        values: { id, imageUrl },
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid container item justifyContent="center">
              <DisplayImage
                collection="employees"
                itemID={id}
                imageURL={imageUrl}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Field
                name="title"
                label="Titel*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item md={3} />
            <Grid item xs={12} md={3}>
              <Field
                name="firstName"
                label="Fornavn*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="lastName"
                label="Efternavn*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="email"
                label="Email*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="phone"
                label="Telefon*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="address"
                label="Adresse"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="city"
                label="By"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="postcode"
                label="Postnummer*"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel component="legend">Tilknyttet</FormLabel>
              <CheckboxField name="associatedLines.sports" label="Sport" />
              <CheckboxField name="associatedLines.outdoor" label="Friluft" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container item style={{ marginTop: 8 }}>
              <EquipmentForm />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} container direction="column">
              <FormLabel component="legend">Nøgler</FormLabel>
              {Object.keys(keyOptions).map((option: string) => (
                <CheckboxField
                  key={option}
                  name={`keys.${option}`}
                  label={`${keyOptions[option]}`}
                />
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <DropzoneFileList
                collection="employees"
                id={id}
                multiple
                type={DropzoneFileTypes.Files}
                heading="Filer"
              />
            </Grid>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <Field
                name="note"
                label="Note"
                type="text"
                style={{ marginBottom: "8px" }}
                component={TextField}
                margin="normal"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                Opdater
              </Button>
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={() => resetForm()}
                style={{ marginLeft: 8 }}
              >
                Fortryd
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default Form;
