import _ from "lodash";
import { add, isBefore } from "date-fns";
import { parseLine } from "./parser";
import { interviewers as currentInterviewers } from "./interviewers";

type LineDataReturn = {
  sport: number;
  sportFemale: number;
  sportMale: number;
  sportChange: number;
  outdoor: number;
  outdoorFemale: number;
  outdoorMale: number;
  outdoorChange: number;
  ["x-outdoor"]: number;
  ["x-outdoor_female"]: number;
  ["x-outdoor_male"]: number;
  ["x-outdoor_change"]: number;
  discharged: number;
  dischargedFemale: number;
  dischargedMale: number;
  total: number;
  totalFemale: number;
  totalMale: number;
};

const lineChartData = (data: ChartEntry[]): LineDataReturn[] => {
  const accumulatedEntries: any[] = [];
  const nextMonth = add(new Date(), { months: 1 });

  data
    .filter((entry) => isBefore(entry.date.toDate(), nextMonth))
    .reduce((acc: any, data: any) => {
      const sport = (acc["sport"] || 0) + data.sport;
      const sportFemale = (acc.sport_female || 0) + data.sport_female;
      const sportMale = (acc.sport_male || 0) + data.sport_male;
      const outdoor = (acc.outdoor || 0) + data.outdoor;
      const outdoorFemale = (acc.outdoor_female || 0) + data.outdoor_female;
      const outdoorMale = (acc.outdoor_male || 0) + data.outdoor_male;
      const xOutdoor = (acc["x-outdoor"] || 0) + data["x-outdoor"];
      const xOutdoorFemale =
        (acc["x-outdoor_female"] || 0) + data["x-outdoor_female"];
      const xOutdoorMale =
        (acc["x-outdoor_male"] || 0) + data["x-outdoor_male"];
      (acc["x-outdoor_change"] || 0) + data["x-outdoor_change"];

      const discharged = (acc.discharged || 0) + data.discharged;
      const dischargedFemale = data.discharged_female;
      const dischargedMale = data.discharged_male;

      const total = (acc.total || 0) + data.total;
      const totalFemale = sportFemale + outdoorFemale + xOutdoorFemale;
      const totalMale = sportMale + outdoorMale + xOutdoorMale;

      const obj = {
        ...acc,
        name: data.name,
        sport,
        sportFemale,
        sportMale,
        outdoor,
        outdoorFemale,
        outdoorMale,
        ["x-outdoor"]: xOutdoor,
        ["x-outdoor_female"]: xOutdoorFemale,
        ["x-outdoor_male"]: xOutdoorMale,
        discharged,
        dischargedFemale,
        dischargedMale,
        total,
        totalFemale,
        totalMale,
      };
      accumulatedEntries.push(obj);
      return obj;
    }, 0);

  return accumulatedEntries;
};

const interviewersPieChartData = (data: Statistic[]): any => {
  // Filter out any interviewers that aren't here anymore
  const interviewers = data
    .map((d) => d.interviewer)
    .filter((i) => currentInterviewers.includes(i));

  const initialData: { [key: string]: number } = { andre: 0 };
  interviewers.map((i) => {
    initialData[i] = 0;
  });

  const totals = data.reduce((acc: any, currentValue: Statistic) => {
    if (currentInterviewers.includes(currentValue.interviewer)) {
      acc[currentValue.interviewer] += 1;
    } else {
      acc["andre"] += 1;
    }

    return acc;
  }, initialData);

  return Object.keys(totals).map((key) => ({
    name: key.toUpperCase(),
    value: totals[key],
  }));
};

type InterviewerResponse = {
  name: string;
  total: number;
  rate: number;
  successful: number;
  unsuccessful: number;
};
type SuccessRateReturn = {
  [key: string]: InterviewerResponse;
};
const successRateData = (data: Statistic[]): InterviewerResponse[] => {
  const initialData: { [key: string]: any | null } = {};
  data
    .map((d) => d.interviewer)
    .filter((i) => currentInterviewers.includes(i))
    .forEach(
      (i) =>
        (initialData[i] = {
          name: i,
          total: 0,
          rate: 0,
          successful: 0,
          unsuccessful: 0,
        })
    );

  const d = data
    .filter(
      (stat) => stat.type === "conversion" || stat.type === "unsuccessful"
    )
    .reduce((acc: SuccessRateReturn, current: Statistic) => {
      const entry = acc[current.interviewer];
      acc[current.interviewer] = {
        name: current.interviewer,
        successful: (current.type === "conversion" ? 1 : 0) + entry?.successful,
        unsuccessful:
          (current.type === "unsuccessful" ? 1 : 0) + entry?.unsuccessful,
        rate: (entry?.successful / entry?.total) * 100,
        total: (acc[current.interviewer]?.total || 0) + 1,
      };
      return acc;
    }, initialData);

  return Object.keys(d).map((key) => d[key]);
};

const pieChartData = (data: ChartEntry[]): any => {
  const totals: any = data.reduce(
    (acc, data) => {
      const sportCount = (acc["sport"] || 0) + data.sport;
      const outdoorCount = (acc["outdoor"] || 0) + data.outdoor;
      const xOutdoorCount = (acc["x-outdoor"] || 0) + data["x-outdoor"];

      const obj = {
        ...acc,
        name: data.name,
        sport: sportCount,
        outdoor: outdoorCount,
        "x-outdoor": xOutdoorCount,
        total: sportCount + outdoorCount + xOutdoorCount,
      };
      return obj;
    },
    { sport: 0, outdoor: 0, ["x-outdoor"]: 0, total: 0 }
  );

  return Object.keys(totals)
    .map((key) => {
      delete totals["total"];
      //Remove unused elements
      if (key === "name") return null;
      if (key === "total") return null;
      return { name: parseLine(key), value: totals[key], nameKey: key };
    })
    .filter((el) => el != null);
};

export {
  lineChartData,
  pieChartData,
  interviewersPieChartData,
  successRateData,
};
