import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Delete from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const PREFIX = 'UserUploadsComponent';

const classes = {
  root: `${PREFIX}-root`,
  tileRoot: `${PREFIX}-tileRoot`,
  gridList: `${PREFIX}-gridList`,
  icon: `${PREFIX}-icon`,
  flex: `${PREFIX}-flex`,
  menuButton: `${PREFIX}-menuButton`,
  button: `${PREFIX}-button`,
  rightIcon: `${PREFIX}-rightIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%'
  },

  [`& .${classes.tileRoot}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },

  [`& .${classes.gridList}`]: {
    width: '100%',
    height: '100%'
  },

  [`& .${classes.icon}`]: {
    color: 'rgba(255, 255, 255, 0.54)'
  },

  [`& .${classes.flex}`]: {
    flex: 1
  },

  [`& .${classes.menuButton}`]: {
    marginLeft: -12,
    marginRight: 20
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1)
  },

  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1)
  }
}));

const UserUploadsComponent = ({
  classes,
  userUploads,
  downloadAll,
  deleteAll,
  progress
}) => {
  return (
    <Root className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          {progress.mediaLink ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flex}>
              <a href={progress.mediaLink}>Hent</a>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flex}>
              {progress.message}
            </Typography>
          )}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={downloadAll}>
            Download alle
            <CloudDownload className={classes.rightIcon} />
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={deleteAll}>
            Slet alle
            <Delete className={classes.rightIcon} />
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.tileRoot}>
        <ImageList cellHeight={300} className={classes.gridList}>
          <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }} />
          {Object.keys(userUploads).map(key => {
            const upload = userUploads[key];
            return (
              <ImageListItem key={key}>
                <img
                  src={upload.downloadURL}
                  download={upload.name}
                  alt={upload.userName}
                />
                <ImageListItemBar
                  title={upload.name}
                  actionIcon={
                    <IconButton
                      className={classes.icon}
                      onClick={() => window.open(upload.downloadURL, '_blank')}
                      size="large">
                      <CloudDownload />
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </div>
    </Root>
  );
};

UserUploadsComponent.propTypes = {
  userUploads: PropTypes.object.isRequired
};
export default (UserUploadsComponent);
