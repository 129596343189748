import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import { authUser } from "../../actions/auth";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import green from "@mui/material/colors/green";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "./logo.png";

const PREFIX = "LoginContainer";

const classes = {
  root: `${PREFIX}-root`,
  appFrame: `${PREFIX}-appFrame`,
  paper: `${PREFIX}-paper`,
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  divider: `${PREFIX}-divider`,
  imageDiv: `${PREFIX}-imageDiv`,
  wrapper: `${PREFIX}-wrapper`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  buttonSuccess: `${PREFIX}-buttonSuccess`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    minWidth: "400px",
    marginTop: theme.spacing(3),
    zIndex: 1,
    overflow: "hidden",
  },

  [`& .${classes.appFrame}`]: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.paper}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    textAlign: "center",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      content: {
        height: "calc(100% - 64px)",
        marginTop: 64,
      },
    },
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(3),
  },

  [`& .${classes.imageDiv}`]: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.buttonProgress}`]: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  [`& .${classes.buttonSuccess}`]: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

class LoginContainer extends Component {
  render() {
    const { error, isAuthed, authenticating } = this.props;
    return (
      <Root className={classes.root}>
        <div className={classes.appFrame}>
          <main className={classes.content}>
            {!isAuthed && (
              <Grid
                container
                alignItems={"center"}
                justify={"center"}
                spacing={4}
              >
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <Paper className={classes.paper} elevation={4}>
                    <img src={logo} alt={"Paul Petersen logo"} />
                    <Typography type="headline" component="h3">
                      Velkommen til PP KBH Intra
                    </Typography>
                    <Typography type="body1" component="p">
                      Log ind for at forstætte
                    </Typography>
                    <Divider className={classes.divider} />
                    <div className={classes.wrapper}>
                      <Button
                        className={classes.button}
                        onClick={() => this.props.authUser()}
                        color="primary"
                        raised="true"
                      >
                        Login
                      </Button>
                      {authenticating && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    {error !== null ||
                      (error !== undefined && (
                        <Divider className={classes.divider} />
                      ))}
                    <Typography type="body2" component="p">
                      {error}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </main>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { isAuthed, error, authenticating } = auth;
  return {
    error,
    isAuthed,
    authenticating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authUser: () => dispatch(authUser()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LoginContainer
);
