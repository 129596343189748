import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { useParams, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  fetchContact,
  savePrivateContact,
  fetchPagination,
} from "../../actions/contacts";
import { ContactsForm } from "../../components";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { AppState } from "../../reducers";
import Contact from "../../Classes/Contact";

const PREFIX = "ContactContainer";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  buttonRight: `${PREFIX}-buttonRight`,
  leftIcon: `${PREFIX}-leftIcon`,
  rightIcon: `${PREFIX}-rightIconr`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: { marginTop: 24 },

  [`& .${classes.paper}`]: {
    padding: 16,
  },
  [`& .${classes.buttonRight}`]: { float: "right" },
  [`& .${classes.leftIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1),
  },
}));

const ContactContainer: React.FC = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  useFirestoreConnect([
    {
      collection: "contacts",
      doc: id,
    },
  ]);

  const { contact, pagination, isFetchingPagination } = useSelector(
    ({
      firestore: { data },
      contacts: { pagination, isFetchingPagination },
    }: AppState) => ({
      contact: data.contacts && data.contacts[id],
      pagination,
      isFetchingPagination,
    })
  );

  const isFetching = !isLoaded(contact);
  useEffect(() => {
    if (contact) {
      dispatch(fetchPagination(contact));
    }
  }, [contact]);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12} lg={10}>
        {isFetching && <LinearProgress />}
        <Paper className={classes.paper}>
          {contact && !isFetching && (
            <ContactsForm
              initialValues={contact}
              savePrivateContact={(c: Contact) =>
                dispatch(savePrivateContact(c))
              }
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} lg={10}>
        <PreviousNextButtons
          pagination={pagination}
          isFetching={isFetchingPagination}
        />
      </Grid>
    </Grid>
  );
};

interface PaginationProps {
  pagination: any;
  isFetching: boolean;
}
const PreviousNextButtons: React.FC<PaginationProps> = ({
  pagination,
  isFetching,
}) => {
  const next = pagination["next"];
  const previous = pagination["previous"];
  let nextKey: any, previousKey: any;
  const history = useHistory();
  if (next) {
    nextKey = Object.keys(next)[0];
  }
  if (previous) {
    previousKey = Object.keys(previous)[0];
  }
  return (
    <Root>
      <Button
        disabled={!previousKey || isFetching}
        color="primary"
        variant="contained"
        onClick={() => history.push(`/contacts/${previousKey}`)}>
        <KeyboardArrowLeft className={classes.leftIcon} />
        {(previousKey && previous[previousKey]) || "Ej muligt"}
      </Button>
      <Button
        disabled={!nextKey || isFetching}
        color="primary"
        variant="contained"
        className={classes.buttonRight}
        onClick={() => history.push(`/contacts/${nextKey}`)}>
        {(nextKey && next[nextKey]) || "Ej muligt"}
        <KeyboardArrowRight className={classes.rightIcon} />
      </Button>
    </Root>
  );
};

export default ContactContainer;
