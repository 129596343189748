import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridValueGetter,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import useStudentGrades from "../../hooks/useStudentGrades";
import { parseExamResult, parseExamType } from "../../helpers/parser";
import Button from "@mui/material/Button";

const PREFIX = "StudentGradeTable";

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  buttonRow: `${PREFIX}-buttonRow`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    hegith: 400,
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },

  [`& .${classes.table}`]: {
    minWidth: 700,
  },

  [`& .${classes.buttonRow}`]: {
    padding: 16,
  },
}));

type GradeTableProps = {
  studentId: string;
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Fag",
    width: 150,
    editable: false,
    sortable: true,
    valueGetter: (_, row) => {
      return row.subject.name;
    },
  },

  {
    field: "value",
    headerName: "Værdi",
    width: 150,
    editable: true,
    valueGetter: (_, row) => parseExamResult(row.value),
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
    editable: false,
    sortable: true,
    valueGetter: (_, row) => parseExamType(row.type),
  },
];

const StudentGradeTable: React.FC<GradeTableProps> = ({ studentId }) => {
  const [selections, setSelections] = useState<GridRowId[]>([]);
  const grades = useStudentGrades(studentId);

  return (
    <StyledPaper className={classes.root}>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={grades}
          columns={columns}
          // pageSize={20}
          autoPageSize
          pageSizeOptions={[20]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(selected: any) => setSelections(selected)}
        />
      </div>
      <div className={classes.buttonRow}>
        <Button
          onClick={() => console.log("delete")}
          disabled={selections.length === 0}
          variant="contained"
          color="secondary"
        >
          Slet
        </Button>
      </div>
    </StyledPaper>
  );
};

export default StudentGradeTable;
