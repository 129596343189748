import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import {
  firebaseReducer,
  FirebaseReducer,
  FirestoreReducer,
} from "react-redux-firebase";
import { History } from "history";
import { firestoreReducer } from "redux-firestore";
import auth from "./reducers/auth";
import applicants from "./reducers/applicants";
import students from "./reducers/students";
import fileUploads from "./reducers/fileUploads";
import semesters from "./reducers/semesters";
import templates from "./reducers/templates";
import snackbar from "./reducers/snackbar";
import messages from "./reducers/messages";
import uploads from "./reducers/uploads";
import exams from "./reducers/exams";
import settings from "./reducers/settings";
import examiners from "./reducers/examiners";
import sheets from "./reducers/sheets";
import contacts from "./reducers/contacts";
import search from "./reducers/search";
import certificates from "./reducers/certificates";

import { IApplicant } from "./Applicants/Applicant";

interface UserProfile {
  email: string;
  displayName: string;
}

// create schema for the DB
interface DBSchema {
  applicants: IApplicant[];
  semesters: { title: string }[];
  [name: string]: any;
}
interface RootState {
  firebase: FirebaseReducer.Reducer<UserProfile, DBSchema>;
  firestore: FirestoreReducer.Reducer;
  router: RouterState;
  students: any;
  semesters: any;
  fileUploads: any;
  messages: any;
  exams: any;
  sheets: any;
  contacts: any;
}

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    applicants,
    students,
    fileUploads,
    semesters,
    templates,
    snackbar,
    messages,
    uploads,
    exams,
    settings,
    examiners,
    sheets,
    contacts,
    search,
    certificates,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

export type AppState = RootState;

export default rootReducer;
