import React, { Component, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "recompose";
import {
  fetchStudents,
  deleteStudents,
  fetchClasses,
  setQueryForStudents,
  getClassImages,
  setProperty,
} from "../../actions/students";
import { setRecipients } from "../../actions/messages";
import { submitSheet, createTravelInfoSheet } from "../../actions/sheets";
import { setModalOpen } from "../../actions/certificates";
import {
  StudentsComponent,
  ConfirmDeleteDialog,
  CreateSpreadsheetDialog,
} from "../../components";
import Grid from "@mui/material/Grid";
import Student from "../../Classes/Student";
import { AppState } from "../../reducers";

const PREFIX = "StudentsContainer";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  },
}));

const StudentsContainer: React.FC = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [spreadsheetDialogOpen, setSpreadsheetDialogOpen] = useState(false);
  const [items, setItems] = useState<Student[]>([]);

  const { semester }: { semester: string } = useParams();
  const dispatch = useDispatch();
  const {
    students: {
      students,
      isFetching,

      isDeleting,
      didDeleteItems,
    },
    sheets: { isCreatingTravelInfo, travelInfoError },
  } = useSelector((state: AppState) => state);

  const handleFetchStudents = (withQueries: any) => {
    const query = {
      ...withQueries,
      semester,
    };
    dispatch(setQueryForStudents(query));
  };

  const handleDeleteItems = (items: string[]) => {
    dispatch(deleteStudents(items));
  };

  const handleSendMessages = (ids: string[]) => {
    const studentsArr = students.filter((s: Student) => ids.includes(s.id));
    const recipients = studentsArr.map((s: Student) => {
      return {
        name: `${s.firstName} ${s.lastName}`,
        email: s.email,
        phone: s.phone,
        collection: "students",
        docId: s.id,
        status: "not_submitted",
      };
    });
    dispatch(setRecipients(recipients));
  };

  const handleCreateSpreadsheet = (items: Student[]) => {
    setSpreadsheetDialogOpen(!spreadsheetDialogOpen);
    setItems(items);
  };

  const handleCreateTravelInfo = (items: Student[]) => {
    dispatch(createTravelInfoSheet(items));
  };

  const closeSpreadsheetDialog = () => {
    setSpreadsheetDialogOpen(false);
  };
  const handleCreate = (values: any, translations: any) => {
    // Items is set in 'handleCreateSpreadsheet' when the modal is openend

    dispatch(submitSheet(values, items, translations));
  };

  const handleGetClassImages = () => {
    dispatch(getClassImages());
  };

  const handleCreateCertificateRequest = (ids: string[]) => {
    dispatch(setModalOpen(true, ids));
  };

  const handleSetGraduated = (date: Date, ids: string[]) => {
    dispatch(setProperty({ graduated: true, graduatedDate: date }, ids));
  };

  const handleSetProp = (prop: any, ids: string[]) =>
    dispatch(setProperty(prop, ids));

  return (
    <Root className={classes.root}>
      {/* <CreateSpreadsheetDialog
          open={spreadsheetDialogOpen}
          handleClose={closeSpreadsheetDialog}
          handleCreate={handleCreate}
        /> */}
      <ConfirmDeleteDialog
        open={deleteDialogOpen}
        itemsShouldDelete={() => handleDeleteItems(items.map((s) => s.id))}
        handleRequestClose={() => setDeleteDialogOpen(false)}
        itemType={"studerende"}
        itemCount={items.length}
        isDeleting={isDeleting}
        didDeleteItems={didDeleteItems}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StudentsComponent
            students={students}
            isFetching={isFetching || isCreatingTravelInfo}
            fetchStudents={(withQueries) => {
              handleFetchStudents(withQueries);
            }}
            handleDeleteItems={(items) => handleDeleteItems(items)}
            handleSendMessages={handleSendMessages}
            handleCreateSpreadsheet={handleCreateSpreadsheet}
            handleCreateTravelInfo={handleCreateTravelInfo}
            handleCreateCertificateRequest={handleCreateCertificateRequest}
            handleGetClassImages={handleGetClassImages}
            handleSetGraduated={handleSetGraduated}
            handleSetProp={handleSetProp}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export default StudentsContainer;
