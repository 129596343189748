import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Done from '@mui/icons-material/Done';
import IconButton from '@mui/material/IconButton';
import green from '@mui/material/colors/green';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
const PREFIX = 'ExamsTableComponent';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  doneButton: `${PREFIX}-doneButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    overflowX: 'auto',
    maxHeight: '600px'
  },

  [`& .${classes.table}`]: {
    minWidth: 500
  },

  [`& .${classes.doneButton}`]: {
    color: green[500]
  }
}));

const gradeTypes = ['pass-fail', 'attendance', 'scale'];
const lines = ['sport', 'xOutdoor', 'outdoor'];

class ExamsTableComponent extends Component {
  state = {
    isEditing: false,
    selected: '',
    gradeType: '',
    line: '',
    name: ''
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleEdit = key => {
    const { exams } = this.props;
    const { gradeType, line, name } = exams[key];
    this.setState({ selected: key, isEditing: true, name, gradeType, line });
  };

  updateExamSubject = key => {
    const { name, line, gradeType } = this.state;
    const subject = {
      id: key,
      name,
      line,
      gradeType
    };
    this.props.handleUpdate(subject);
    this.setState({
      isEditing: false,
      selected: '',
      name: '',
      line: '',
      gradeType: ''
    });
  };
  render() {
    const {
      classes,
      exams,
      checkIsSelected,
      examDidSelect,
      hasSelection,
      deleteExams
    } = this.props;
    const { isEditing, selected } = this.state;
    return (
      <Root className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          disabled={!hasSelection}
          onClick={() => deleteExams()}>
          Slet
        </Button>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Vælg</TableCell>
              <TableCell padding="none">Rediger</TableCell>
              <TableCell>Titel</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Linie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(exams).map(key => {
              const exam = exams[key];
              const isSelected = checkIsSelected(exam.id);
              return (
                <TableRow hover selected={isSelected} key={key}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={() => examDidSelect(key)}
                      checked={isSelected}
                    />
                  </TableCell>
                  <TableCell padding="none">
                    <IconButton
                      onClick={() =>
                        !isEditing
                          ? this.handleEdit(key)
                          : this.updateExamSubject(key)
                      }
                      disabled={isEditing && key !== selected}>
                      {isEditing && key === selected ? (
                        <Done className={classes.doneButton} />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {isEditing && key === selected ? (
                      <TextField
                        value={this.state.name}
                        onChange={this.handleChange('name')}
                      />
                    ) : (
                      exam.name
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing && key === selected ? (
                      <Select
                        value={this.state.gradeType}
                        onChange={this.handleChange('gradeType')}
                        inputProps={{
                          name: 'gradeType'
                        }}>
                        {gradeTypes.map(type => {
                          return (
                            <MenuItem key={type} value={type}>
                              {sanatizeGradeType(type)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      sanatizeGradeType(exam.gradeType)
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing && key === selected ? (
                      <Select
                        value={this.state.line}
                        onChange={this.handleChange('line')}
                        inputProps={{
                          name: 'line'
                        }}>
                        {lines.map(line => {
                          return (
                            <MenuItem key={line} value={line}>
                              {sanatizeLine(line)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : (
                      sanatizeLine(exam.line)
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Root>
    );
  }
}

const sanatizeGradeType = type => {
  switch (type) {
    case 'pass-fail':
      return 'Bestået/Ikke bestået';
    case 'attendance':
      return 'Deltaget/Ej deltaget';
    case 'scale':
      return 'Karakter';
    default:
      return type;
  }
};

const sanatizeLine = line => {
  switch (line) {
    case 'sport':
      return 'Sports';
    case 'xOutdoor':
      return 'X-Outdoor';
    case 'outdoor':
      return 'Friluft';
    default:
      return line;
  }
};

ExamsTableComponent.propTypes = {
  exams: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  checkIsSelected: PropTypes.func.isRequired,
  examDidSelect: PropTypes.func.isRequired
};

export default (ExamsTableComponent);
