import React from "react";
import { Select, MenuItem, Grid, SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import useSemesters from "../../hooks/useSemesters";
import { ApplicantsChart } from "./ApplicantsChart";
import AccumulatedStudentsChart from "./AccumulatedStudentsChart";
import StudentsBarChart from "./StudentsBarChart";
import useGraphData from "../../hooks/useGraphData";
import useStatistics from "../../hooks/useStatistics";
import InterviewersPieCount from "./InterviewersPieCount";
import StudentsPieChart from "./StudentsPieChart";
import InterviewSuccessRate from "./InterviewSuccessRate";

type TabPanelProps = {
  children: React.ReactNode;
  value: number;
  index: number;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const DashboardComponent: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(1);

  const semesters = useSemesters();
  const [applicantsSemester, setApplicantSemester] =
    React.useState<Semester | null>(semesters[0] || { title: "2021-2022" });
  const [semester, setSemester] = React.useState<Semester | null>(
    semesters[0] || { title: "2022-2023" }
  );

  const handleTabValueChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const data = useGraphData(semester?.title);
  const statistics = useStatistics(semester?.title);

  const handleSetApplicantSemester = (event: SelectChangeEvent<string>) => {
    const s = semesters.filter((s) => s.title === event.target.value)[0];
    setApplicantSemester(s);
  };
  const handleSetSemester = (event: SelectChangeEvent<string>) => {
    const s = semesters.filter((s) => s.title === event.target.value)[0];
    setSemester(s);
  };
  return (
    <Paper elevation={4}>
      <Box mx="auto" my="10px">
        <Tabs
          value={tabValue}
          onChange={handleTabValueChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Ansøgere" />
          <Tab label="Studerende" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <Box p={2} width="100%" height={500} bgcolor="background.paper">
          <>
            <div style={{ display: "flex" }}>
              <Typography variant="body1" style={{ marginRight: 8 }}>
                Vælg ansøger år
              </Typography>
              <Select
                value={applicantsSemester?.title}
                onChange={(e) => handleSetApplicantSemester(e)}
              >
                {semesters.map((semester) => (
                  <MenuItem value={semester.title} key={semester.title}>
                    {semester.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <ApplicantsChart semester={semester?.title} />
          </>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container style={{ padding: 8, minHeight: 270 }}>
          <Grid item xs={12} style={{ marginBottom: 16 }}>
            <Typography variant="body1" style={{ marginRight: 8 }}>
              Vælg semester
            </Typography>
            {semesters.length !== 0 ? (
              <Select
                style={{ minWidth: 200 }}
                value={semester?.title}
                onChange={(e) => handleSetSemester(e)}
              >
                {semesters.map((semester) => (
                  <MenuItem value={semester.title} key={semester.title}>
                    {semester.title}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <InterviewSuccessRate data={statistics} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InterviewersPieCount data={statistics} />
          </Grid> */}
          <Grid item xs={12} md={4}>
            {/* <StudentsPieChart data={data} /> */}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} style={{ minHeight: 500 }}>
            <Box
              p={2}
              m={2}
              boxShadow={2}
              bgcolor="background.paper"
              width={1}
              borderRadius="5px"
              height={500}
            >
              {/* <StudentsChart /> */}
              <AccumulatedStudentsChart data={data} />
            </Box>
            <Box
              p={2}
              m={2}
              boxShadow={2}
              bgcolor="background.paper"
              width={1}
              borderRadius="5px"
              height={500}
            >
              <StudentsBarChart data={data} />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
    </Paper>
  );
};

export default DashboardComponent;
