import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
class ConfirmDeleteDialog extends Component {
  state = { text: '' };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handleTextChange = event => {
    this.setState({ text: event.target.value });
  };
  render() {
    const {
      open,
      itemCount,
      itemType,
      handleRequestClose,
      itemsShouldDelete,
      isDeleting,
      didDeleteItems
    } = this.props;
    const title = `Er du sikker på at du vil slette ${itemCount} ${itemType}?`;
    const deletionString = `slet disse ${itemType}`;
    return (
      <div>
        <Dialog
          open={open || (isDeleting && !didDeleteItems)}
          onClose={handleRequestClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              For at slette {itemCount === 0 ? 'den' : 'de'} valgte {itemType}{' '}
              skal du skrive <i>{deletionString}</i>
            </DialogContentText>
            <TextField
              type="text"
              value={this.state.text}
              onChange={this.handleTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleRequestClose}>
              Annuller
            </Button>
            <Button
              onClick={() => itemsShouldDelete()}
              color="secondary"
              variant="contained"
              disabled={deletionString !== this.state.text || isDeleting}
              autoFocus>
              Slet
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  itemType: PropTypes.string.isRequired,
  handleRequestClose: PropTypes.func.isRequired,
  itemsShouldDelete: PropTypes.func.isRequired,
  didDeleteItems: PropTypes.bool.isRequired
};

export default ConfirmDeleteDialog;
