import React from "react";
import { green, red, orange } from "@mui/material/colors";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const getColour = (entry: string): string => {
  switch (entry) {
    case "attending":
      return green[500];
    case "not_attending":
      return red[500];
    case "unconfirmed":
      return orange[500];
  }
  return "fff";
};
type ChartEntry = { label: string; nameKey: string; value: number };
type ChartProps = {
  data: ChartEntry[];
  nextEventDate: string;
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const NextEventStatsPieChart: React.FC<ChartProps> = ({
  data,
  nextEventDate,
}) => {
  return (
    <PieChart
      series={[
        {
          data: data.map((d: any) => {
            d["color"] = getColour(d.nameKey);
            return d;
          }),
          arcLabel: (item) =>
            item.value > 0 ? `${item.label} (${item.value})` : "",
          innerRadius: 80,
          outerRadius: 120,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "black",
          fontWeight: "bold",
        },
      }}
    >
      <PieCenterLabel>{nextEventDate}</PieCenterLabel>
    </PieChart>
  );
};

export default NextEventStatsPieChart;
