import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import { connect } from "react-redux";
import { compose } from "recompose";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PermContactCalendar from "@mui/icons-material/PermContactCalendar";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import MessageIcon from "@mui/icons-material/Message";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LocalLibrary from "@mui/icons-material/LocalLibrary";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsPower from "@mui/icons-material/SettingsPower";
import Archive from "@mui/icons-material/Archive";
import Drawer from "@mui/material/Drawer";
import Badge from "@mui/material/Badge";
import { withRouter } from "react-router-dom";
import { drawerWidth } from "../../constants";

const PREFIX = "TestableDrawerComponent";

const classes = {
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  link: `${PREFIX}-link`,
  toolbar: `${PREFIX}-toolbar`,
  title: `${PREFIX}-title`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    position: "relative",
    height: "100%",
    width: drawerWidth,
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    color: "black",
  },

  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(3),
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) / 2,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const ListItemButton = ({ history, semester }) => (
  <ListItem
    button
    onClick={() => {
      history.push(`/semesters/${semester}?tab=0`);
    }}
  >
    <ListItemText inset primary={semester} />
  </ListItem>
);

const DrawerComponent = ({
  open,
  openStudents,
  openExams,
  handleToggleExpansion,
  semesters,
  handleToggleDrawer,
  handleToggleDialog,
  handleToggleArchivedSemestersDialog,
  history,
  auth,
}) => {
  return (
    <Root>
      <Drawer
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
      >
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={() => {
                handleToggleDrawer();
              }}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <div className={classes.toolbar}>
              <Link className={classes.title} to="/">
                <Typography variant="h6" color="inherit">
                  PP KBH Intra
                </Typography>
              </Link>
            </div>
          </div>
          <List>
            <Link to="/" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
            <Link to="/applicants" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Ansøgere" />
              </ListItem>
            </Link>
            <ListItem
              button
              onClick={() => handleToggleExpansion("openStudents")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Studerende" />{" "}
              {openStudents ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openStudents} transitionduration="auto" unmountOnExit>
              {semesters
                .sort()
                .reverse()
                .slice(0, 5)
                .map((semester) => {
                  return (
                    <ListItemButton
                      history={history}
                      key={semester}
                      classes={classes}
                      semester={semester}
                    />
                  );
                })}
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleToggleDialog()}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={"Tilføj semester"} />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleToggleArchivedSemestersDialog()}
              >
                <ListItemIcon>
                  <Archive />
                </ListItemIcon>
                <ListItemText primary={"Arkiverede"} />
              </ListItem>
            </Collapse>
            <Link to="/examiners" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <LocalLibrary />
                </ListItemIcon>
                <ListItemText primary="Censorer" />
              </ListItem>
            </Link>
            <Link to="/contacts" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PermContactCalendar />
                </ListItemIcon>
                <ListItemText primary="Kontakter" />
              </ListItem>
            </Link>

            {/* <ListItem
              dense
              button
              onClick={() => handleToggleExpansion("openExams")}
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Eksamensresultater" />
              {openExams ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openExams} transitionduration="auto" unmountOnExit>
              <Link to="/exams" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText primary={"Tildel resultater"} />
                </ListItem>
              </Link>
              <Link to="/exams/add" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Tilføj eksamensfag"} />
                </ListItem>
              </Link>
              <Link to="/exams/edit" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText primary={"Rediger eksamensfag"} />
                </ListItem>
              </Link>
            </Collapse> */}
            <Link to="/messages" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary="Beskeder" />
              </ListItem>
            </Link>
            <Link to="/uploads" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="Uploads" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link to="/settings" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  {!auth.emailVerified ? (
                    <Badge badgeContent={1} color="secondary">
                      <SettingsPower />
                    </Badge>
                  ) : (
                    <SettingsPower />
                  )}
                </ListItemIcon>
                <ListItemText primary="Indstillinger" />
              </ListItem>
            </Link>
            <Link to="/users" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Brugere" />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>
    </Root>
  );
};

DrawerComponent.propTypes = {
  semesters: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  openStudents: PropTypes.bool.isRequired,
  handleToggleExpansion: PropTypes.func.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ firebase }) => {
  return {
    auth: firebase.auth,
  };
};

export const TestableDrawerComponent = DrawerComponent;
export default withRouter(
  compose(connect(mapStateToProps, null))(DrawerComponent)
);
