import React, { useEffect } from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import styled from "styled-components";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Typography, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { parseExamType, parseLine } from "../../helpers/parser";

const SUBJECTS_COLLECTION = "subjects";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

type Selection = Subject[];
type Data = Subject;
type TableProps = {
  setSelection: (selection: Selection) => void;
  semester: string;
};

const SubjectsTable: React.FC<TableProps> = React.memo(
  ({ setSelection, semester }) => {
    const theme = useTheme();
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Navn",
        width: 150,
        renderCell: (params) => (
          <StyledLink to={params.row.id}>{params.value}</StyledLink>
        ),
      },
      {
        field: "lines",
        headerName: "Linie",
        width: 150,
        renderCell: (params) => params.row.lines?.map(parseLine).join(", "),
      },
      {
        field: "exam",
        headerName: "Type",
        width: 150,
        renderCell: (params) => parseExamType(params.row.exam.type),
      },
    ];

    useFirestoreConnect([
      {
        collection: SUBJECTS_COLLECTION,
        where: ["semester", "==", semester],
      },
    ]);

    const events = useSelector(
      ({ firestore: { ordered } }: AppState) =>
        ordered[SUBJECTS_COLLECTION] || []
    );

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
      const selectedSubjects = newSelection.map((id) =>
        events.find((event) => event.id === id)
      );
      setSelection(selectedSubjects as Subject[]);
    };

    return (
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={events}
          columns={columns}
          autoPageSize
          pageSizeOptions={[20, 30, 40, 50]}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          sx={{
            backgroundColor: theme.palette.background.default,
            boxShadow: "rgba(0, 0, 0, 0.05) 1px 1px 10px 10px",
            borderRadius: "10px",
          }}
        />
      </div>
    );
  }
);

export default SubjectsTable;
