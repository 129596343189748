import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import NewIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Divider } from "@mui/material";
import ExaminerIcon from "../../components/Icons/ExaminersIcon";
import useSubjects from "../../hooks/useSubjects";

const PREFIX = "Header";

const classes = {
  root: `${PREFIX}-root`,
  cardRoot: `${PREFIX}-cardRoot`,
  cardHeader: `${PREFIX}-cardHeader`,
  cardContent: `${PREFIX}-cardContent`,
  dischargeSelect: `${PREFIX}-dischargeSelect`,
  typography: `${PREFIX}-typography`,
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`,
  button: `${PREFIX}-button`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  radio: `${PREFIX}-radio`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    marginTop: 30,
  },

  [`& .${classes.cardRoot}`]: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },

  [`& .${classes.cardHeader}`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },

  [`& .${classes.cardContent}`]: {
    padding: 10,
  },

  [`& .${classes.dischargeSelect}`]: {
    padding: theme.spacing(2),
    minWidth: 300,
  },

  [`& .${classes.typography}`]: { fontWeight: 600 },

  [`& .${classes.select}`]: {
    width: "100%",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.divider}`]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.radio}`]: {
    marginTop: theme.spacing(2),
  },
}));

type HeaderProps = {
  selection: string[];
  newExaminer: () => void;
  deleteExaminer: () => void;
  createSpreadsheet: () => void;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  semesters: { title: string }[];
  setQuery: (query: string | null) => void;
  query: string | null;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  newExaminer,
  deleteExaminer,
  createSpreadsheet,
  semesters,
  setQuery,
  query,
}) => {
  const [subjectQuery, setSubjectQuery] = useState({ line: "", semester: "" });
  const [subject, setSubject] = useState("");
  const subjects = useSubjects({
    setQuery: setSubjectQuery,
    ...subjectQuery,
  });

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Censorer"} />

            <CardContent className={classes.cardContent}>
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} md={2}>
                  <div style={{ textAlign: "center" }}>
                    <ExaminerIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={2}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                    style={{ width: "100%" }}
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => newExaminer()}
                    >
                      Ny censor
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<ListAltIcon />}
                      onClick={() => createSpreadsheet()}
                      disabled={selection.length < 1}
                    >
                      Excel udtræk
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteExaminer()}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet
                    </Button>
                  </ButtonGroup>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                      Vis censorer der er brugt for en given linie, semester og
                      fag.
                    </Typography>
                    <FormControl component="fieldset" className={classes.radio}>
                      <RadioGroup
                        row
                        name="line"
                        value={subjectQuery.line}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSubjectQuery({
                            line: e.target.value,
                            semester: subjectQuery.semester,
                          })
                        }
                      >
                        <FormControlLabel
                          value="sport"
                          control={<Radio />}
                          label={
                            <Typography variant="caption">Sports</Typography>
                          }
                        />
                        <FormControlLabel
                          value="xOutdoor"
                          control={<Radio />}
                          label={
                            <Typography variant="caption">X-Outdoor</Typography>
                          }
                        />
                        <FormControlLabel
                          value="outdoor"
                          control={<Radio />}
                          label={
                            <Typography variant="caption">Friluft</Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="semester-select-label">
                        Semester
                      </InputLabel>
                      <Select
                        labelId="semester-select-label"
                        value={subjectQuery.semester}
                        onChange={(e) =>
                          setSubjectQuery({
                            semester: e.target.value as string,
                            line: subjectQuery.line,
                          })
                        }
                      >
                        <MenuItem value={""}>Vælg</MenuItem>
                        {semesters.map((semester: { title: string }) => (
                          <MenuItem value={semester.title} key={semester.title}>
                            {semester.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="semester-select-label">Fag</InputLabel>
                      <Select
                        labelId="semester-select-label"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value as string);
                          setQuery(e.target.value as string);
                        }}
                        disabled={subjects.length === 0}
                      >
                        <MenuItem value={""}>Vælg</MenuItem>
                        {subjects.map((subject: Subject) => (
                          <MenuItem value={subject.id} key={subject.id}>
                            {subject.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      disabled={Boolean(query) === false}
                      color="primary"
                      onClick={() => setQuery(null)}
                    >
                      Ryd
                    </Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Header;
