import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import amber from "@mui/material/colors/amber";
import { lineChartData } from "../../helpers/statisticsCalculator";

const colors = {
  sport: "#9C1918",
  outdoor: "#5B5A37",

  discharged: "#F78AE0",
  "x-outdoor": "#364B55",
  total: amber[300],
};
type Props = {
  data: ChartEntry[];
};
const AccumulatedStudentsChart: React.FC<Props> = ({ data }) => {
  return (
    <ResponsiveContainer width={"100%"} height="80%">
      <LineChart data={lineChartData(data)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />

        <Line dataKey="sport" name="Classic Sports" stroke={colors["sport"]} />

        <Line dataKey="outdoor" name="Friluft" stroke={colors["outdoor"]} />

        <Line
          dataKey="x-outdoor"
          name="X-Outdoor"
          stroke={colors["x-outdoor"]}
        />

        <Line dataKey="total" name="Total" stroke={colors["total"]} />

        <Line
          dataKey="discharged"
          name="Udmeldte"
          stroke={colors["discharged"]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AccumulatedStudentsChart;
