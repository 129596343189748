import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = 'SnackbarComponent';

const classes = {
  close: `${PREFIX}-close`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.close}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

const SnackbarComponent = ({ open, message,  requestClose }) => (
  <Root>
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={6000}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={requestClose}
          size="large">
          <CloseIcon />
        </IconButton>
      }
    />
  </Root>
);

SnackbarComponent.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired
};

export default (SnackbarComponent);
