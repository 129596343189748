import React from "react";
import { styled } from "@mui/material/styles";
import { ContactsForm } from "../../components";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";

import { createPrivateContact } from "../../actions/contacts";
import Contact from "../../Classes/Contact";

const PREFIX = "NewContactContainer";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: { marginTop: 24 },

  [`& .${classes.paper}`]: {
    padding: 16,
  },
}));

const NewContactContainer = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: any) => {
    dispatch(createPrivateContact(values));
  };

  return (
    <StyledGrid container justifyContent="center" className={classes.root}>
      <Grid item xs={12} lg={8}>
        <Paper className={classes.paper}>
          <ContactsForm
            /* Set the default values here when creating a new contact */
            initialValues={new Contact()}
            savePrivateContact={handleSubmit}
          />
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default NewContactContainer;
