import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PermContactIcon from "@mui/icons-material/PermContactCalendar";
import SecurityIcon from "@mui/icons-material/Security";
import FolderIcon from "@mui/icons-material/Folder";
import SchoolIcon from "@mui/icons-material/School";

const StudentTabBar = ({ value, handleChange, isFetching }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab
        label="Generelle oplysninger"
        icon={<PermContactIcon />}
        disabled={isFetching}
      />
      <Tab
        label="Forsikring & Sikkerhed"
        icon={<SecurityIcon />}
        disabled={isFetching}
      />
      <Tab label="Journal mappe" icon={<FolderIcon />} disabled={isFetching} />
      <Tab
        label="Eksamensresultater"
        icon={<SchoolIcon />}
        disabled={isFetching}
      />
    </Tabs>
  );
};

StudentTabBar.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default StudentTabBar;
