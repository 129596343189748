import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (props: EventCompletedProps) => void;
};

const CompleteEventDialog: React.FC<DialogProps> = ({
  open,
  handleConfirm,
  handleClose,
}) => {
  const [attendance, setAttending] = React.useState<number>(0);
  const [interviews, setInterviews] = React.useState<number>(0);
  const [interviewsSport, setInterviewsSport] = React.useState<number>(0);
  const [interviewsOutdoor, setInterviewsOutdoor] = React.useState<number>(0);
  const [interviewsXOutdoor, setInterviewsXOutdoor] = React.useState<number>(0);
  const [facebookInterested, setFacebookInterested] = React.useState<number>(0);
  const [
    facebookParticipants,
    setFacebookParticipants,
  ] = React.useState<number>(0);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Marker som afviklet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hvis du markerer denne infoaften som afviklet vil det ikke længere
            være muligt at foretage ændringer.{" "}
            <strong>NB. ALLE EMAIL ADRESSER BLIVER SLETTET</strong>
          </DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                autoFocus
                value={attendance}
                onChange={(e) => setAttending(parseInt(e.target.value))}
                margin="dense"
                label="Hvor mange mødte op til infoaften?"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={interviews}
                onChange={(e) => setInterviews(parseInt(e.target.value))}
                margin="dense"
                label="Aftalte samtaler"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={interviewsSport}
                onChange={(e) => setInterviewsSport(parseInt(e.target.value))}
                margin="dense"
                label="Samtaler Sport"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={interviewsOutdoor}
                onChange={(e) => setInterviewsOutdoor(parseInt(e.target.value))}
                margin="dense"
                label="Samtaler Friluft"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={interviewsXOutdoor}
                onChange={(e) =>
                  setInterviewsXOutdoor(parseInt(e.target.value))
                }
                margin="dense"
                label="Samtaler X-Outdoor"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={facebookInterested}
                onChange={(e) =>
                  setFacebookInterested(parseInt(e.target.value))
                }
                margin="dense"
                label="Facebook Interesserede"
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                value={facebookParticipants}
                onChange={(e) =>
                  setFacebookParticipants(parseInt(e.target.value))
                }
                margin="dense"
                label="Facebook Deltagere"
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuller
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              handleConfirm({
                attendance,
                interviews,
                interviewsSport,
                interviewsOutdoor,
                interviewsXOutdoor,
                facebookInterested,
                facebookParticipants,
              })
            }
            color="secondary"
          >
            Afviklet
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CompleteEventDialog;
