import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import {
  createCertificateRequest,
  setModalOpen,
} from "../../actions/certificates";

class CertificateDialog extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = { fieldName: "", certificateName: "" };

  handleClose = () => {
    this.props.setModalOpen(false, []);
  };

  handleSubmit = () => {
    if (this.fileInput.current.files) {
      this.props.createCertificateRequest(
        this.fileInput.current.files[0],
        this.state.fieldName,
        this.state.certificateName
      );
    } else {
      // Set error here
      console.log("Error creating pdf");
    }
  };

  onFieldChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { isOpen, status } = this.props;
    let isWorking = false;
    let informationString;
    switch (status) {
      case ("working", "pending"):
        isWorking = true;
        informationString = "Arbejder...";
        break;
      case "success":
        isWorking = false;
        informationString = "✅ Færdig";
        break;
      case "error":
        isWorking = false;
        informationString = "🛑 Fejl";
        break;
      default:
        break;
    }

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Instruktørbevis</DialogTitle>
          <DialogContent>
            <DialogContentText>
              For at bruge denne funktion skal du vedhæfte et dokument der har
              et formular felt (dette kan tilføjes i Adobe Acrobat)
            </DialogContentText>
            <TextField
              autoFocus
              id="certificateName"
              label="Navn på formular (eks. 'Løbeinstruktør')"
              value={this.state.certificateName}
              onChange={this.onFieldChange("certificateName")}
              type="text"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Navn på formular felt"
              value={this.state.fieldName}
              onChange={this.onFieldChange("fieldName")}
              type="text"
              fullWidth
            />
            <input type="file" ref={this.fileInput} />
            <Typography variant="caption" color="error">
              {this.props.error}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              {informationString}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {status === "success" || status === "error" ? "OK" : "Annuller"}
            </Button>

            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={isWorking}
            >
              Opret beviser
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ certificates }) => {
  const { creating, error, success, isOpen, ids, status } = certificates;
  return {
    creating,
    error,
    success,
    isOpen,
    ids,
    status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCertificateRequest: (file, fieldName, certificateName) =>
      dispatch(createCertificateRequest(file, fieldName, certificateName)),
    setModalOpen: (value) => dispatch(setModalOpen(value)),
  };
};

CertificateDialog.propTypes = {
  ids: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateDialog);
