import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useFirestoreConnect,
  useFirestore,
  WhereOptions,
} from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../reducers";
import Header from "./components/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createExaminersSheet } from "../actions/sheets";

const EXAMINERS_COLLECTION = "examiners";

const getQuery = (query: string | null): WhereOptions[] => {
  if (query) {
    return [
      ["subjects", "array-contains", query],
      ["isArchived", "==", false],
    ];
  } else {
    return [["isArchived", "==", false]];
  }
};

const columns = (history: any): GridColDef[] => [
  {
    field: "actions",
    type: "actions",
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        icon={<VisibilityIcon />}
        onClick={() => {
          history.push(`/examiners/${params.row.id}`);
        }}
        label="Delete"
      />,
    ],
  },
  {
    field: "firstName",
    headerName: "Fornavn",
    type: "string",
    width: 190,
  },
  {
    field: "lastName",
    headerName: "Efternavn",
    type: "string",
    width: 190,
  },
  {
    field: "phone",
    headerName: "Telefon",
    type: "string",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    type: "string",
    width: 220,
  },
  {
    field: "approved",
    headerName: "Godkendt",
    type: "boolean",
    width: 220,
  },
  {
    field: "lastUsed",
    headerName: "Sidst Anvendt",
    type: "date",
    width: 220,
    valueGetter: (value, row) => {
      if (!row.lastUsed) return value;
      return row.lastUsed.toDate();
    },
  },
];

const ExaminersComponent: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const [query, setQuery] = React.useState<string | null>(null);
  const [selection, setSelection] = React.useState<GridRowSelectionModel>([]);
  useFirestoreConnect([{ collection: "semesters" }]);

  const [filters, setFilters] = useState<Filters>({
    approved: null,
    examinerReport: null,
    paymentInformationForwarded: null,
  });

  const semesters = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.semesters || []
  );

  const handleCreateNewExaminer = async () => {
    try {
      const doc = firestore.collection("examiners").doc();
      const newExaminer: Examiner = {
        id: doc.id,
        firstName: "",
        lastName: "",
        isArchived: false,
        approved: false,
        approvedSubjects: [],
        approvedUntil: null,
        lastUsed: null,
        email: "",
        phone: "",
        paymentInformationForwarded: false,
        examinerReport: false,
        note: "",
        examinations: [],
        subjects: {},
        semesters: [],
      };
      await doc.set(newExaminer);
      history.push(`/examiners/${doc.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteExaminers = async () => {
    if (selection.length === 0) return;

    if (
      window.confirm(
        `Er du sikker på du vil slette ${selection.length} censorer?`
      )
    ) {
      const batch = firestore.batch();
      const collectionRef = firestore.collection("examiners");
      selection.forEach((id) => batch.delete(collectionRef.doc(id as string)));

      try {
        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCreateSpreadsheet = () => {
    dispatch(createExaminersSheet(selection));
  };

  useFirestoreConnect([
    { collection: EXAMINERS_COLLECTION, where: getQuery(query) },
  ]);
  const examiners = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.examiners || []
  );

  return (
    <div style={{ marginBottom: 16, background: "white" }}>
      <div>
        <Header
          newExaminer={handleCreateNewExaminer}
          deleteExaminer={handleDeleteExaminers}
          createSpreadsheet={() => handleCreateSpreadsheet()}
          selection={selection.map((id) => id as string)}
          filters={filters}
          setFilters={setFilters}
          semesters={semesters}
          setQuery={setQuery}
          query={query}
        />
      </div>
      <DataGrid
        rows={examiners}
        columns={columns(history)}
        checkboxSelection
        rowSelection
        rowSelectionModel={selection}
        onRowSelectionModelChange={(selection) => setSelection(selection)}
      />
    </div>
  );
};

export default ExaminersComponent;
