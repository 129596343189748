import React from "react";
import { styled } from '@mui/material/styles';
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";

const PREFIX = 'UserComponent';

const classes = {
  paper: `${PREFIX}-paper`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(1),
  }
}));

const validationSchema = yup.object({
  name: yup.string(),
  email: yup.string().email(),
  emailVerified: yup.boolean(),
});

type UserComponentProps = {
  handleDelete: (user: User) => void;
  handleSubmit: (user: User) => void;
  initialValues: User;
};
const UserComponent: React.FC<UserComponentProps> = ({
  handleDelete,
  handleSubmit,
  initialValues,
}) => {


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <Root onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Bruger:</Typography>
                <TextField
                  name="displayName"
                  value={formik.values.displayName}
                  className={classes.textField}
                  label="Navn"
                  placeholder={"Navn"}
                  type="text"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                  helperText={
                    formik.touched.displayName && formik.errors.displayName
                  }
                  disabled
                />
                <TextField
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  className={classes.textField}
                  onChange={formik.handleChange}
                  placeholder={"Email"}
                  type="text"
                  disabled
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.emailVerified}
                      onChange={formik.handleChange}
                      name="emailVerified"
                      disabled
                    />
                  }
                  label="Email verificeret"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1">Rettigheder:</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isReader}
                      onChange={formik.handleChange}
                      name="isReader"
                    />
                  }
                  label="Kan se (læse) data"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isAdmin}
                      onChange={formik.handleChange}
                      name="isAdmin"
                    />
                  }
                  label="Kan se (læse) og redigere data"
                />
                {/* Dont allow to change this value client side. Only a firebase admin can change this */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isSuperAdmin}
                      onChange={formik.handleChange}
                      name="isSuperAdmin"
                      disabled
                    />
                  }
                  label="Kan se (læse) og redigere data samt redigere brugere"
                />
              </Grid>
              {!formik.values.emailVerified && (
                <Grid item xs={10}>
                  <Typography variant="caption">
                    Du kan ikke ændre brugeren før end at denne har verificeret
                    sin email. Venligst kontakt brugeren først.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={10}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={!formik.values.emailVerified}
                  type="submit"
                >
                  Opdater
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={() => formik.resetForm()}
                >
                  Fortryd
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(formik.values)}
                >
                  Slet
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
};

export default UserComponent;
