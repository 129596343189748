import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Field } from "formik";
import { useFirestore } from "react-redux-firebase";
import * as Yup from "yup";

import Grid from "@mui/material/Grid";
import {
  DateField,
  CheckboxGroupField,
  SelectField,
  GenderField,
  RadioField,
  TextField,
} from "../../components";
import { referrers } from "../../helpers/referrers";

const PREFIX = "NewApplicantDialog";

const classes = {
  root: `${PREFIX}-root`,
  note: `${PREFIX}-note`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.note}`]: {
    backgroundColor: theme.palette.background.default,
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().label("Navn").min(2).required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  birthday: Yup.date().required(),
  dateSent: Yup.date().required(),
  gender: Yup.string().label("Køn").required(),
  note: Yup.string(),
  line: Yup.string(),
  origin: Yup.string(),
  startingYear: Yup.array().of(Yup.string()),
  referrer: Yup.string(),
});

type DialogProps = { open: boolean; setOpen: (open: boolean) => void };

export const NewApplicantDialog: React.FC<DialogProps> = React.memo(
  ({ open, setOpen }) => {
    const firestore = useFirestore();

    return (
      <StyledDialog
        className={classes.root}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            // Set a date that is reasonable for a new applicants latest birthday
            birthday: new Date("2004-01-01"),
            dateSent: new Date(),
            startingYear: [],
            gender: "",
            line: "",
            origin: "info-aften",
            referrer: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            // Submit the new applicant
            try {
              await firestore.collection("applicants").add(values);
              setOpen(false);
            } catch (error) {
              console.log(error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Ny ansøger</DialogTitle>
              <DialogContent>
                <DialogContentText>Opret ny ansøger</DialogContentText>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="name"
                      label="Navn*"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="email"
                      label="Email*"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="phone"
                      label="Telefon*"
                      fullWidth
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GenderField name="gender" label="Køn" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateField name="dateSent" label="Indsendt d.*" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateField
                      name="birthday"
                      label="Fødselsdag*"
                      maxDate={new Date("2004-01-01")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxGroupField
                      name="startingYear"
                      label="Valgte startdatoer:"
                      row
                      options={[
                        "2020",
                        "2021",
                        "2022",
                        "2023",
                        "2024",
                        "2025",
                      ].map((year) => ({ label: year, value: year }))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioField
                      row
                      name="line"
                      label="Tildel linie"
                      options={[
                        { value: "sport", label: "Sports" },
                        { value: "x-outdoor", label: "X-Outdoor" },
                        { value: "outdoor", label: "Friluft" },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectField
                      name="referrer"
                      label="Hørte om os"
                      options={referrers.map((r) => ({ value: r, label: r }))}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      className={classes.note}
                      name="note"
                      label="Note"
                      fullWidth
                      multiline
                      rows={6}
                      component={TextField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Annuller</Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Opret
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </StyledDialog>
    );
  }
);

export default NewApplicantDialog;
