import React from "react";
import { styled } from '@mui/material/styles';
import { withRouter } from "react-router-dom";
import { timeAgo } from "../../helpers/timeAgo";
import PropTypes from "prop-types";
import classnames from "classnames";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import blue from "@mui/material/colors/blue";

const PREFIX = 'NotificationCardComponent';

const classes = {
  card: `${PREFIX}-card`,
  cardUnread: `${PREFIX}-cardUnread`,
  cardRead: `${PREFIX}-cardRead`,
  avatar: `${PREFIX}-avatar`
};

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`&.${classes.card}`]: {
    maxWidth: 400,
    padding: 0,
    overflow: "initial"
  },

  [`&.${classes.cardUnread}`]: {
    backgroundColor: blue[100]
  },

  [`&.${classes.cardRead}`]: {
    backgroundColor: "inherit",
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shorter
    })
  },

  [`& .${classes.avatar}`]: {}
}));

const NotificationCardComponent = withRouter(
  ({ history, notification,  notificationRead, clearNotification }) => (
    <StyledCard
      className={classnames(classes.card, {
        [classes.cardUnread]: !notification.read,
        [classes.cardRead]: notification.read
      })}
    >
      <CardHeader
        avatar={
          <Avatar aria-label="applicant" className={classes.avatar}>
            {notification.type.toUpperCase().match(/\b(\w)/g)}
          </Avatar>
        }
        title={notification.title}
        subheader={timeAgo(notification.created_at)}
      />

      <CardContent>
        <Typography component="p">{notification.message}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        {notification.read ? (
          <IconButton onClick={() => clearNotification([notification.id])}>
            <ClearIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => notificationRead([notification.id])}>
            <DoneIcon />
          </IconButton>
        )}
        <Button
          color="primary"
          onClick={() => {
            if (!notification.read) {
              notificationRead([notification.id]);
            }
            notification.isDownload
              ? window.open(notification.link, "_blank")
              : history.push(notification.link);
          }}
        >
          {notification.actionTitle || "Gå"}
        </Button>
      </CardActions>
    </StyledCard>
  )
);

NotificationCardComponent.propTypes = {
  notification: PropTypes.object.isRequired,
  notificationRead: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired
};

export default (NotificationCardComponent);
