export interface IApplicant {
  id: string;
  name: string;
  dateSent: Date;
  contractSentDate: Date;
  contractReceivedTimestamp: Date | null;
  gender: string;
  startingYear: string[];
  sport?: boolean;
  outdoor?: boolean;
  xOutdoor: boolean;
  line: string;
  interviewer?: string;
  interviewScheduled: boolean;
  interviewDate: Date | null;
  admissionFeeReceived: boolean;
  semester: string;
  referrer: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  postcode: string;
  birthday: Date;
  education: string;
  motivation: string;
  note: string;
  origin: string;
  applicantSource: string;
  dischargeReasons: string[];
  textMessageTemplate: string;
  noShow: boolean;
  isArchived: boolean;
  isPurged: boolean;
  contractSent: boolean;
  contractReceived: boolean;
  sms: {
    status: number;
    message: string;
    dateCreated: Date;
  };
}

class Applicant {
  isProduction: boolean | undefined;
  isArchived: boolean;
  isPurged: boolean;
  contractSent: boolean;
  contractReceived: boolean;
  noShow: boolean;
  name: string;
  startingYear: any[];
  sport: boolean;
  outdoor: boolean;
  xOutdoor: boolean;
  interviewScheduled: boolean;
  admissionFeeReceived: boolean;
  interviewDateString: string;
  interviewDate: Date | undefined;
  contractReceivedDate: string;
  textMessageTemplate: string;
  interviewer: string;
  caller: string;
  line: string;
  semester: string;
  referrer: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  postcode: string;
  birthday: string;
  education: string;
  motivation: string;
  note: string;
  dischargeReasons: any[];
  origin: string;
  applicantSource: string;
  dateSent: Date | undefined;
  intraLink: string;
  id: string;
  applicantId: string;
  [key: string]: any;
  constructor(obj: any) {
    this.id = "";
    this.applicantId = "";
    this.isArchived = false;
    this.isPurged = false;
    this.contractSent = false;
    this.contractReceived = false;
    this.noShow = false;
    this.name = "";
    this.startingYear = [];
    this.sport = false;
    this.outdoor = false;
    this.xOutdoor = false;
    this.interviewScheduled = false;
    this.admissionFeeReceived = false;
    this.interviewDateString = "";
    this.interviewDate;
    this.contractReceivedDate = "";
    this.textMessageTemplate = "";
    this.interviewer = "";
    this.caller = "";
    this.line = "";
    this.semester = "";
    this.referrer = "";
    this.email = "";
    this.phone = "";
    this.street = "";
    this.city = "";
    this.postcode = "";
    this.birthday = "";
    this.education = "";
    this.motivation = "";
    this.intraLink = "";
    this.note = "";
    // `dischargeReason` is not the best gramatically but it matches the students prop and will transfer across nicely
    this.dischargeReasons = [];
    this.origin = "intra";
    this.applicantSource = "";
    // Overwrite default properties
    Object.assign(this, obj);
  }
}
export default Applicant;
