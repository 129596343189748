import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useHistory, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import FilterList from "./FilterList";
import StudentsActionsList from "./StudentsActionsList";

import Student from "../../Classes/Student";
import {
  parseLine,
  parseGender,
  parseDate,
  parseClass,
} from "../../helpers/parser";
import { lines } from "../../helpers/lines";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import StudentsPropMenu from "./Table/StudentsPropMenu";
import { toggleGradingDialog } from "../../actions/students";
import {
  AirplanemodeActive,
  DeleteOutline,
  FileCopy,
  SchoolOutlined,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";

const columns: GridColDef[] = [
  {
    field: "firstName",
    headerName: "Fornavn",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "lastName",
    headerName: "Efternavn",
    width: 150,
    sortable: true,
  },
  {
    field: "gender",
    headerName: "Køn",
    sortable: true,
    width: 110,
    valueGetter: (_, row) => parseGender(row.gender),
  },

  {
    field: "contractReceivedTimestamp",
    headerName: "Kontrakt modtaget",
    sortable: true,

    width: 160,
    valueGetter: (_, row) => parseDate(row.contractReceivedTimestamp),
  },
  {
    field: "class",
    headerName: "Hold",
    sortable: true,
    width: 160,
    valueGetter: (_, row) => parseClass(row.class),
  },
  {
    field: "contractSentDate",
    headerName: "Kontrakt afsendt",
    sortable: true,
    width: 160,
    valueGetter: (_, row) => parseDate(row.contractSentDate),
  },
  {
    field: "createdAt",
    headerName: "Oprettet",
    sortable: true,
    width: 160,
    valueGetter: (_, row) => parseDate(row.createdAt),
  },
  {
    field: "updatedAt",
    headerName: "Opdateret",
    sortable: true,
    width: 160,
    valueGetter: (_, row) => parseDate(row.updatedAt),
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    sortable: true,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "street",
    headerName: "Vejnavn",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "city",
    headerName: "By",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "postcode",
    headerName: "Post nr.",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "note",
    headerName: "Note",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "semester",
    headerName: "Semester",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "ssn",
    headerName: "CPR nr.",
    width: 150,
    sortable: true,
    editable: false,
  },
  {
    field: "contractReceived",
    headerName: "Kontrakt modtaget",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.contractReceived ? "Ja" : "Nej"),
  },
  {
    field: "contractSent",
    headerName: "Kontrakt afsendt",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.contractSent ? "Ja" : "Nej"),
  },
  {
    field: "createdInSU",
    headerName: "Oprettet i SU",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.createdInSU ? "Ja" : "Nej"),
  },
  {
    field: "discharged",
    headerName: "Udmeldt",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.discharged ? "Ja" : "Nej"),
  },
  {
    field: "infoLetter",
    headerName: "Informationsbref",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.infoLetter ? "Ja" : "Nej"),
  },
  {
    field: "introductionEmail",
    headerName: "Intro email",
    width: 150,
    sortable: true,
    editable: false,
    valueGetter: (_, row) => (row.introductionEmail ? "Ja" : "Nej"),
  },
];

const PREFIX = "StudentsComponent";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  sideBar: `${PREFIX}-sideBar`,
  row: `${PREFIX}-row`,
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: 16,
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.appBar}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.sideBar}`]: {
    marginTop: 20,
  },

  [`& .${classes.row}`]: {
    display: "flex",
    justifyContent: "center",
  },
  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },
  [`& .${classes.card}`]: {
    minWidth: 275,
  },

  [`& .${classes.title}`]: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },

  [`& .${classes.pos}`]: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
  },
}));

interface Props {
  isFetching: boolean;
  students: Student[];
  handleDeleteItems: (ids: string[]) => void;
  handleSendMessages: (ids: string[]) => void;
  handleCreateSpreadsheet: (students: Student[]) => void;
  handleCreateTravelInfo: (students: Student[]) => void;
  handleCreateCertificateRequest: (ids: string[]) => void;
  handleGetClassImages: () => void;
  handleSetGraduated: (date: Date, ids: string[]) => void;
  handleSetProp: (props: any, ids: string[]) => void;
  fetchStudents: (query: any) => void;
}

const StudentsComponent: React.FC<Props> = ({
  isFetching,
  students,
  handleDeleteItems,
  handleSendMessages,
  handleCreateSpreadsheet,
  handleCreateTravelInfo,
  handleCreateCertificateRequest,
  handleGetClassImages,
  handleSetGraduated,
  handleSetProp,
  fetchStudents,
}) => {
  const [checkedValues, setCheckedValues] = React.useState<{
    [key: string]: any;
  }>({});
  const [line, setLine] = useState(lines[0]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const { semester }: { semester: string } = useParams();
  useEffect(() => {
    performRefetch();
  }, [checkedValues, line, selectedClass]);

  const handleCheckboxChange = (name: string) => (event: any) => {
    if (name === "gender") {
      // The gender attribute is a string
      setCheckedValues({ [name]: event });
    } else if (name === "class") {
      // The class attribute is a string
      setCheckedValues({ [name]: event });
    } else {
      setCheckedValues({ [name]: event.target.checked });
    }
  };

  const removeKey = (key: string) => {
    if (!(key in checkedValues)) return;

    setCheckedValues((current) => {
      const copy = { ...current };
      delete copy[key];
      return copy;
    });
  };

  const performRefetch = () => {
    const queries: { [key: string]: any } = {
      ...checkedValues,
      semester,
      line,
    };

    if (selectedClass !== "") {
      queries["class"] = selectedClass;
    }

    fetchStudents(queries);
  };

  const handleClassSelect = (className: string) => {
    setSelectedClass(className);
  };

  const handleCreateNewStudent = () => {
    history.push(`/semesters/${semester}/student/new`);
  };

  const handleToggleGradingDialog = () => {
    dispatch(toggleGradingDialog());
  };

  return (
    <Root>
      <Paper className={classes.root} elevation={4}>
        <Grid container>
          <Grid item xs={3} className={classes.sideBar}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h5">{students.length} Studerende</Typography>
            </Box>

            <Box sx={{ padding: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="line-select">Linie</InputLabel>
                <Select
                  labelId="line-select"
                  value={line}
                  label="Vælg linie"
                  onChange={(event) => setLine(event.target.value)}
                >
                  {lines.map((line) => (
                    <MenuItem key={line} value={line}>
                      {parseLine(line)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <StudentsActionsList
              handleCreateNewStudent={handleCreateNewStudent}
              handleGetClassImages={handleGetClassImages}
            />
            <FilterList
              handleChange={handleCheckboxChange}
              removeKey={removeKey}
              selectedLine={line}
            />
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ height: 800, width: "100%" }}>
              <Stack direction="row" spacing={2}>
                <StudentsPropMenu
                  handleSetProp={(prop) =>
                    handleSetProp(prop, selectionModel as string[])
                  }
                />
                <Tooltip title="Sæt dimmiteret">
                  <IconButton
                    color="primary"
                    className={classes.button}
                    onClick={() => handleToggleGradingDialog()}
                    size="large"
                  >
                    <SchoolOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Opret rejseinfo spreadsheet">
                  <IconButton
                    color="primary"
                    className={classes.button}
                    aria-label="Travel info"
                    onClick={() =>
                      handleCreateTravelInfo(
                        students.filter((s) => selectionModel.includes(s.id))
                      )
                    }
                    size="large"
                  >
                    <AirplanemodeActive />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Instruktør beviser">
                  <IconButton
                    color="primary"
                    className={classes.button}
                    aria-label="Certificate Request"
                    onClick={() =>
                      handleCreateCertificateRequest(selectionModel as string[])
                    }
                    size="large"
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Slet">
                  <IconButton
                    color="primary"
                    className={classes.button}
                    aria-label="Delete"
                    onClick={() =>
                      handleDeleteItems(selectionModel as string[])
                    }
                    size="large"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              </Stack>
              <DataGrid
                rows={students}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      contractSentDate: false,
                      createdAt: false,
                      updatedAt: false,
                    },
                  },
                }}
                columns={columns}
                autoPageSize
                pageSizeOptions={[20, 40, 60]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={(val) => console.log(val.id)}
                slots={{ toolbar: GridToolbar }}
                onRowSelectionModelChange={(
                  newSelectionModel: GridRowSelectionModel
                ) => {
                  setSelectionModel(newSelectionModel);
                }}
                rowSelectionModel={selectionModel}
                onCellClick={(params) => {
                  if (params.colDef.field == "firstName") {
                    history.push(
                      `/semesters/${semester}/student/${params.row.id}`
                    );
                  }
                }}
              />
            </Box>
            {/* <SetGraduatedDialog
              handleSetGraduated={(date: Date) =>
                handleSetGraduated(date, selectionModel as string[])
              }
            /> */}
          </Grid>
        </Grid>
      </Paper>
    </Root>
  );
};

export default StudentsComponent;
