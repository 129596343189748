import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const PREFIX = 'ArchivedSemestersDialog';

const classes = {
  root: `${PREFIX}-root`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: { maxHeight: 800 }
}));

class ArchivedSemesterDialog extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value,
    };
  }

  handleCancel = () => {
    this.props.onClose();
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { semesters, history,  onClose, open } = this.props;

    return (
      <StyledDialog
        disableEscapeKeyDown
        maxWidth="sm"
        className={classes.root}
        aria-labelledby="confirmation-dialog-title"
        onClose={onClose}
        open={open}>
        <DialogTitle id="confirmation-dialog-title">Vælg Semester</DialogTitle>
        <DialogContent>
          <List component="nav">
            {semesters.map((semester) => (
              <ListItem
                button
                key={semester.id}
                onClick={() => {
                  onClose();
                  history.push(`/semesters/${semester.title}`);
                }}
              >
                <ListItemText primary={semester} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Luk
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}

ArchivedSemesterDialog.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string,
};

export default withRouter((ArchivedSemesterDialog));
