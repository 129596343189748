import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { ExamsComponent } from "../../components";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useSubjects from "../../hooks/useSubjects";
import useSemesters from "../../hooks/useSemesters";
import useStudents from "../../hooks/useStudents";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import Student from "../../Classes/Student";
import { parseClass } from "../../helpers/parser";
import InputLabel from "@mui/material/InputLabel";
import { useFirestore } from "react-redux-firebase";

const PREFIX = "ExamsContainer";

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  select: `${PREFIX}-select`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.select}`]: {
    minWidth: 200,
  },
})) as typeof FormControl;

const ExamsContainer: React.FC = () => {
  const [query, setQuery] = useState({ line: "sports", semester: "2022-2023" });
  const [subject, setSubject] = useState("");

  const semesters = useSemesters();
  const subjects = useSubjects({ setQuery, ...query });
  const students = useStudents({ ...query });

  const availableClasses = _.uniqBy(students, "class")
    .map((s) => s.class)
    .filter((c) => c !== "");
  const [selectedClass, setSelectedClass] = useState(availableClasses[0] || "");

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12}>
        <Paper className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              <ExamsComponent />
            </Grid>
            <Grid
              container
              alignItems="center"
              style={{ marginTop: 16, marginBottom: 16 }}>
              <Grid item xs={12} md={4}>
                <StyledFormControl component="fieldset">
                  <FormLabel component="legend">Linie</FormLabel>
                  <RadioGroup
                    aria-label="linie"
                    row
                    name="lines"
                    value={query.line}
                    onChange={(e) =>
                      setQuery({ ...query, line: e.target.value })
                    }>
                    <FormControlLabel
                      value="sport"
                      control={<Radio />}
                      label="Sports"
                    />
                    <FormControlLabel
                      value="outdoor"
                      control={<Radio />}
                      label="Friluft"
                    />
                    <FormControlLabel
                      value="xOutdoor"
                      control={<Radio />}
                      label="X-Outdoor"
                    />
                  </RadioGroup>
                </StyledFormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <Select
                  className={classes.select}
                  value={query.semester}
                  onChange={(e) =>
                    setQuery({ ...query, semester: e.target.value as string })
                  }>
                  {semesters.map((semester) => (
                    <MenuItem value={semester.title} key={semester.title}>
                      {semester.title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <Select
                  className={classes.select}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value as string)}>
                  <MenuItem value="">Vælg</MenuItem>
                  {subjects.map((subject) => (
                    <MenuItem value={subject.id} key={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="center"
              style={{ marginTop: 8 }}>
              {availableClasses.length > 0 && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Hold</FormLabel>
                  <RadioGroup
                    aria-label="classes"
                    name="classes"
                    row
                    value={selectedClass}
                    onChange={(e) =>
                      setSelectedClass(e.target.value as string)
                    }>
                    {availableClasses.map((c) => (
                      <FormControlLabel
                        value={c}
                        key={c}
                        control={<Radio />}
                        label={parseClass(c)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            {_.sortBy(students, ["firstName", "lastName"])
              .filter((s) => s.class === selectedClass)
              .map((student, idx) => {
                const currentSubject = subjects.filter(
                  (s) => s.id === subject
                )[0];
                console.log(currentSubject);
                return (
                  <Grid key={student.id} item xs={12}>
                    <StudentRow
                      idx={idx}
                      type={currentSubject?.exam.type}
                      subjectId={currentSubject?.id}
                      student={student}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Button variant="contained" color="primary">
                Gem
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

type StudentRowProps = {
  idx: number;
  type: ExamType;
  subjectId: string;
  student: Student;
};

const StudentRow: React.FC<StudentRowProps> = ({
  idx,
  type,
  subjectId,
  student,
}) => {
  const [value, setValue] = React.useState("");

  const rowColor = idx % 2 == 0 ? "#e9e9e9" : "#fff";
  const firestore = useFirestore();

  const updateStudent = async (value: string) => {
    setValue(value);

    const studentRef = firestore
      .collection("students")
      .doc(student.id)
      .collection("grades")
      .doc(subjectId);

    try {
      await studentRef.set({ value, type, subjectId }, { merge: true });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const examTypeComponent = () => {
    switch (type) {
      case "graded":
        return (
          <GradedForm
            value={value}
            setValue={(value) => updateStudent(value)}
          />
        );
      case "pass_fail":
        return (
          <PassFailForm
            value={value}
            setValue={(value) => updateStudent(value)}
          />
        );
      case "attendance":
        return (
          <AttendanceForm
            value={value}
            setValue={(value) => updateStudent(value)}
          />
        );
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid
        item
        xs={12}
        md={6}
        sx={{ background: rowColor }}
        style={{ padding: 4 }}>
        <Typography variant="body1">
          {student.firstName} {student.lastName}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center">
        <Grid item xs={12} sx={{ background: rowColor }}>
          {examTypeComponent()}
        </Grid>
      </Grid>
    </Grid>
  );
};
type StudentRowFromProps = {
  value: string;
  setValue: (value: string) => void;
};
const AttendanceForm: React.FC<StudentRowFromProps> = ({ value, setValue }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="attendance"
        name="attendance"
        value={value}
        row
        onChange={(e) => setValue(e.target.value)}>
        <FormControlLabel value="attended" control={<Radio />} label="Deltog" />
        <FormControlLabel
          value="no_attendance"
          control={<Radio />}
          label="Deltog ikke"
        />
      </RadioGroup>
    </FormControl>
  );
};

const PassFailForm: React.FC<StudentRowFromProps> = ({ value, setValue }) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="pass_fail"
        name="pass_fail"
        value={value}
        row
        onChange={(e) => setValue(e.target.value)}>
        <FormControlLabel value="pass" control={<Radio />} label="Bestået" />
        <FormControlLabel
          value="fail"
          control={<Radio />}
          label="Ikke bestået"
        />
      </RadioGroup>
    </FormControl>
  );
};

const GradedForm: React.FC<StudentRowFromProps> = ({ value, setValue }) => {
  return (
    <FormControl style={{ minWidth: 150 }}>
      <InputLabel id="character-label">Karakter</InputLabel>
      <Select
        labelId="character-label"
        id="character-select"
        value={value}
        onChange={(e) => setValue(e.target.value as string)}>
        {["-03", "00", "02", "4", "7", "10", "12"].map((grade) => (
          <MenuItem value={grade} key={grade}>
            {grade}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ExamsContainer;
