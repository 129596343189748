import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useFirestoreConnect,
  WhereOptions,
  useFirestore,
} from "react-redux-firebase";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { deleteApplicants } from "./actions/applicants";
import { sendMessages, setRecipients } from "../actions/messages";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridRowParams,
  GridSlots,
  GridToolbarContainer,
  GridActionsCellItem,
  GridSlotProps,
} from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Select, MenuItem, Tooltip, IconButton, Chip } from "@mui/material";
import queries from "../helpers/applicantQueries";
import NoteIcon from "@mui/icons-material/Note";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MessageIcon from "@mui/icons-material/Message";
import AddIcon from "@mui/icons-material/Add";

import NewApplicantDialog from "./components/NewApplicantDialog";
import { parseLine } from "../helpers/parser";
import { AppState } from "../reducers";
import { interviewers } from "../helpers/interviewers";
import { Query } from "../helpers/applicantQueries";
import colors from "../helpers/colors";
import { getFirestore } from "redux-firestore";
import Applicant from "./Applicant";

const columns = (query: Query, history: any): GridColDef[] => [
  {
    field: "actions",
    type: "actions",
    getActions: (params: GridRowParams) => [
      <GridActionsCellItem
        icon={<VisibilityIcon />}
        onClick={() => {
          history.push(`/applicants/${params.row.id}`);
        }}
        label="Delete"
      />,
    ],
  },
  {
    field: "name",
    headerName: "Navn",
    type: "string",
    width: 210,
    editable: false,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "dateSent",
    headerName: "Modatget",
    type: "date",
    width: 180,
    editable: true,
    valueGetter: (value, row) => {
      if (!row.dateSent) return value;

      return row.dateSent.toDate();
    },
  },
  {
    field: "phone",
    headerName: "Telefon",
    type: "string",
    width: 220,
    editable: true,
  },
  {
    field: "line",
    headerName: "Linie",
    type: "string",
    width: 150,
    editable: false,
    valueFormatter: (value) => parseLine(value),
    renderCell(params) {
      const lineColor = colors[params.row.line];

      return params.row.line ? (
        <Chip
          label={parseLine(params.row.line)}
          style={{ backgroundColor: lineColor?.hex }}
          variant="outlined"
        />
      ) : null;
    },
  },
  {
    field: "firstContactedTimestamp",
    headerName: "1.",
    type: "date",
    width: 150,
    editable: true,
    valueGetter: (value, row) => {
      if (!row.firstContactedTimestamp) return value;

      return row.firstContactedTimestamp.toDate();
    },
  },
  {
    field: "secondContactedTimestamp",
    headerName: "2.",
    type: "date",
    width: 150,
    editable: true,
    valueGetter: (value, row) => {
      if (!row.secondContactedTimestamp) return value;
      return row.secondContactedTimestamp.toDate();
    },
  },
  {
    field: "thirdContactedTimestamp",
    headerName: "3.",
    type: "date",
    width: 150,
    editable: true,
    valueGetter: (value, row) => {
      if (!row.thirdContactedTimestamp) return value;
      return row.thirdContactedTimestamp.toDate();
    },
  },
  {
    field: "interviewDate",
    headerName: "Samtale Aftalt",
    type: "dateTime",
    width: 150,
    editable: true,
    valueGetter: (value, row) => {
      if (!row.interviewDate) return value;
      return row.interviewDate.toDate();
    },
  },
  {
    field: query === Query.AwaitingCall ? "caller" : "interviewer",
    headerName: query === Query.AwaitingCall ? "Ringes op af" : "Samtale med",
    type: "singleSelect",
    width: 150,
    editable: true,
    // valueFormatter: value => value.toUppercase()
    valueOptions: interviewers,
    valueFormatter: (value: string) => (value ?? "").toUpperCase(),
  },
  {
    field: "note",
    headerName: "Note",
    type: "string",
    width: 100,
    renderCell(params) {
      return (
        <Tooltip title={params.row.note}>
          <IconButton>
            <NoteIcon />
          </IconButton>
        </Tooltip>
      );
    },
  },
];

const getQuery = (query: Query, startYear: string): WhereOptions[] => {
  switch (query) {
    case Query.AwaitingCall:
      return [
        ["interviewScheduled", "==", false],
        ["contractSent", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.InterviewScheduled:
      return [
        ["interviewScheduled", "==", true],
        ["contractSent", "==", false],
        ["noShow", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.ContractSent:
      return [
        ["contractSent", "==", true],
        ["contractReceived", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.NoShow:
      return [
        ["interviewScheduled", "==", true],
        ["noShow", "==", true],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.Archived:
      return [
        ["isArchived", "==", true],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
  }
};

declare module "@mui/x-data-grid" {
  interface ToolbarPropsOverrides {
    selection: GridRowSelectionModel;
    startYear: string;
    createApplicant: () => void;
    setStartYear: (value: string) => void;
    query: { id: string };
    setQuery: (id: string) => void;
    handleDelete: () => void;
    handleSendMessages: () => void;
  }
}

function EditToolbar(props: GridSlotProps["toolbar"]) {
  const {
    startYear,
    setStartYear,
    query,
    setQuery,
    selection,
    createApplicant,
    handleSendMessages,
    handleDelete,
  } = props;

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={createApplicant}>
        Tilføj ansøger
      </Button>
      <Button
        color="primary"
        startIcon={<DeleteIcon />}
        disabled={selection.length < 1}
        onClick={handleDelete}
      >
        Slet ansøger(e)
      </Button>
      <Button
        color="primary"
        startIcon={<MessageIcon />}
        disabled={selection.length < 1}
        onClick={handleSendMessages}
      >
        Send besked(er)
      </Button>
      <Select
        value={startYear}
        onChange={(e) => setStartYear(e.target.value as string)}
        label={"Studiestart"}
        inputProps={{
          name: "startYear",
          id: "startYear",
        }}
      >
        <MenuItem value={"2019"}>Studiestart 2019</MenuItem>
        <MenuItem value={"2020"}>Studiestart 2020</MenuItem>
        <MenuItem value={"2021"}>Studiestart 2021</MenuItem>
        <MenuItem value={"2022"}>Studiestart 2022</MenuItem>
        <MenuItem value={"2023"}>Studiestart 2023</MenuItem>
        <MenuItem value={"2024"}>Studiestart 2024</MenuItem>
        <MenuItem value={"2025"}>Studiestart 2025</MenuItem>
        <MenuItem value={"2026"}>Studiestart 2026</MenuItem>
        <MenuItem value={"2027"}>Studiestart 2027</MenuItem>
      </Select>
      <Select
        onChange={(e) => setQuery(e.target.value as string)}
        value={query.id}
      >
        {queries.map((query) => {
          return (
            <MenuItem key={query.id} value={query.id}>
              {query.name}
            </MenuItem>
          );
        })}
      </Select>
    </GridToolbarContainer>
  );
}

const ApplicantsContainer: React.FC = () => {
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const firestore = useFirestore();
  const [dialogOpen, setDialogOpen] = useState(false);
  const history = useHistory();

  const storage = window.localStorage.getItem("applicants-query");
  const initial = storage ? JSON.parse(storage) : null;
  const [query, setQuery] = useState<{
    name: string;
    id: string;
    where: Query;
  }>(initial ? initial.query : queries[0]);
  const [startYear, setStartYear] = useState<string>(
    initial ? initial.startYear : "2025"
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    window.localStorage.setItem(
      "applicants-query",
      JSON.stringify({ query, startYear })
    );
  }, [query, startYear]);

  useFirestoreConnect([
    {
      collection: "applicants",
      where: getQuery(query.where, startYear),
    },
  ]);
  // Sort by line by default
  // Slice the array as other wise it may throw an error
  const data = useSelector(
    (state: AppState) => state.firestore.ordered.applicants || []
  )
    .slice()
    .sort((a, b) => ("" + a.line).localeCompare(b.line));

  // const handleSetSelection = (selection: Selections) => {
  //   setSelection(selection);
  // };

  function getRandomNumber(): number {
    return Math.floor(Math.random() * (99999 - 1000 + 1)) + 1000;
  }
  const createApplicant = async () => {
    const ref = firestore.collection("applicants").doc();
    const app = new Applicant({
      id: ref.id,
      name: `Ansøger #${getRandomNumber()}`,
      dateSent: firestore.Timestamp.serverTimestamp,
      origin: "intra",
      startingYear: [startYear],
    });
    try {
      await ref.set(JSON.parse(JSON.stringify(app)));
      console.log(ref.id);
    } catch (error) {
      console.error(error);
    }
  };

  const sendMessage = () => {
    // Remove any unnecessary props
    const recipients = data
      .filter((d) => selection.includes(d.id))
      .map((recipient) => {
        return _.pick(recipient, ["name", "phone", "email", "id"]);
      })
      .map((recipient) => {
        return {
          ...recipient,
          collection: "applicants",
          docId: recipient.id,
          status: "not_submitted",
        };
      });

    // And send the recipients to the redux store, this will automatically change the route to /messages as well
    // this.props.setRecipients(recipientsArray);
    reduxDispatch(setRecipients(recipients));
  };

  const handleDeleteApplicants = () => {
    if (
      window.confirm(
        `Er du sikker på du vil slette ${selection.length} ansøger(e)`
      )
    ) {
      // Map the array of applicants to ids
      reduxDispatch(deleteApplicants(selection.map((id) => id as string)));
    }
  };

  const handleSetQuery = (value: string) => {
    const newQuery = queries.filter((q) => q.id === value)[0];
    setQuery(newQuery);
  };

  const updateApplicant = async (updated: any) => {
    const ref = firestore.collection("applicants").doc(updated.id);
    console.log(updated);
    try {
      await ref.update(updated);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container sx={{ background: "white" }}>
      <NewApplicantDialog open={dialogOpen} setOpen={setDialogOpen} />

      <DataGrid
        rows={data}
        columns={columns(query.where, history)}
        checkboxSelection
        processRowUpdate={(updated, _) => {
          updateApplicant(updated);
        }}
        onProcessRowUpdateError={(error) => {
          console.error(error);
        }}
        rowSelectionModel={selection}
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelection(newSelectionModel)
        }
        disableRowSelectionOnClick
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: {
            startYear,
            createApplicant,
            setStartYear,
            query,
            setQuery: handleSetQuery,
            selection,
            handleDelete: handleDeleteApplicants,
            handleSendMessages: sendMessage,
          },
        }}
      />
    </Container>
  );
};

export default ApplicantsContainer;
