import React, { useState } from "react";
import useSemesters from "../hooks/useSemesters";
import Header from "./Components/Header";
import SubjectsTable from "./Components/SubjectsTable";
import NewSubjectDialog from "./NewSubjectDialog";
import Grid from "@mui/material/Grid";
import { useFirestore } from "react-redux-firebase";
import { openSnackbar } from "../actions/snackbar";
import { useDispatch } from "react-redux";

type SubjectsComponentProps = {
  id: string;
};

const SubjectsComponent: React.FunctionComponent<SubjectsComponentProps> =
  () => {
    const [open, setOpen] = useState(false);
    const semesters = useSemesters();
    const [semester, setSemester] = useState("2022-2023");
    const [selection, setSelection] = React.useState<Subject[]>([]);
    const firestore = useFirestore();
    const dispatch = useDispatch();

    const deleteSubjects = async (ids: string[]) => {
      const batch = firestore.batch();

      if (window.confirm(`Er du sikker på du vil slette ${ids.length} fag?`)) {
        for (const id of ids) {
          batch.delete(firestore.collection("subjects").doc(id));
        }

        try {
          await batch.commit();
          dispatch(openSnackbar(`✅ ${ids.length} fag blev slettet`));
        } catch (error) {
          dispatch(openSnackbar(`🛑 Kunne ikke slette fag`));
        }
      }
    };

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <NewSubjectDialog
            open={open}
            setOpen={setOpen}
            semesters={semesters}
          />
        </Grid>
        <Grid item xs={12}>
          <Header
            semesters={semesters}
            semester={semester}
            setSemester={setSemester}
            newSubject={() => setOpen(true)}
            deleteSubjects={deleteSubjects}
            selection={selection}
          />
        </Grid>
        <Grid item xs={12}>
          <SubjectsTable setSelection={setSelection} semester={semester} />
        </Grid>
      </Grid>
    );
  };

export default SubjectsComponent;
