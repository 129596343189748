import React from "react";
import PropTypes from "prop-types";
import keycode from "keycode";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import PersonsTableHead from "./PersonsTableHead";
import PersonsTableToolbar from "./PersonsTableToolbar";
import PersonsTableBody from "./PersonsTableBody";
import PersonsTableFooter from "./PersonsTableFooter";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { setRecipients } from "../../actions/messages";

class PersonsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.order || "asc",
      orderBy: props.sortProp || "firstName",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      filters: {}
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.items
        .sort((a, b) =>
          this.state.order === "desc"
            ? b[this.props.sortProp || ""] < a[this.props.sortProp || ""]
              ? -1
              : 1
            : a[this.props.sortProp || ""] < b[this.props.sortProp || ""]
            ? -1
            : 1
        )
        .sort(this.groupByLineSortDescriptor)
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        data: nextProps.items
          .sort((a, b) =>
            this.state.order === "desc"
              ? b[nextProps.sortProp || ""] < a[nextProps.sortProp || ""]
                ? -1
                : 1
              : a[nextProps.sortProp || ""] < b[nextProps.sortProp || ""]
              ? -1
              : 1
          )
          .sort(this.groupByLineSortDescriptor)
      });
    }
  }

  groupByLineSortDescriptor = (a, b) => (a["line"] < b["line"] ? -1 : 1);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.state.data
            .sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            .sort(this.groupByLineSortDescriptor)
        : this.state.data
            .sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
            .sort(this.groupByLineSortDescriptor);

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(s => s.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleBackButtonClick = () => {
    const { page } = this.state;
    if (page === 0) return;
    this.handleChangePage(null, page - 1);
  };

  handleNextButtonClick = () => {
    const { page, rowsPerPage, data } = this.state;
    if (page >= Math.ceil(data.length / rowsPerPage) - 1) return;
    this.handleChangePage(null, page + 1);
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleSendMessages = recipients => {
    const filtered = [];
    // Filter the two arrays against eachother
    // 'data' contains all the objects, recipients is just an array of IDs
    this.state.data.filter(d => {
      recipients.filter(r => {
        if (d.id === r) {
          filtered.push(d);
          // If the object schema is by firstName / lastName then we need to set it to just a name prop
          d.name = d.name ? d.name : `${d.firstName} ${d.lastName}`;
        }
        return false;
      });
      return false;
    });

    // Remove any unnecessary props
    const recipientsArray = filtered
      .map(recipient => {
        return _.pick(recipient, ["name", "phone", "email", "id"]);
      })
      .map(recipient => {
        return {
          ...recipient,
          // Because a private contact exists under a subcollection /contacts/id/private/profile we need to append that to the path
          docId: `${recipient.id}/private/profile`,
          collection: "contacts",
          status: "not_submitted"
        };
      });

    // And send the recipients to the redux store, this will automatically change the route to /messages as well
    this.props.setRecipients(recipientsArray);
  };

  render() {
    const {
      handleDeleteItems,
      handleCreateSheet,
      canonicalName,
      columnData
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;

    return (
      <div>
        <PersonsTableToolbar
          numSelected={selected.length}
          count={data.length}
          canonicalName={canonicalName}
          handleDeleteItems={() => handleDeleteItems(selected)}
          handleSendMessages={() => this.handleSendMessages(selected)}
          handleCreateSheet={() => handleCreateSheet(selected)}
        />
        <div>
          <div style={{ float: "right" }}>
            <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} size="large">
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={this.handleNextButtonClick}
              disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
              size="large">
              <KeyboardArrowRight />
            </IconButton>
          </div>
          <Table>
            <PersonsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              columnData={columnData}
              customize={this.props.customize}
            />
            <PersonsTableBody
              data={data}
              page={page}
              rowsPerPage={rowsPerPage}
              columnData={columnData}
              customize={this.props.customize}
              handleClick={this.handleClick}
              handleKeyDown={this.handleKeyDown}
              isSelectedFunc={this.isSelected}
              onSelectChange={this.props.onSelectChange}
            />
            <PersonsTableFooter
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
            />
          </Table>
        </div>
      </div>
    );
  }
}

PersonsTable.propTypes = {
  items: PropTypes.array.isRequired,
  canonicalName: PropTypes.string.isRequired,
  columnData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCreateSheet: PropTypes.func,
  rowsToDisplayDidChange: PropTypes.func,
  sortProp: PropTypes.string,
  onSelectChange: PropTypes.func,
  customize: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    setRecipients: recipients => dispatch(setRecipients(recipients))
  };
};

export default connect(null, mapDispatchToProps)(PersonsTable);
