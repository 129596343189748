import React from "react";
import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const PREFIX = 'NumberCard';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`
};

const StyledCard = styled(Card)({
  [`&.${classes.root}`]: {
    minWidth: 150,
  },

  [`& .${classes.title}`]: {
    fontSize: 14,
  },
  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },
});

type CardProps = {
  count: number;
  title: string;
  icon: React.ReactNode;
};
const NumberCard: React.FC<CardProps> = ({ count, title, icon }) => {

  return (
    <StyledCard className={classes.root}>
      <CardHeader avatar={icon} title={title} />
      <CardContent>
        <Typography variant="h2" component="p">
          {count}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default NumberCard;
