import React from "react";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import green from "@mui/material/colors/green";

const PREFIX = "LoadableButton";

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  buttonSuccess: `${PREFIX}-buttonSuccess`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "inline-flex",
    alignItems: "center",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.buttonSuccess}`]: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  [`& .${classes.buttonProgress}`]: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
}));

interface Props {
  loading: boolean;
  disabled: boolean;
  success: boolean;
  children: React.ReactNode;
  other: any;
}

const LoadableButton: React.FC<Props> = ({
  loading,
  disabled,
  success,
  children,
  ...other
}) => {
  const buttonClassname = classNames({
    [classes.buttonSuccess]: success,
  });
  return (
    <Root className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          disabled={loading || disabled}
          className={buttonClassname}
          {...other}>
          {children}
        </Button>
        {loading && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </div>
    </Root>
  );
};

export default LoadableButton;
