import React from "react";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const PREFIX = 'ExamsComponent';

const classes = {
  root: `${PREFIX}-root`,
  leftIcon: `${PREFIX}-leftIcon`,
  button: `${PREFIX}-button`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2)
  },

  [`& .${classes.leftIcon}`]: {
    marginRight: theme.spacing(1)
  },

  [`& .${classes.button}`]: { marginLeft: theme.spacing(2) }
}));

const ExamsComponent = ({ }) => (
  <StyledGrid container>
    <Grid item xs={12} md={8}>
      <Typography variant="h5">Eksamensresultater</Typography>
      <Typography>{`
          Her kan du oprette eksamens fag og tildele karakterer til studerende for en given årgang og hold
        `}</Typography>
    </Grid>
    <Grid item xs={12} md={8} />
  </StyledGrid>
);

export default (ExamsComponent);
