import { storage, db, firebaseAuth } from "../helpers/firebaseHelper";
import { openSnackbar } from "./snackbar";
import {
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCEEDED,
  FILE_UPLOAD_FAILED,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_SUCCEEDED,
  FILES_UPLOAD_ERROR,
  FETCHING_FILES_FOR_COLLECTION,
  FETCH_FILES_FOR_COLLECTION_SUCCESS,
  FETCH_FILES_FOR_COLLECTION_ERROR,
  DELETING_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from "../constants";

export function fileUploadProgress(p) {
  return {
    type: FILE_UPLOAD_PROGRESS,
    progress: p,
  };
}

export function fileUploadSucceeded(downloadURL) {
  return {
    type: FILE_UPLOAD_SUCCEEDED,
    downloadURL,
  };
}

export function fileUploadFailed(e) {
  return {
    type: FILE_UPLOAD_FAILED,
    error: e,
  };
}

export function filesUploading(filesRemaining, progress = 0) {
  return {
    type: FILES_UPLOAD_PROGRESS,
    filesRemaining,
    progress,
  };
}

export function filesUploadSuccess() {
  return {
    type: FILES_UPLOAD_SUCCEEDED,
  };
}

export function filesUploadError(e) {
  return {
    type: FILES_UPLOAD_ERROR,
    error: e,
  };
}

export function fetchingFilesForCollection() {
  return {
    type: FETCHING_FILES_FOR_COLLECTION,
  };
}

export function fetchFilesForCollectionSuccess(files) {
  return {
    type: FETCH_FILES_FOR_COLLECTION_SUCCESS,
    files,
  };
}

export function fetchFilesForCollectionError(e) {
  return {
    type: FETCH_FILES_FOR_COLLECTION_ERROR,
    error: e,
  };
}

export function deletingFile() {
  return {
    type: DELETING_FILE,
  };
}

export function deleteFileSuccess() {
  return {
    type: DELETE_FILE_SUCCESS,
  };
}

export function deleteFileError(e) {
  return {
    type: DELETE_FILE_ERROR,
    error: e,
  };
}

export function uploadFile(fullPath, file) {
  return async (dispatch) => {
    dispatch(fileUploadProgress(0));
    const user = firebaseAuth().currentUser;

    const uploadTask = storage
      .ref(fullPath)
      .put(file, { customMetadata: { uploadedBy: user.uid } });
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch(fileUploadProgress(progress));
      },
      (error) => dispatch(fileUploadFailed(error)),
      async () => {
        try {
          console.log(uploadTask.snapshot);

          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          dispatch(fileUploadSucceeded(downloadURL));
        } catch (e) {
          dispatch(
            openSnackbar(`🛑 Kunne ikke uploade dokument: ${e.message}`)
          );
          dispatch(fileUploadFailed(e));
        }
      }
    );
  };
}

export function uploadFiles(collection, itemId, type, files) {
  return async (dispatch) => {
    const filesToUpload = files.length;
    let currentlyUploaded = 0;
    dispatch(filesUploading(filesToUpload));
    const user = firebaseAuth().currentUser;
    const storageRef = storage.ref(collection).child(itemId).child("documents");

    files.map(async (file) => {
      try {
        await storageRef
          .child(type)
          .child(file.name)
          .put(file, { customMetadata: { uploadedBy: user.uid } });
        currentlyUploaded += 1;
        if (currentlyUploaded === filesToUpload) {
          dispatch(filesUploadSuccess());
        } else {
          const filesRemaining = filesToUpload - currentlyUploaded;
          const progress = (currentlyUploaded / filesToUpload) * 100;
          dispatch(filesUploading(filesRemaining, progress));
        }
      } catch (e) {
        dispatch(filesUploadError(e));
        dispatch(openSnackbar(`🛑 Kunne ikke uploade dokumenter: ${e}`));
      }
    });
  };
}
let unsubscribe = () => {};
export function fetchFilesForCollection(collection, id, cb) {
  return async (dispatch) => {
    dispatch(fetchingFilesForCollection());
    if (!id) {
      dispatch(fetchFilesForCollectionError(new Error("No ID provided")));
      return;
    }
    const baseRef = db.collection(collection).doc(id).collection("files");
    try {
      unsubscribe = baseRef.onSnapshot(
        (querySnap) => {
          const files = getFilesFromQuery(querySnap);
          dispatch(fetchFilesForCollectionSuccess(files));
        },
        (e) => {
          dispatch(openSnackbar(`🛑 Kunne ikke hente dokumenter: ${e}`));
          dispatch(fetchFilesForCollectionError(e));
        }
      );
    } catch (e) {
      dispatch(openSnackbar(`🛑 Kunne ikke hente dokumenter: ${e}`));
      dispatch(fetchFilesForCollectionError(e));
    }
  };
}
export function unsubscribeFilesListener() {
  unsubscribe();
}

export function deleteFile(file) {
  return async (dispatch) => {
    dispatch(deletingFile());
    const ref = db.collection("actions").doc();
    try {
      await ref.set({
        actionType: "deleteFile",
        file: file,
        status: "pending",
      });
      dispatch(deleteFileSuccess());
      const detach = ref.onSnapshot((doc) => {
        const status = doc.data().status;
        if (status === "success") {
          dispatch(openSnackbar(`✅ ${doc.data().file.fileName} blev slettet`));
          dispatch(deleteFileSuccess());
          detach();
        } else if (status === "error") {
          dispatch(deleteFileError({}));
          dispatch(openSnackbar(`🛑 Filen kunne ikke slettes: ${doc.data()}`));
          detach();
        }
      });
    } catch (e) {
      dispatch(openSnackbar(`🛑 Filen kunne ikke slettes: ${e.error}`));
      dispatch(deleteFileError(e));
    }
  };
}

//Helpers
function getFilesFromQuery(query) {
  const files = {
    examDocs: [],
    meritDocs: [],
    medicalDocs: [],
    importantDocs: [],
    files: [],
  };
  query.forEach((doc) => {
    if (!doc.exists) {
      return;
    }
    const file = doc.data();

    file["fileName"] = doc.id;
    // Some files has an associated documentType (see `files` object above)
    // if the document type doesn't exist or if the document type differs
    // from what's above, use `files` as a bucket document type
    if (files[doc.data().documentType]) {
      files[doc.data().documentType].push(file);
    } else {
      files["files"].push(file);
    }
  });
  return files;
}
