import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useFirestore } from "react-redux-firebase";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { parseLine } from "../../helpers/parser";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import useSubjects from "../../hooks/useSubjects";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";

const PREFIX = 'SubjectsTransferList';

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  cardHeader: `${PREFIX}-cardHeader`,
  button: `${PREFIX}-button`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  radio: `${PREFIX}-radio`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: "auto",
  },

  [`& .${classes.list}`]: {
    width: 400,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },

  [`& .${classes.cardHeader}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(0.5, 0),
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.radio}`]: {
    marginTop: theme.spacing(2),
  }
}));

function useExaminerSubjects(id: string) {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const firestore = useFirestore();

  useEffect(() => {
    const unsubscribe = firestore
      .collection("subjects")
      .where("examiners", "array-contains", id)
      .onSnapshot(
        (snapshot) => {
          setSubjects(snapshot.docs.map((doc) => doc.data() as Subject));
        },
        (error) => {
          console.error(error);
        }
      );

    return () => {
      unsubscribe();
    };
  }, [id]);

  return subjects;
}

const SubjectsTransferList: React.FC<{
  examinerID: string;
  semesters: { title: string }[];
}> = ({ examinerID, semesters }) => {

  const examinerSubjects = useExaminerSubjects(examinerID);
  const firestore = useFirestore();
  const [query, setQuery] = React.useState({ line: "", semester: "" });
  const subjects = useSubjects({
    setQuery,
    ...query,
  });
  const examinerDoc = firestore.collection("examiners").doc(examinerID);
  const addSubject = async (subject: Subject) => {
    try {
      // Duplicate the data so that an examiner appear in a subject and a subject appear on the examiner
      const batch = firestore.batch();
      batch.update(firestore.collection("subjects").doc(subject.id), {
        examiners: firestore.FieldValue.arrayUnion(examinerID),
      });
      batch.update(examinerDoc, {
        subjects: firestore.FieldValue.arrayUnion(subject.id),
      });

      batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  const removeSubject = async (subject: Subject) => {
    try {
      const batch = firestore.batch();
      batch.update(firestore.collection("subjects").doc(subject.id), {
        examiners: firestore.FieldValue.arrayRemove(examinerID),
      });
      batch.update(examinerDoc, {
        subjects: firestore.FieldValue.arrayRemove(subject.id),
      });
      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  const customAddList = (title: string, items: Subject[]) => (
    <Card>
      <CardHeader className={classes.cardHeader} title={title} />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((subject) => {
          const labelId = `transfer-list-item-${subject.id}-label`;

          return (
            <ListItem key={subject.id} role="listitem">
              <ListItemIcon>
                <IconButton onClick={() => addSubject(subject)} size="large">
                  <Add />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${subject.name} - ${subject.semester}`}
                secondary={`${subject.lines
                  .map((line) => parseLine(line))
                  .join(", ")}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const customRemoveList = (title: string, items: Subject[]) => (
    <Card>
      <CardHeader className={classes.cardHeader} title={title} />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((subject) => {
          const labelId = `transfer-list-item-${subject.id}-label`;

          return (
            <ListItem key={subject.id} role="listitem">
              <ListItemIcon>
                <IconButton onClick={() => removeSubject(subject)} size="large">
                  <Delete />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${subject.name} - ${subject.semester}`}
                secondary={`${subject.lines
                  .map((line) => parseLine(line))
                  .join(", ")}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const SubjectSelectForm = () => {
    return (
      <>
        <Typography variant="body1">
          Vælg linie og semester for at se fag du kan tildele censor.
        </Typography>
        <FormControl component="fieldset" className={classes.radio}>
          <RadioGroup
            row
            name="line"
            value={query.line}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuery({
                line: e.target.value as string,
                semester: query.semester,
              })
            }
          >
            <FormControlLabel value="sport" control={<Radio />} label="Sport" />
            <FormControlLabel
              value="xOutdoor"
              control={<Radio />}
              label="X-Outdoor"
            />
            <FormControlLabel
              value="outdoor"
              control={<Radio />}
              label="Friluft"
            />
          </RadioGroup>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="semester-select-label">Semester</InputLabel>
          <Select
            labelId="semester-select-label"
            value={query.semester}
            onChange={(e) =>
              setQuery({
                semester: e.target.value as string,
                line: query.line,
              })
            }
          >
            <MenuItem value={""}>Vælg semester</MenuItem>
            {semesters.map((s) => (
              <MenuItem value={s.title} key={s.title}>
                {s.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  };
  return (
    <StyledGrid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid item xs={12}>
        <SubjectSelectForm />
      </Grid>
      <Grid item>
        {customAddList(
          "Fag",
          subjects.filter((s) => !s.examiners.includes(examinerID))
        )}
      </Grid>
      <Grid item>{customRemoveList("Tilvalgte fag", examinerSubjects)}</Grid>
    </StyledGrid>
  );
};

export default SubjectsTransferList;
