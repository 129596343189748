import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  NotificationsComponent,
  NavBar,
  NewSemesterDialog,
} from "../../components";
import { CertificateDialog, SnackbarContainer } from "../";
import classNames from "classnames";
import Container from "../../BaseComponents/Container";
import DrawerContainer from "../Drawer/DrawerContainer";
import BackgroundImage from "./bg.png";
import { fetchClassTemplates, createClass } from "../../actions/templates";
import {
  fetchSemesters,
  createSemester,
  toggleSemesterDialog,
} from "../../actions/semesters";

const PREFIX = 'MainContainer';

const classes = {
  root: `${PREFIX}-root`,
  appFrame: `${PREFIX}-appFrame`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    zIndex: 1,
    overflow: "hidden",
  },

  [`& .${classes.appFrame}`]: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },

  [`& .${classes.content}`]: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    minHeight: "100vh",
    width: "100%",

    flexGrow: 1,
    backgroundColor: theme.palette.background.default,

    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      content: {
        height: "calc(100% - 64px)",
        marginTop: 64,
      },
    },
  },

  [`& .${classes.contentShift}`]: {
    marginLeft: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
}));

class MainContainer extends Component {
  state = {
    drawerOpen: false,
    notificationsOpen: false,
  };

  handleToggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  handleToggleNotifications = () => {
    this.setState({ notificationsOpen: !this.state.notificationsOpen });
  };

  handleCreateClass = (c) => {
    if (c === "") {
      return;
    }
    const lowercase = c.toLowerCase();
    const hyphenated = lowercase.replace(" ", "-");
    const classTemplate = { [hyphenated]: c };

    this.props.createClass(classTemplate);
  };

  handleCreateSemester = (s) => {
    this.props.createSemester(s);
  };

  componentDidMount() {
    this.props.fetchClassTemplates();
    this.props.fetchSemesters();
  }

  render() {
    const {  isAuthed, semesterOpen, classTemplates } = this.props;

    return (
      <Root className={classes.root}>
        <div className={classes.appFrame}>
          {isAuthed ? (
            <div className="no-print">
              <SnackbarContainer />
              <NewSemesterDialog
                open={semesterOpen}
                handleToggleDialog={() =>
                  this.props.toggleSemesterDialog(!semesterOpen)
                }
                classTemplates={classTemplates}
                handleCreateClass={(c) => this.handleCreateClass(c)}
                handleCreateSemester={(s) => this.handleCreateSemester(s)}
              />
              <CertificateDialog />
              <NavBar
                open={this.state.drawerOpen}
                handleToggleDrawer={() => this.handleToggleDrawer()}
                toggleNotifications={() => this.handleToggleNotifications()}
              />
              <NotificationsComponent
                open={this.state.notificationsOpen}
                toggleNotifications={() => {
                  this.handleToggleNotifications();
                }}
              />
            </div>
          ) : null}
          <main
            className={classNames(
              classes.content,
              this.state.drawerOpen && classes.contentShift
            )}
          >
            <Container maxWidth="xl">{this.props.children}</Container>
          </main>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = ({ auth, semesters, templates }) => {
  const { isAuthed, user, error } = auth;
  return {
    isAuthed,
    user,
    error,
    semesterOpen: semesters.semesterOpen,
    classTemplates: templates.classes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClassTemplates: () => dispatch(fetchClassTemplates()),
    fetchSemesters: () => dispatch(fetchSemesters()),
    toggleSemesterDialog: (open) => dispatch(toggleSemesterDialog(open)),
    createClass: (c) => dispatch(createClass(c)),
    createSemester: (s) => dispatch(createSemester(s)),
  };
};

export default compose(
  
  connect(mapStateToProps, mapDispatchToProps)
)(MainContainer);
