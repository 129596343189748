import React from "react";
import PropTypes from "prop-types";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

interface Props {
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: () => void;
  rowsPerPageOptions: number[];
}

const PersonsTableFooter: React.FC<Props> = (props) => (
  <TableFooter>
    <TableRow>
      <TablePagination {...props} />
    </TableRow>
  </TableFooter>
);
PersonsTableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
};
export default PersonsTableFooter;
