import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import CloudDownload from "@mui/icons-material/CloudDownload";
import CircularProgress from "@mui/material/CircularProgress";
import ListSubheader from "@mui/material/ListSubheader";

const PREFIX = 'FilesComponent';

const classes = {
  list: `${PREFIX}-list`
};

const StyledList = styled(List)((
  {
    theme
  }
) => ({
  [`&.${classes.list}`]: {
    overflow: "auto",
    height: listHeight
  }
}));

const listHeight = 350;

const FilesComponent = ({
  isFetching,
  isUploading,
  fetchStatus,
  files,
  handleDeleteFile,
  height = listHeight,
  heading,
  type
}) => {
  return (
    <StyledList
      className={classes.list}
      style={{ height: height }}
      subheader={<ListSubheader component="div">{heading}</ListSubheader>}
    >
      {(isFetching || isUploading) && (
        <ListItem>
          <CircularProgress size={24} />
          <ListItemText
            primary={isFetching || isUploading ? fetchStatus : "Filer"}
          />
        </ListItem>
      )}
      {files[type].map(file => {
        return (
          <ListItem key={file.id} dense button className={classes.listItem}>
            <IconButton
              aria-label="Download"
              onClick={() => window.open(file.downloadURL, "_blank")}
              size="large">
              <CloudDownload />
            </IconButton>
            <ListItemText primary={truncateFileName(file.fileName)} />
            <ListItemSecondaryAction>
              <IconButton aria-label="Delete" onClick={() => handleDeleteFile(file)} size="large">
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </StyledList>
  );
};

const truncateFileName = name => {
  //If the first part of the filename is too long, truncate it
  const maxLength = 25;
  const arr = name.split(" ");
  const first = arr[0];
  if (first) {
    if (first.length > maxLength) {
      const truncated = first.substring(0, maxLength) + "...";
      return truncated;
    } else {
      return name;
    }
  }
};

FilesComponent.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  fetchStatus: PropTypes.string,
  files: PropTypes.object.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FilesComponent;
