import React from "react";
import { moveApplicantToStudents, deleteApplicant } from "./actions/applicants";
import NoApplicantFound from "./components/NoApplicantFound";
import Form from "./components/Form";
import {
  formatApplicantMessage,
  formatInterviewerMessage,
} from "../helpers/messageFormatter";
import { openSnackbar } from "../actions/snackbar";
import { setRecipients } from "../actions/messages";
import Container from "@mui/material/Container";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { AppState } from "../reducers";

const PREFIX = "ApplicantContainer";

const ApplicantContainer: React.FC = () => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  useFirestoreConnect([`applicants/${id}`, "semesters"]);

  const { applicant, semesters } = useSelector(
    ({ firestore: { data, ordered } }: AppState) => ({
      applicant: data.applicants && data.applicants[id],
      semesters: ordered.semesters || [],
    })
  );

  const handleDeleteApplicant = () => {
    if (window) {
      if (
        window.confirm(`Er du sikker på at du vil slette ${applicant.name}?`)
      ) {
        dispatch(deleteApplicant(id));
      }
    }
  };

  const sendReminder = (reminderType: string) => {
    if (reminderType === "applicant") {
      const recipient = {
        name: applicant.name,
        email: applicant.email,
        phone: applicant.phone,
        collection: "applicants",
        docId: applicant.id,
        status: "not_submitted",
        message: formatApplicantMessage(
          applicant.name,
          applicant.interviewDateString
        ),
      };
      dispatch(setRecipients([recipient]));
    } else if (reminderType === "interviewer") {
      const recipient = {
        name: applicant.interviewer,
        email: `${applicant.interviewer}@ppkbh.dk`,
        phone: "",
        status: "not_submitted",
        message: formatInterviewerMessage(
          applicant.interviewer,
          applicant.name,
          applicant.interviewDateString
        ),
      };
      setRecipients([recipient]);
    }
  };

  const handleMove = () => {
    moveApplicantToStudents(applicant);
  };

  const saveApplicant = async (updated: any) => {
    try {
      await firestore.collection("applicants").doc(id).update(updated);
      dispatch(openSnackbar(`✅ ${updated.name} opdateret`));
      // Client wan't the applicant to be moved automatically to /students
      // if the contract has been received
      if (updated.contractReceived) {
        dispatch(moveApplicantToStudents(updated));
      } else {
        history.push("/applicants");
      }
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 Kunne ikke gemme ansøger ${error.message}`));
    }
  };

  return (
    <Container sx={{ background: "white", paddingY: 4 }} maxWidth="md">
      {applicant ? (
        <Form
          doSubmit={(a: any) => saveApplicant(a)}
          sendReminder={(reminderType: string) => {
            sendReminder(reminderType);
          }}
          handleDeleteApplicant={handleDeleteApplicant}
          applicant={applicant}
          moveApplicant={() => handleMove()}
          semesters={semesters}
        />
      ) : (
        <NoApplicantFound />
      )}
    </Container>
  );
};

export default ApplicantContainer;
