import React from "react";
import { useField, getIn, FieldAttributes } from "formik";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SxProps,
  SelectProps,
} from "@mui/material";

type SelectFieldProps = {
  label: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
} & FieldAttributes<unknown> &
  SxProps;

const SelectField = ({ label, options, ...props }: SelectFieldProps) => {
  const [field, meta] = useField(props);
  const errorText =
    (getIn(meta.touched, field.name) && getIn(meta.error, field.name)) || "";
  const labelId = `${field.name}-label`;
  return (
    <FormControl fullWidth error={!!errorText}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select labelId={labelId} {...field} {...(props as SelectProps)}>
        {options &&
          options.map((op, i) => (
            <MenuItem key={`${op.value}-${i}`} value={op.value}>
              {op.label}
            </MenuItem>
          ))}
      </Select>
      {meta.touched && meta.error ? <FormHelperText></FormHelperText> : null}
    </FormControl>
  );
};
export default SelectField;
