import React, { useState, useEffect } from "react";
import { UserComponent } from "../../components";
import { useParams, useHistory } from "react-router";
import { functions } from "../../helpers/firebaseHelper";
import { snackbarOpen } from "../../actions/snackbar";
import { useDispatch } from "react-redux";
import { Grid, CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";

const UserContainer: React.FC = () => {
  const { id: uid }: { id: string } = useParams();
  const [user, setUser] = useState<UserRecord | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      const userFunction = functions.httpsCallable("users-fetchUser");
      const result = await userFunction({ uid });
      setUser(result.data);
    }
    loadUser();
  }, []);

  const handleSubmit = async (user: User) => {
    const modifyFunction = functions.httpsCallable("users-modifyUser");

    try {
      await modifyFunction(user);
      dispatch(snackbarOpen(`✅ Bruger ${user.displayName} blev opdateret`));
    } catch (error: any) {
      dispatch(snackbarOpen(error.message));
    }
  };

  const handleDelete = async (user: User) => {
    if (window && uid) {
      if (window.confirm(`Er du sikker på du vil slette ${user.displayName}`)) {
        const deleteFunction = functions.httpsCallable("users-deleteUser");

        try {
          await deleteFunction(user);
          dispatch(snackbarOpen(`✅ Bruger ${user.displayName} blev slettet`));
          history.push("/users");
        } catch (error: any) {
          dispatch(snackbarOpen(error.message));
        }
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper elevation={4} style={{ marginTop: 16, padding: 8 }}>
          {user ? (
            <UserComponent
              initialValues={{
                uid: user.uid,
                emailVerified: user.emailVerified,
                email: user.providerData[0].email,
                displayName: user.providerData[0].displayName,
                ...user.customClaims,
              }}
              handleDelete={handleDelete}
              handleSubmit={handleSubmit}
            />
          ) : (
            <CircularProgress />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserContainer;
