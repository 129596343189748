import React from "react";
import { styled } from "@mui/material/styles";
import { Formik, Field, FieldArray, FormikValues } from "formik";

import _ from "lodash";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  FormLabel,
  FormGroup,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  ListSubheader,
} from "@mui/material";
import { RadioField, SelectField, TextField } from "../../components";
import * as Yup from "yup";
import { parseLine } from "../../helpers/parser";
import useExaminers from "../../hooks/useExaminers";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import AssignStudentsDialog from "./AssignStudentsDialog";
import green from "@mui/material/colors/green";

const PREFIX = "SubjectForm";

const classes = {
  buttonRow: `${PREFIX}-buttonRow`,
  list: `${PREFIX}-list`,
  checkmark: `${PREFIX}-checkmark`,
};

const StyledFormik = styled(Formik)(({ theme }) => ({
  [`& .${classes.buttonRow}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.list}`]: {
    height: 400,
    width: 300,
    overflow: "auto",
  },

  [`& .${classes.checkmark}`]: {
    color: green[500],
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  semester: Yup.string().required(),
  lines: Yup.array().of(Yup.string()).required(),
  exam: Yup.mixed<Exam>().nullable(),
  students: Yup.array().of(Yup.string()),
  examiners: Yup.array().of(Yup.mixed<Examiner>()),
  examResults: Yup.array().of(Yup.mixed<ExamResult>()),
});

type SubjectFormProps = {
  initialValues: Subject;
  semesters: Semester[];
  onSubmit: (subject: FormikValues) => Promise<void>;
  assignStudents?: (ids: string[]) => void;
  unassignStudents?: (ids: string[]) => void;
  isCreate?: boolean;
  onComplete?: () => void;
};
const SubjectForm: React.FC<SubjectFormProps> = ({
  initialValues,
  semesters,
  onSubmit,
  isCreate,
  assignStudents,
  unassignStudents,
  onComplete,
}) => {
  const allExaminers = useExaminers();

  const [open, setOpen] = React.useState<boolean>(false);
  const [line, setLine] = React.useState<string>("");
  return (
    <StyledFormik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        // Submit the new subject
        try {
          await onSubmit(values);

          onComplete ? onComplete() : null;
        } catch (error: any) {
          console.log(error.message);
        }
      }}
    >
      {({
        values: { semester, lines, id, examiners },
        handleSubmit,
        isSubmitting,
        resetForm,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <AssignStudentsDialog
            semester={semester}
            line={line}
            handleCancel={() => setOpen(false)}
            handleAssign={(ids) => {
              if (assignStudents) {
                assignStudents(ids);
              }
            }}
            handleUnassign={(ids) => {
              if (unassignStudents) {
                unassignStudents(ids);
              }
            }}
            subjectId={id}
            open={open}
          />

          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Field
                name="name"
                label="Navn*"
                fullWidth
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectField
                name="semester"
                label="Semester"
                options={semesters.map((s) => ({
                  value: s.title,
                  label: s.title,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioField
                row
                name="exam.type"
                label="Eksamens type"
                options={[
                  { value: "graded", label: "Karakter" },
                  {
                    value: "pass_fail",
                    label: "Bestået / Ikke bestået",
                  },
                  { value: "attendance", label: "Deltaget" },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Linier</FormLabel>
                <FieldArray
                  name={"lines"}
                  render={(arrayHelpers) => (
                    <FormGroup row>
                      {["sport", "xOutdoor", "outdoor"].map((line) => (
                        <FormControlLabel
                          key={line}
                          control={
                            <Checkbox
                              value={line}
                              checked={lines?.includes(line)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(line);
                                } else {
                                  const idx = lines.indexOf(line);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          }
                          label={parseLine(line)}
                        />
                      ))}
                    </FormGroup>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" style={{ marginBottom: 8 }}>
                Klik nedenfor for at tildele studerende dette eksamensfag. Du
                kan se en liste over de studerende før at de bliver tildelt
                faget
              </Typography>
              <ButtonGroup
                color="primary"
                variant="outlined"
                aria-label="outlined primary button group"
              >
                {lines?.map((line: string) => (
                  <Button
                    key={line}
                    onClick={() => {
                      setLine(line);
                      setOpen(true);
                    }}
                  >
                    Tildel {parseLine(line)}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldArray
                name="examiners"
                render={({ remove, push }) => (
                  <List
                    className={classes.list}
                    component="nav"
                    aria-label="examiners"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Censorer
                      </ListSubheader>
                    }
                  >
                    {_.sortBy(allExaminers, "firstName").map((examiner) => {
                      const name = `${examiner.firstName} ${examiner.lastName}`;
                      const isAssigned = examiners.includes(examiner.id);
                      const idx = examiners.indexOf(examiner.id);
                      return (
                        <ListItemButton
                          key={examiner.id}
                          onClick={() =>
                            isAssigned ? remove(idx) : push(examiner.id)
                          }
                        >
                          <ListItemIcon>
                            {isAssigned ? (
                              <CheckCircleOutlineIcon
                                className={classes.checkmark}
                              />
                            ) : (
                              <RadioButtonUncheckedIcon />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={name} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                )}
              />
            </Grid>
          </Grid>

          <div className={classes.buttonRow}>
            {onComplete && (
              <Button
                onClick={() => (onComplete ? onComplete() : null)}
                color="primary"
                style={{ marginRight: 16 }}
              >
                Annuller
              </Button>
            )}
            <Button
              onClick={() => resetForm()}
              disabled={!dirty}
              color="primary"
              style={{ marginRight: 16 }}
            >
              Fortryd
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || !dirty}
            >
              {isCreate ? "Opret" : "Opdater"}
            </Button>
          </div>
        </form>
      )}
    </StyledFormik>
  );
};

export default SubjectForm;
