import React from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { parseDate } from "../../helpers/parser";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Data = InfoEvent;

type TableProps = {
  setSelection: (selection: Data[]) => void;
  semester: string;
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const EventsTable: React.FC<TableProps> = React.memo(
  ({ setSelection, semester }) => {
    const theme = useTheme();

    const columns: GridColDef[] = [
      {
        field: "date",
        headerName: "Dato",
        width: 200,
        renderCell: (params) => (
          <StyledLink to={`/events/${params.row.id}`}>
            {parseDate(params.value, "LLL")}
          </StyledLink>
        ),
      },
      {
        field: "location",
        headerName: "Sted",
        width: 150,
        renderCell: (params) => (
          <StyledLink to={`/events/${params.row.id}`}>
            {params.value}
          </StyledLink>
        ),
      },
      {
        field: "isPublic",
        headerName: "Offentliggjort",
        width: 150,
        type: "boolean",
      },
      {
        field: "completed",
        headerName: "Afviklet",
        type: "boolean",
        width: 150,
      },
    ];

    useFirestoreConnect([
      {
        collection: "info_events",
        orderBy: ["date", "asc"],
        startAt: new Date(`${semester}-09-01`),
        endAt: new Date(`${parseInt(semester) + 1}-09-01`),
      },
    ]);

    const events = useSelector(
      ({ firestore: { ordered } }: AppState) => ordered["info_events"] || []
    );

    return (
      <div style={{ width: "100%", minHeight: 400, background: "white" }}>
        <DataGrid
          rows={events}
          columns={columns}
          pageSizeOptions={[10, 20, 50]}
          checkboxSelection
          onRowSelectionModelChange={(
            newSelectionModel: GridRowSelectionModel
          ) => {
            const selectedIDs = new Set(newSelectionModel);
            const selectedRows = events.filter((row) =>
              selectedIDs.has(row.id)
            );
            setSelection(selectedRows);
          }}
        />
      </div>
    );
  }
);

export default EventsTable;
