import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { Field, Formik } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import green from "@mui/material/colors/green";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "../FormFields/TextField";
const PREFIX = "SafetyInsuranceForm";

const classes = {
  paper: `${PREFIX}-paper`,
  textField: `${PREFIX}-textField`,
  textFieldPolicy: `${PREFIX}-textFieldPolicy`,
  button: `${PREFIX}-button`,
  buttonDiv: `${PREFIX}-buttonDiv`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  [`& .${classes.textFieldPolicy}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.buttonDiv}`]: { display: "flex", alignItems: "center" },

  [`& .${classes.buttonProgress}`]: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

class SafetyInsuranceForm extends Component {
  render() {
    const { safetyInformation, saveSafetyInfo } = this.props;

    return (
      <Formik
        initialValues={safetyInformation}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          await saveSafetyInfo(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, resetForm, touched, handleSubmit }) => (
          <Root>
            {isSubmitting && <LinearProgress />}
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography type="subheading">
                    Forsikringsoplysninger og Sikkerhed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="accident"
                    className={classes.textField}
                    placeholder={"Ulykkesforsikring firma"}
                    label="Ulykkesforsikring firma"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="accidentPolicyNumber"
                    className={classes.textFieldPolicy}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="travel"
                    className={classes.textField}
                    placeholder={"Rejseforsikring firma"}
                    label="Rejseforsikring firma"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="travelPolicyNumber"
                    className={classes.textFieldPolicy}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="home"
                    className={classes.textField}
                    placeholder={"Indboforsikring firma"}
                    label="Indboforsikring firma"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="homePolicyNumber"
                    className={classes.textFieldPolicy}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography type="subheading">
                    Kontaktoplysninger på pårørende
                  </Typography>
                  <Field
                    component={TextField}
                    name="emergencyContactName"
                    className={classes.textField}
                    placeholder={"Kontaktperson"}
                    label="Kontaktperson"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="emergencyContactRelation"
                    className={classes.textField}
                    placeholder={"F.eks. Partner, Forældre etc."}
                    label="Kontaktpersonens relation"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="emergencyContactNumber"
                    className={classes.textFieldPolicy}
                    placeholder={"Telefon nummer"}
                    label="Telefon"
                    type="tel"
                  />
                  <Field
                    component={TextField}
                    name="emergencyContactEmail"
                    className={classes.textFieldPolicy}
                    placeholder={"Email"}
                    label="Email"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography type="subheading">Pas oplysninger</Typography>
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Field
                        name="passportExpiresInSemester"
                        component={Checkbox}
                      />
                    }
                    label="Pas udløber i studieår"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="passportNumber"
                    className={classes.textFieldPolicy}
                    placeholder={"123456789"}
                    label="Pasnummer"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="passportExpiry"
                    className={classes.textFieldPolicy}
                    label="Udløbsdato"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Field
                    component={TextField}
                    name="passportName"
                    className={classes.textFieldPolicy}
                    label="Navn i pas"
                    type="text"
                  />
                  <Field
                    component={TextField}
                    name="passportNationality"
                    className={classes.textFieldPolicy}
                    label="Nationalitet"
                    type="text"
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.buttonDiv}>
                    <div className={classes.wrapper}>
                      <Button
                        raised="true"
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        Opdater
                      </Button>

                      {isSubmitting && (
                        <CircularProgress
                          size={30}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <Button
                      raised="true"
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={resetForm}
                    >
                      Fortryd
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Root>
        )}
      </Formik>
    );
  }
}

export default SafetyInsuranceForm;
