import React, { useState } from "react";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import EventsTable from "./components/EventsTable";
import { FieldValue } from "../helpers/firebaseHelper";
import Header from "./components/Header";
import NextEventStatsPieChart from "./components/NextEventStatsPieChart";
import EventStatsLineChart from "./components/EventStatsLineChart";
import useEventStats from "./useEventStats";
import { AppState } from "../reducers";
import useNextEventStats from "./useNextEventStats";
const EVENTS_COLLECTION = "info_events";

const EventsComponent: React.FC = () => {
  const firestore = useFirestore();
  const [selection, setSelection] = useState<InfoEvent[]>([]);
  const [semester, setSemester] = useState<string>("2024");

  const eventStats = useEventStats({ semester });
  const { nextEventStats, nextEventDate } = useNextEventStats();

  const deleteSelected = async () => {
    try {
      const eventsRef = firestore.collection(EVENTS_COLLECTION);
      const batch = firestore.batch();

      if (
        window.confirm(
          `Er du sikker på du vil slette ${selection.length} infoaftener?`
        )
      ) {
        for (const infoEvent of selection) {
          const eventRef = eventsRef.doc(infoEvent.id);
          batch.delete(eventRef);
        }
        await batch.commit();
      }
    } catch (error: any) {
      console.error(error);
      window.alert(`Kunne ikke slette infoaftener ${error.message}`);
    }
  };

  useFirestoreConnect([
    {
      collection: EVENTS_COLLECTION,
      orderBy: ["date", "asc"],
      startAt: new Date(`${semester}-09-01`),
      endAt: new Date(`${parseInt(semester) + 1}-09-01`),
    },
  ]);

  const events = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered[EVENTS_COLLECTION] || []
  );

  const handleCreateNewEvent = async () => {
    try {
      const doc = firestore.collection(EVENTS_COLLECTION).doc();
      await doc.set({
        date: FieldValue.serverTimestamp(),
        location: "DGI Byen",
        id: doc.id,
        isPublic: false,
        completed: false,
        attendance: 0,
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ marginBottom: 8 }}></Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="subtitle1" style={{ padding: 8, fontWeight: 900 }}>
          Oversigt over infoaften
        </Typography>
        <Box
          mx="auto"
          pt={2}
          boxShadow={2}
          bgcolor="background.paper"
          height={400}
          borderRadius="5px"
        >
          <EventStatsLineChart data={eventStats} />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="subtitle1" style={{ padding: 8, fontWeight: 900 }}>
          Overblik over kommende infoaften
        </Typography>
        <Box
          boxShadow={2}
          height={400}
          mx="auto"
          bgcolor="background.paper"
          borderRadius="5px"
        >
          <NextEventStatsPieChart
            nextEventDate={nextEventDate}
            data={nextEventStats}
          />
        </Box>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 8 }}>
        <Box sx={{ background: "white" }}>
          <Header
            selection={selection}
            handleCreateEvent={handleCreateNewEvent}
            handleDeleteEvents={deleteSelected}
            semester={semester}
            setSemester={setSemester}
          />
        </Box>
        <EventsTable setSelection={setSelection} semester={semester} />
      </Grid>
    </Grid>
  );
};

export default EventsComponent;
