import React from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../actions/snackbar";
import { AppState } from "../reducers";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Form from "./components/Form";
import { IEmployee } from "./Employee";

const PREFIX = "EmployeeContainer";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  },
}));

const EmployeeContainer: React.FC = () => {
  const { id }: { id: string } = useParams();

  useFirestoreConnect([
    {
      collection: "employees",
      doc: id,
    },
  ]);

  const employee = useSelector(
    ({ firestore: { data } }: AppState) => data.employees && data.employees[id]
  );

  const firestore = useFirestore();
  const dispatch = useDispatch();

  const saveEmployee = async (employee: IEmployee) => {
    try {
      await firestore.collection("employees").doc(id).update(employee);
      dispatch(openSnackbar(`✅ ${employee.firstName} opdateret`));
    } catch (error) {
      dispatch(openSnackbar(`🛑 Kunne ikke opdatere ${employee.firstName}`));
      console.log(error);
    }
  };
  return (
    <StyledGrid container justifyContent="center">
      <Grid item xs={12} lg={10} xl={8}>
        <Paper className={classes.root} elevation={6}>
          {employee ? (
            <Form
              employee={employee}
              doSubmit={(employee) => saveEmployee(employee)}
            />
          ) : null}
        </Paper>
      </Grid>
    </StyledGrid>
  );
};

export default EmployeeContainer;
