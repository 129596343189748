import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
const PREFIX = "StudentActionsMenu";

const classes = {
  paper: `${PREFIX}-paper`,
  button: `${PREFIX}-button`,
  input: `${PREFIX}-input`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: "100%",
  },

  [`& .${classes.input}`]: {
    display: "none",
  },
}));

const StudentActionsMenu = ({
  handleProfileImageFile,

  handleSendMessage,
  creatingAccount,
   handleViewMessages,
  handleCreateAccount,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Root>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {creatingAccount ? "Opretter konto" : "Handlinger"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <input
            accept="jpg,jpeg,JPG,JPEG,png,PNG"
            className={classes.input}
            id="file"
            type="file"
            onChange={(e) => handleProfileImageFile(e.target.files[0])}
          />
          <label htmlFor="file">Upload billede</label>
        </MenuItem>
        <MenuItem onClick={handleSendMessage}>Send besked</MenuItem>
        <MenuItem onClick={handleViewMessages}>Se beskedhistorik</MenuItem>
        <MenuItem
          onClick={handleCreateAccount}
          disabled={ creatingAccount}
        >
          {" "}
          {creatingAccount ? "Opretter..." : "Opret konto"}
        </MenuItem>
      </Menu>
    </Root>
  );
};

StudentActionsMenu.propTypes = {

  handleProfileImageFile: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  handleViewMessages: PropTypes.func.isRequired,
  handleCreateAccount: PropTypes.func.isRequired,
};

export default StudentActionsMenu;
